import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../../Landing/Buttons/FullButton";
//Assets 
import Logo1 from '../../../assets/images/Inicio/Crear evento.png'
import Logo2 from '../../../assets/images/Inicio/Mis eventos.png'
import Logo3 from '../../../assets/images/Inicio/App.png'


export default function PanelCard({ title, description, btnText, action, icon }) {
  
  let getIcon;

  switch (icon) {
    case 1:
      getIcon = <IconCenter src={Logo1} />;
      break;
    case 2:
      getIcon = <IconCenter src={Logo2} />;
      break;
    case 3:
      getIcon = <IconCenter src={Logo3} />;
      break;
    default:
      getIcon = <IconCenter src={Logo1} />;
      break;
  }

  return (
    <Wrapper className="whiteBg radius8 shadow">
      <div style={{ margin: "30px 0" }}>
        <div className="flexSpaceCenter">
          {getIcon}
        </div>
        <h4 className="font20 extraBold text-center" style={{height:"80px"}}>{title}</h4>
        {/* <p className="font13" style={{height:"150px"}}>{description}</p> */}
        <Ulist>
          {
            description.map((v, i)=>{
              return <li key={i} className="font15" >{v}</li>
            })
          }
        </Ulist>
      </div>
      <div style={{ maxWidth: "200px", margin: "30px auto 0 auto" }}>
        <FullButton title={btnText} action={action}/>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;

const Ulist = styled.ul`
  list-style: unset;
  height: 160px;
`;

const IconCenter = styled.img`
  width:140px;
  margin: 0px auto;
`;
