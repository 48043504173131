import React, { Component } from 'react';
import Html5QrcodePlugin from '../../Html5QrcodePlugin/Html5QrcodePlugin';
import {Link,useParams} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ChevronLeft } from 'react-bootstrap-icons';

import 'react-toastify/dist/ReactToastify.css';

import Logo from "../../../assets/images/glup-tickets-logo.png";
import BotonVerde from "../../../assets/images/Boton Verde.svg";
import BotonRojo from "../../../assets/images/Boton Rojo.svg";
import beep from "../../../assets/sounds/beep.mp3";


function buscarInvitadoDemo(eventoId,datos){
  let invitados = require('../Ingresos/Ingresos.json');
  let mensaje = "";
  invitados.forEach(invitado => {
    if(invitado.qr===datos.qr){
      if(invitado.estado!==2){
        invitado.estado=2;
        const d = new Date();  
        invitado.hora_ingreso=d.toLocaleTimeString();
        
        mensaje = "Ingreso Aceptado: "+ invitado.nombre;

        
      }
      else{
        mensaje = "Ingreso Repetido: "+ invitado.nombre;
      }
      
    }else{
      mensaje = "QR NO VÁLIDO";
    }
  });
  return mensaje;
}

function vibrate() {
  
  if (!window) {
      return;
  }

  if (!window.navigator) {
      return;
  }

  if (!window.navigator.vibrate) {
      return;
  }

  window.navigator.vibrate([500]);
}

class EscanearQr extends Component {

    constructor(props) {
        super(props);
        this.state = {
            result: '',
            evento_id: 0,
            messageStyle : {
              color: "#fff"
            },
            messageBtn: BotonRojo
          };
        this.handleScan = this.handleScan.bind(this);
    }

  handleScan(data){
    let audio = new Audio(beep);
    audio.play();
    if(data == null) return
      vibrate();
      const datos = {
        qr: data
      }
      const validarQR = buscarInvitadoDemo(this.state.evento_id, datos);
      var res="";
      res=validarQR;
      if(res.includes("Aceptado")){
        this.setState({
          result: validarQR,
          messageStyle: {color: "#00ff22"},
          messageBtn: BotonVerde
        });
        toast.success('INGRESO ACEPTADO', {
          theme: 'colored',
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          });
      }else{
      this.setState({
        result: validarQR,
        messageStyle: {color: "#f50505"},
        messageBtn: BotonRojo
      });
      toast.error('INGRESO RECHAZADO', {
        theme: 'colored',
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
        });
    }
      //let mensaje = "";

      // if(validarQR.Success){
      //   const Invitado = validarQR.Data;
      //   if(validarQR.Repetido){
      //     mensaje = "Ingreso Repetido: "+ Invitado.nombre;
      //     this.setState({
      //       result: mensaje,
      //       messageStyle: {color: "#f50505"},
      //       messageBtn: BotonRojo
      //     });
      //   } else{
      //     mensaje = "Ingreso Aceptado: "+ Invitado.nombre;
      //   this.setState({
      //     result: mensaje,
      //     messageStyle: {color: "#00ff22"},
      //     messageBtn: BotonVerde
      //   });
      //   }
      // }else{
      //   mensaje = validarQR.Mensaje;
      //   this.setState({
      //     result: mensaje,
      //     messageStyle: {color: "#fff700"},
      //     messageBtn: BotonRojo
      //   });
      // }
       
  }

componentDidMount() {
  const {eventoId} = this.props.params;
  this.setState({
    evento_id: eventoId
  });

}


  render(){
 
    return(
      <div className='app-wrapper wrapper scrollable'>
        <div>
          <div className='demo-top-right-circle'>
          </div>
          <div className='demo-bottom-left-circle'>
          </div>
          <div>
            <img src={Logo} alt="Logo Glup Tickets" width={"150px"}/>
          </div>
        </div>
        <div>
          <Html5QrcodePlugin 
                fps={10}
                qrbox={250}
                aspectRatio={1.0}
                disableFlip={false}
                qrCodeSuccessCallback={this.handleScan}
          />
        </div>
        <div className='scan-button-group'>
          <div style={{width:'80%',marginTop: '20px'}}>
            <p className='message' style={this.state.messageStyle}>
              <img className='btnMessage' src={this.state.messageBtn} alt="Boton" width={"25px"}/>   
              {this.state.result}
            </p>
          </div>
        </div>
        <div style={{width:'300px'}}>
          <div className='d-grid gap-3 col mx-auto px-4'> 
            <Link className='demo-button' to={`/demo/evento/${this.state.evento_id}`}><ChevronLeft size={20}/> &nbsp; Atras</Link>
          </div>
        </div>
        
        <ToastContainer/>  
      </div>
      
    )
  }
}

const QR = (props) =>(
  <EscanearQr
    {...props}
    params={useParams()}
  />
);

export default QR;