import ReactModal from 'react-modal';
import Conexion from '../../../Conexion/Conexion';
const {apiUrl} = Conexion();


const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: '40%',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  
  async function ValidarInvitados(eventoId) {
    return fetch(`${apiUrl}/api/invitados/validar/${eventoId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        },
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);

            }
            console.log(r.status)
            console.log("Error", "Could not reach server");
        }
        console.log(r)
        return r.json();
    })
}
async function InvalidarInvitados(eventoId) {
    return fetch(`${apiUrl}/api/invitados/invalidar/${eventoId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        },
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);

            }
            console.log(r.status)
            console.log("Error", "Could not reach server");
        }
        console.log(r)
        return r.json();
    })
}



export default function modalConfirmacion(props){

    const datos = props.data;
    const closeModal=()=>{
        props.close();
    }

    let handleSubmit = async (e) =>{
        e.preventDefault();
        if(props.type=="1"){
            const eliminar = await ValidarInvitados(props.data);
            if(eliminar){
                alert("Invitados reiniciados con éxito");
                props.submit();
                props.close();
            }else{
                alert("Error");
                console.log(eliminar);
            }
        }else{
            const eliminar = await InvalidarInvitados(props.data);
            if(eliminar){
                alert("Invitados desabilitados con éxito");
                props.submit();
                props.close();
            }else{
                alert("Error");
                console.log(eliminar);
            }
        }
        
    }


    
    return (
        <div>
          <ReactModal
             isOpen={props.open}
             contentLabel="Eliminar Evento"
             htmlOpenClassName="col-md-6"
             style={customStyles}
          >
            
            <div className="container">
                <h2 className="text-center">{props.type=="1"?"Reiniciar Invitados":"Deshabilitar Invitados"}</h2>
                <form className="col-lg-8 mx-auto mb-2" onSubmit={handleSubmit} >
                    <div className="mb-3">
                        <h3 className="text-center">Esta seguro?</h3>
                    </div>
                    <div className='text-center'>
                        <button type="submit" className="btn btn-primary text-right">Si</button>
                        <button className="btn btn-danger" onClick={closeModal}>Cerrar</button>
                    </div>
                    
                </form>
                

            </div>
          </ReactModal>
        </div>
      );  

}