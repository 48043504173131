import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Helmet } from "react-helmet-async";
import {  useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { ChevronLeft, PersonPlusFill, PencilSquare, Trash} from 'react-bootstrap-icons';
import { FullButton, LinkButton } from '../components/AppButton';
import AppLogo from '../components/AppLogo';
import Conexion from "../../Conexion/Conexion";
import Modal from 'react-bootstrap/Modal';
const {apiUrl} = Conexion();


async function obtenerRoles() {
  return fetch(`${apiUrl}/api/usuarios/uroles/cliente`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('token')
    }
  })
    .then(r => {
      if (r.status > 399) {
        if (r.status === 401) {
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
        }
        console.log("Error", "Could not reach server");
      }
      return r.json()
    })
}

async function cambiarEstadoUsuario(idusuario, datos) {
  return fetch(`${apiUrl}/api/usuarios/estado/${idusuario}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('token')
    },
    body: JSON.stringify(datos)
  })
    .then(r => {
      if (r.status > 399) {
        if (r.status === 401) {
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
        }
        console.log("Error", "Could not reach server");
      }
      return r.json()
      
    })
}

async function EliminarRol(idusuario) {
  return fetch(`${apiUrl}/api/usuarios/roles/${idusuario}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('token')
    }
  })
    .then(r => {
      if (r.status > 399) {
        if (r.status === 401) {
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
        }
        console.log("Error", "Could not reach server");
      }
      return r.json()
      
    })
}

function Roles() {
  const navigate = useNavigate()

  const [state, setState] = useState({
    usuarios: [],
    isfetched: false,
    disabled: false
  });
  const [modal, setModal] = useState({
    show:false,
    idusuario: 0,
    nombre: ''

  });

  const handleClose = () => setModal({
    show:false,
    idusuario: 0,
    nombre: ''
  });
  const handleShow = (idusuario, nombre) => setModal({
    show:true,
    idusuario: idusuario,
    nombre: nombre
  });

  const cargarDatos = async () => {
    let roles = await obtenerRoles();
    if(roles){
      setState({
        usuarios: roles,
        isfetched: true,
        disabled: false
      })
    }
  }

  

  const handleChangeEstado = async (idusuario, estado) => {
    let _state = _.cloneDeep(state);
    let cambio = await cambiarEstadoUsuario(idusuario,{estado: estado==1 ? 0 : 1});
    if(cambio){
      _state.isfetched=false;
      setState(_state);
    }
  }

  const handleEliminar = async (idusuario) =>{
    let _state = _.cloneDeep(state);
    let eliminar = await EliminarRol(idusuario);
    if(eliminar.ok){
      _state.isfetched=false;
      setState(_state);
    }
  }

  //handleEliminar(u.id)


    var lista = state.usuarios.map((u, i) =>{   
         return (
            <Row key={i} style={{width:'360px'}}>
                <Col xs={12} className="mb-3" >
                    <RolItem active={u.estado} >
                        <TextWrapper>
                            <p className='m-0 mx-1 darkColor semiBold'>{u.nombre}</p>
                            <p className='m-0 mx-1 darkColor'>{u.nombre_usuario}</p>
                            <p className='m-0 mx-1 darkColor'>{u.rol}</p>
                        </TextWrapper>
                        <ActionWrapper>
                          <Form.Check 
                              type="switch"
                              className='app-toggle-switch mb-3'
                              id={"custom-switch"+u.id}
                              onChange={()=> handleChangeEstado(u.id , u.estado)}
                              checked={u.estado}
                              disabled={state.disabled}
                          />
                          <div className='text-center d-flex'>
                              <button className='btn m-0 mx-2 p-1 primaryBg' disabled={state.disabled} onClick={() => navigate(`/app/roles/${u.id}/eventos`)} ><PencilSquare size={23}  /></button>
                              <button className='btn btn-danger m-0 mx-2 p-1' disabled={state.disabled} onClick={()=> handleShow(u.id, u.nombre)} ><Trash size={23} /></button>
                          </div>
                            
                        </ActionWrapper>
                    </RolItem>
                </Col>
            </Row>
         )
       } );


       if(!state.isfetched){
        cargarDatos();
      }

  return (
    <div className='container-sm'>
      <Helmet>
          <title>Administrar Roles - Glup Ticket</title>
      </Helmet>
      <div className='text-center'>
        <AppLogo />
        <div className="d-flex flex-column align-items-center">
          <h4 className="extraBold">Permisos</h4> 
          {lista}
          <LinkButton className='mb-1' to={`/app/roles/nuevo`} title={<><PersonPlusFill size={24} />&nbsp; Agregar Permiso</>} />
          <LinkButton className='my-4' border to={`/app`} title={<><ChevronLeft size={24} />&nbsp; Atras</>} />
        </div>
      </div>
      <ModalEliminar show={modal.show} close={handleClose} usuario={modal} confirmar={handleEliminar} />
    </div>
  );
}


function ModalEliminar(props) {

  const handleConfirmar = () =>{
    let id = props.usuario.idusuario;
    if(id!=0) props.confirmar(id)
    props.close();
  }

  return(
      <Modal show={props.show} onHide={props.close} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar a {props.usuario.nombre}</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de eliminar esta cuenta de usuario? Será eliminada de forma permanente</Modal.Body>
        <Modal.Footer>
        <div className="d-flex gap-3">
          <FullButton action={handleConfirmar} title={"Eliminar"} />
          <FullButton border action={props.close} title={"Cancelar"} />
        </div>
          
        </Modal.Footer>
      </Modal>
  )
}



const RolItem = styled.div`
  background-color: ${(props) => (props.active ? "#dcfdd4" : "#eee")};
  border-radius: 18px;
  display: flex;
  flex-direction: row;
  height: 90px;
  width: auto;
  font-size: 16px;
  line-height: 24px;
  color: #979797;
  text-align: left;
  padding: 5px;
`;

const TextWrapper = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ActionWrapper = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;

`




export default Roles;