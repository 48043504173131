import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Card, CardHeader, Divider, IconButton, List,ListItem,ListItemAvatar,ListItemText } from '@mui/material';
import { deepOrange, green } from '@mui/material/colors';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Conexion from '../../../Conexion/Conexion';

const { apiUrl } = Conexion();

async function obtenerUsuarios() {
  return fetch(`${apiUrl}/api/dashboard/usuarios-con-mas-eventos`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('token')
      }
  })
      .then(result => result.json())
}

const UsuariosConMasEventos = (props) => {

  let navigate = useNavigate();
  const [state, setState] = useState([]);

  const cargarDatos = async () => {
    const usuarios = await obtenerUsuarios();
    if (usuarios.ok) {
      setState(usuarios.data);
    }
  }

  useEffect(() => {
    cargarDatos();
  }, []);

  return(
    <Card {...props}>
      <CardHeader
        subheader={`Histórico - Top  ${state.length}`}
        title="Usuarios con más eventos"
      />
      <Divider />
      <List
          sx={{
              overflow: 'auto',
              minHeight: 300,
              maxHeight: 300,
          }}
      >
        {state.map((user, i) => (
          <ListItem
            divider={i < state.length - 1}
            key={user.id}
            sx={{ py: 0, minHeight: 32 }}
          >
            <ListItemAvatar>
            {/* sx={{ bgcolor: green[500] }} */}
              <Avatar alt={user.nombre} src="/broken-image.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary={user.nombre}
              secondary={`Cantidad de eventos: ${user.count_eventos}`}
            />
            {/* <IconButton
              edge="end"
              size="small"
            >
              <MoreVertIcon />
            </IconButton> */}
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
          onClick={() => navigate('/admin/clientes')}
        >
          Ver todos los usuarios
        </Button>
      </Box>
    </Card>
  );
}

export default UsuariosConMasEventos;