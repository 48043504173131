import React from 'react';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import "react-datepicker/dist/react-datepicker.css";
import Conexion from '../../Conexion/Conexion';
const { apiUrl } = Conexion();

async function crearEvento(datos) {
    return fetch(`${apiUrl}/api/eventos/subevento`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        },
        body: JSON.stringify(datos)
    })
        .then(r => {
            if (r.status > 399) {
                if (r.status === 401) {
                    alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                    sessionStorage.clear();
                    window.location.replace(window.location.origin);
                    console.log(r.status)
                }
                console.log("Error", "Could not reach server");
            }
            return r.json()
        })
}

async function cargarClientes() {
    return fetch(`${apiUrl}/api/usuarios/clientes`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
};

async function subirImagen(datos) {
    return fetch(`${apiUrl}/api/eventos/imagen/`, {
        method: 'POST',
        headers: {
            'Authorization': sessionStorage.getItem('token')
        },
        body: datos
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
};

class SubEventoForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientes: [],
            cliente_seleccionado: null,
            nombre: '',
            fecha_evento: props.cliente.fecha_evento,
            fecha_limite: props.cliente.fecha_limite,
            fecha_seleccionada: null,
            categoria: 'A',
            imagen_evento: '',
            imagen_qr: '',
            archivo_evento: null,
            archivo_qr: null,
            subEvento: 0,
            id_evento: props.cliente.id,
            id_cliente: props.cliente.id_cliente,
            id_administrador: sessionStorage.getItem('user_id')
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.uploadFile2 = this.uploadFile2.bind(this);
        console.log(this.state.fecha_evento);
        console.log(this.state.fecha_limite);
    }

    handleChange(e) {
        if (e.target.id === 'nombre') {
            this.setState({ nombre: e.target.value });
        }
        if (this.state.archivo_evento != null) {
            let nombre_imagen = e.target.value + '' + this.state.fecha_evento;
            this.setState({ imagen_evento: nombre_imagen });
        }
        if (this.state.archivo_qr != null) {
            let nombre_imagen = e.target.value + '' + this.state.fecha_evento + 'fondoqr';
            this.setState({ imagen_evento: nombre_imagen });
        }

    }

    
    /*handleChangeClientes = cliente => {
        this.setState({ cliente_seleccionado: cliente });
        this.setState({ id_cliente: cliente.value });
    };*/
    /*handleChangeFecha = fecha => {
        const fecha_evento = formatInTimeZone(fecha, "UTC", "dd-MM-yyyy", {
            awareOfUnicodeTokens: true
        });
        this.setState({ fecha_evento: fecha_evento });
        this.setState({ fecha_seleccionada: new Date() });//TODO
        if (this.state.archivo_evento != null) {
            let nombre_imagen = this.state.nombre + '' + fecha_evento;
            this.setState({ imagen_evento: nombre_imagen });
        }
        if (this.state.archivo_qr != null) {
            let nombre_imagen = this.state.nombre + '' + fecha_evento + 'fondoqr';
            this.setState({ imagen_evento: nombre_imagen });
        }
    }*/
    uploadFile(e) {
        let files = e.target.files;
        let nombre_imagen = this.state.nombre + '' + this.state.fecha_evento;
        this.setState({ archivo_evento: files[0] });
        this.setState({ imagen_evento: nombre_imagen });
    }
    uploadFile2(e) {
        let files = e.target.files;
        let nombre_imagen = this.state.nombre + '' + this.state.fecha_evento + 'fondoqr';
        this.setState({ archivo_qr: files[0] });
        this.setState({ imagen_qr: nombre_imagen });
    }
    async handleSubmit(e) {
        e.preventDefault();

        if (this.state.nombre === "" ||
            this.state.id_cliente === 0 ||
            this.state.fecha_evento === "" ||
            this.state.archivo_qr === null) {
            window.alert("Ingrese todos los datos");
            return;
        }
        // return;
        //const form = new FormData();
        const form2 = new FormData();
        //form.append('files', this.state.archivo_evento, this.state.imagen_evento);
        form2.append('files', this.state.archivo_qr, this.state.imagen_qr);
        try {
            //const imagen = await subirImagen(form);
            const fondoqr = await subirImagen(form2);
            if (fondoqr.ok) {
                const datos = {
                    nombre: this.state.nombre,
                    fondo_qr: fondoqr.files[0],
                    fecha_evento: this.state.fecha_evento,
                    fecha_limite: this.state.fecha_limite,
                    categoria: this.state.categoria,
                    subEvento: this.state.subEvento,
                    id_evento: this.state.id_evento,
                    id_cliente: this.state.id_cliente,
                    id_administrador: this.state.id_administrador,
                    estado:1
                }
                
                const evento = await crearEvento(datos);
                if (evento) {
                    alert(evento);
                    window.location.reload(false);
                }
                else { alert("Error al guardar Evento"); }
            } else {
                alert("Error al subir la imagen");
            }
        } catch (error) {
            alert("Error al subir la imagen");
            console.log('Error', error);
        }

    }

    /*async componentDidMount() {
        const clientes = await cargarClientes();
        this.setState({ clientes: clientes.map(({ id, nombre, apellido_p, apellido_m }) => ({ value: id, label: nombre + ' ' + apellido_p + ' ' + apellido_m })) })
    }*/

    render() {
        return (
            <div className="container p-2">
                <h2 className="text-center">Registrar Nuevo Evento</h2>
                <form className="col-lg-8 mx-auto" onSubmit={this.handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="nombre_ev" className="form-label">Nombre</label>
                        <input type="text" className="form-control" id="nombre" value={this.state.nombre} onChange={this.handleChange} />
                    </div>
                    {/*
                    <div className="mb-3">
                        <label className="form-label">Cliente</label>
                        <Select id="id_clientes" options={this.state.clientes} placeholder="Buscar Cliente" value={this.state.cliente_seleccionado} onChange={this.handleChangeClientes} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Fecha Evento</label>
                        <DatePicker selected={this.state.fecha_seleccionada} dateFormat="dd/MM/yyyy" onChange={this.handleChangeFecha} />
                    </div>
                    <div className="mb-3 form-check">
                        <input className="form-check-input" type="checkbox" onChange={this.handleChange} id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Tiene Subevento
                        </label>
                    </div>
                    <div className="mb-3" hidden={this.state.subEvento}>
                        <label htmlFor="imagen_ev" className="form-label">Imagen del Evento (Banner)</label>
                        <input className="form-control" type="file" onChange={this.uploadFile} />
                    </div>*/}
                    <div className="mb-3" hidden={this.state.subEvento}>
                        <label htmlFor="imagen_qr" className="form-label">Imagen de Fondo (QR)</label>
                        <input className="form-control" type="file" onChange={this.uploadFile2} />
                    </div>


                    <button type="submit" className="btn btn-primary text-right">Guardar</button>
                </form>
            </div>
        );
    }
}
export default SubEventoForm;