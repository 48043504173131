import React, {useState } from 'react';
import {BoxArrowLeft, PersonLinesFill, Window} from 'react-bootstrap-icons';
import { Helmet } from "react-helmet-async";
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import Conexion from "../../Conexion/Conexion";
import { LinkButton, EventLinkButton } from '../components/AppButton';
import AppLogo from '../components/AppLogo';

import useToken from '../../useToken/useToken';
const {apiUrl} = Conexion();

async function traerEventos() {
  return fetch(`${apiUrl}/api/eventos/cliente/id`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('token')
    }
  }).then(r =>{
    if (r.status > 399) {
      if(r.status === 401){
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
      }
      console.log("Error","Could not reach server");
    }
    return r.json()
  })
}

function ListaEventos() {
  const [state, setState] = useState({
    eventos: [],
    isfetched: false
  });

  const { user } = useToken();
  const cargarDatosEventos = async () => {
    const dEventos = await traerEventos();
    if (dEventos) {
      setState({
        eventos:  dEventos,
        isfetched: true
      })
    }
  }

  if(!state.isfetched){
    cargarDatosEventos();
  }


  const Lista = () =>{
    let eventos = state.eventos;
    var lista = eventos.map((evento, i) =>{
     
      let fecha = formatInTimeZone(evento.fecha_evento,'UTC', "dd/MM/yyyy", {
        awareOfUnicodeTokens: true
      })

      return(
        <EventLinkButton key={i} evento={evento} fecha={fecha} apiUrl={apiUrl} />
      );
    });

      return (
      <div className="d-flex flex-column align-items-center">
        {state.isfetched == false ? <h2 className='text-info'>Cargando...</h2>: eventos.length > 0  ?lista: <h2 className='text-danger'>No tienes eventos</h2>}
      </div>
    )
  }


  return (
    <div className='container-sm'>
      <Helmet>
        <title>App - Glup Ticket</title>
      </Helmet>
      <div className='text-center'>
          <AppLogo />
        <div>
          <h1 className='extraBold'>Mis eventos</h1>
          <Lista/>
          <div className="d-flex flex-column">
            {user.rol=="1"? <LinkButton to="/app/panel" className='mb-1' title={<><Window size={24} />&nbsp; Mi Panel</>}/>:""}
            {/* {user.rol=="1"? <Link to="/app/evento/nuevo" className='app-button'><Calendar2PlusFill size={24} />&nbsp; Crear Evento</Link>:""} */}
            {user.rol=="1"? <LinkButton to="/app/roles" className='mb-1' title={<><PersonLinesFill size={24} />&nbsp; Gestionar Roles</>} />:""}
            <LinkButton to="/app/logout" className='my-4' border title={<><BoxArrowLeft size={24}/>&nbsp; Cerrar Sesion</>} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListaEventos;