import React, { useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Conexion from '../../../Conexion/Conexion';
import Sidebar from '../../Sidebar/Sidebar';
import RolNuevo from './RolNuevo';
const { apiUrl } = Conexion();

async function cargarUsuarios(idCliente) {
    return fetch(`${apiUrl}/api/usuarios/roles/${idCliente}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
      }
    })
      .then(r => {
        if (r.status > 399) {
          if (r.status === 401) {
            alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
            sessionStorage.clear();
            window.location.replace(window.location.origin);
            console.log(r.status)
          }
          console.log("Error", "Could not reach server");
        }
        return r.json()
      })
  }

  const DataRoles = (props) => {

    
    const actionsMemo = React.useMemo(() => <RolNuevo idPadre={props.idPadre} />, []);
  
    /*const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = props.data.filter(
      item => item.nombre + ' ' + item.apellido_p + ' ' +  item.apellido_m && (item.nombre + ' ' + item.apellido_p + ' ' +  item.apellido_m).toLowerCase().includes(filterText.toLowerCase()),
    );*/
  
    /*const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
        }
      };
  
      return (
        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
      );
    }, [filterText, resetPaginationToggle]);*/
  
    return <DataTable
      columns={columns}
      data={props.data}//filteredItems
      title="Administrar Roles"
      //expandableRows
      actions={actionsMemo}
      //expandOnRowClicked
      //expandableRowsComponent={ExpandedComponent}
      //expandableRowsComponentProps={{ openEdit: props.openEdit }}
      pagination
  
      //subHeader
      //={subHeaderComponentMemo}
      persistTableHead />;
  };

  const columns = [{
    name: 'Nombre',
    selector: row => row.nombre + ' ' + row.apellido_p + ' ' + row.apellido_m
  },
  {
    name: 'Correo',
    selector: row => row.correo
  },
  {
    name: 'Contraseña',
    selector: row => row.password
  },
  {
    name: 'Telefono',
    selector: row => row.telefono,
  },
  {
    name: 'Rol',
    selector: row => row.descripcion,
  },
  {
    name: 'Estado',
    selector: row => {
      switch (row.estado) {
        case 1:
          return "Activo"
        case 0:
          return "Inactivo"
        default:
          return ""
      }
    },
    sortable: true
  }
  ];


const ClienteRoles = () => {
    const { clienteId } = useParams();
    const [state, setState] = useState({
        datos:[],
        isfetched:false
    });
    const cargarDatos= async ()=>{
        let roles= await cargarUsuarios(clienteId);
        if(roles){
            setState({
                datos:roles,
                isfetched:true
            })
        }
    }

    if(!state.isfetched){
        cargarDatos();
    }

    return ( <>
        <Sidebar clientes='active' />
        <div className='container overflow-auto' style={{height:"100vh",background:"#edcebb"}}>
            <DataRoles data={state.datos} idPadre={clienteId} />
        </div></>
    );
}
export default ClienteRoles;