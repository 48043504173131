import React,{ useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import DatePicker from "react-datepicker";
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import es from 'date-fns/locale/es';
import { toast, ToastContainer } from 'react-toastify';
import styled from "styled-components";
import { Form } from 'react-bootstrap';
import {Calendar2Event, Upload } from 'react-bootstrap-icons';
import { Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { AppButton, CustomButton } from '../../App/components/AppButton';
import Conexion from '../../Conexion/Conexion';

import QRfake from "../../../assets/images/qr-sobrepuesto.png";
import Fondo1 from "../../../assets/images/Plantillas/Fondo 1.png";
import Banner1 from "../../../assets/images/Plantillas/Banner 1.jpg";

const { apiUrl } = Conexion();

async function crearEvento(datos) {
    return fetch(`${apiUrl}/api/eventos/cliente`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
      },
      body: JSON.stringify(datos)
    })
      .then(r => {
        if (r.status > 399) {
          if (r.status === 401) {
            alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
            sessionStorage.clear();
            window.location.replace(window.location.origin);
            console.log(r.status)
          }
          console.log("Error", "Could not reach server");
        }
        return r.json()
      })
  }
  
  async function crearInvitadoAlter(datos) {
    return fetch(`${apiUrl}/api/invitados/invitados`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
      },
      body: JSON.stringify(datos)
    }).then(r => {
      if (r.status > 399) {
        if (r.status === 401) {
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
        }
        console.log("Error", "Could not reach server");
      }
      return r.json()
    })
  }
  async function actualizarEstadoEvento(datos, id) {
    return fetch(`${apiUrl}/api/eventos/estado/` + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
      },
      body: JSON.stringify(datos)
    }).then(r => {
      if (r.status > 399) {
        if (r.status === 401) {
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
        }
        console.log("Error", "Could not reach server");
      }
      return r.json()
    })
  }
  async function GenerarAllQR(datos, id) {
    return fetch(`${apiUrl}/api/invitados/evento/generarTodos/` + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
      },
      body: JSON.stringify(datos)
    }).then(r => {
      if (r.status > 399) {
        if (r.status === 401) {
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
        }
        console.log("Error", "Could not reach server");
      }
      return r.json()
    })
  }
  async function DesactivarPlanUsuario(id) {
    return fetch(`${apiUrl}/api/planes/desactivar-plan-usuario/` + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
      }
    }).then(r => {
      if (r.status > 399) {
        if (r.status === 401) {
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
        }
        console.log("Error", "Could not reach server");
      }
      return r.json()
    })
  };
  
  async function cargarPaisesYCiudades() {
    return fetch(`${apiUrl}/api/eventos/geografia_info`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
  };

  async function subirImagen(datos) {
    return fetch(`${apiUrl}/api/eventos/imagen/`, {
      method: 'POST',
      headers: {
        'Authorization': sessionStorage.getItem('token')
      },
      body: datos
    }).then(r => {
      if (r.status > 399) {
        if (r.status === 401) {
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
        }
        console.log("Error", "Could not reach server");
      }
      return r.json()
    })
  };

function CreateEvent0({plan, cliente}){

    const [state, setState]= useState({
        id_cliente: cliente.id,
        nombre:'',
        cant_invitados: plan.cant_tickets,
        fecha_evento: '',
        fecha_seleccionada: null,
        imagen_evento: '',
        imagen_qr: '',
        archivo_evento: null,
        id_pais: '',
        id_ciudad: '',
        archivo_qr: null,
        vp_banner: '',
        vp_ticket: ''
    });
    const [dropdownData, setDropdownData] = useState({
      paises:[],
      ciudades:[],
      pais_seleccionado: null,
      ciudad_seleccionado: null,
      filtro_ciudades: []
    });
    const navigate = useNavigate();
    const hiddenFileInput1 = React.useRef(null);
    const hiddenFileInput2 = React.useRef(null);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);

    const cargarDatos = async () =>{
      let _state = _.cloneDeep(state)
      const { paises, ciudades } = await cargarPaisesYCiudades();
      let _paises = paises.map(({id, codigo, nombre}) => ({ value: codigo, label: nombre, id: id }));
      let _ciudades = ciudades.map(({id, nombre, codigo_pais}) => ({ value: id, label: nombre, cod_pais: codigo_pais }));
      let _pais_default = _.find(_paises,['value','BO']); //Bolivia
      setDropdownData({ 
          paises: _paises,
          ciudades: _ciudades,
          pais_seleccionado: _pais_default,
          ciudad_seleccionado: null,
          filtro_ciudades: _.filter(_ciudades, ['cod_pais',_pais_default.value])
      })
      _state.id_pais = _pais_default.id
      setState(_state)
    }

  useEffect(() => {
      cargarDatos();
  }, []);
    
    const handleChange = (e) => {
        let _state = _.cloneDeep(state);
    
        if (e.target.id === 'nombre') {
          _state.nombre = e.target.value;
        }
        if (e.target.id === 'cant_invitados') {
          _state.cant_invitados = e.target.value;
        }
        if (_state.archivo_evento != null) {
          _state.imagen_evento = plan.id + '' + _state.nombre + '' + _state.fecha_evento;
        }
        if (_state.archivo_qr != null) {
          _state.imagen_qr = plan.id + '' + _state.nombre + '' + _state.fecha_evento + 'fondoqr';
        }
        setState(_state)
    }
    const handleChangeFecha = (fecha) => {
        let _state = _.cloneDeep(state)
        const fecha_evento = formatInTimeZone(fecha, "UTC", "dd-MM-yyyy", {
          awareOfUnicodeTokens: true
        });
        _state.fecha_evento = fecha_evento;
        _state.fecha_seleccionada = fecha;
        if (_state.archivo_evento != null) {
          _state.imagen_evento = plan.id + '' + _state.nombre + '' + fecha_evento;
        }
        if (_state.archivo_qr != null) {
          _state.imagen_qr = plan.id + '' + _state.nombre + '' + fecha_evento + 'fondoqr';
        }
        setState(_state)
    };

    const handleChangePais = pais => {
      let _dropdownData = _.cloneDeep(dropdownData);
      let _state = _.cloneDeep(state);
      _dropdownData.pais_seleccionado = pais;
      _dropdownData.ciudad_seleccionado = null;
      _dropdownData.filtro_ciudades= _.filter(dropdownData.ciudades, ['cod_pais',pais.value]);
      _state.id_pais = pais.id;
      _state.id_ciudad = "";
      setDropdownData(_dropdownData);
      setState(_state);
  };
  const handleChangeCiudad = departamento => {
      let _state = _.cloneDeep(state);
      let _dropdownData = _.cloneDeep(dropdownData);
      _dropdownData.ciudad_seleccionado = departamento;
      _state.id_ciudad = departamento.value
      setDropdownData(_dropdownData);
      setState(_state);
  };

    const handleUploadClick1 = event => {
        hiddenFileInput1.current.click();
    };
    const handleUploadClick2 = event => {
        hiddenFileInput2.current.click();
    };

    const isFileImage = (file) => {
        return file && file['type'].split('/')[0] === 'image';
    }
    
    const validateSize = (file) => {
        let fileSize = file.size / 1024 / 1024; // in MiB;
        return fileSize < 1
    }

    const uploadFile = (e) => {
        let _state = _.cloneDeep(state);
        if (e.target.name === 'banner') {
            if (isFileImage(e.target.files[0]) == false) {
                toast.error("El archivo que subió no es de tipo imagen, intentelo de nuevo")
                return
            }
            if (validateSize(e.target.files[0]) == false) {
                toast.error("La imagen que subió pesa más de 1MB, intentelo de nuevo")
                return
            }
            _state.archivo_evento = e.target.files[0];
            _state.imagen_evento = plan.id + '' + _state.nombre + '' + _state.fecha_evento;
            _state.vp_banner = URL.createObjectURL(e.target.files[0])

        }
        if (e.target.name === 'ticket') {
            if (isFileImage(e.target.files[0]) == false) {
                toast.error("El archivo no tiene un formato de imagen, inténtelo de nuevo")
                return
            }
            if (validateSize(e.target.files[0]) == false) {
                toast.error("Excede el límite de tamaño de la imagen (1MB), inténtelo de nuevo")
                return
            }
            _state.archivo_qr = e.target.files[0];
            _state.imagen_qr = plan.id + '' + _state.nombre + '' + _state.fecha_evento + 'fondoqr';
            _state.vp_ticket = URL.createObjectURL(e.target.files[0])
        }
        setState(_state)
    }

    const GenerarInvitadoAlter = async (id_evento) => {
        if (state.cant_invitados > 0) {
          let datosInvitados = {
            nro_invitado: state.cant_invitados,
            id_evento: id_evento
          }
          const invitado = await crearInvitadoAlter(datosInvitados);
          if (!invitado) {
            toast.error('Ocurrió un error al Generar su Invitados', { progress: undefined });
            return false
          }
          let datosEvento = { estado: 3 }
          const estado = await actualizarEstadoEvento(datosEvento, id_evento);
          if (estado) {
            return true
          } else {
            toast.error('Error al guardar Evento', { progress: undefined });
            return false
          }
        } else {
          toast.error('No hay datos para guardar', { progress: undefined });
          return false
        }
      }
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (state.nombre === "" || state.fecha_evento === "" || state.cant_invitados === "" || state.id_pais === "" || state.id_ciudad === "") {
          toast.error('Por favor, rellene todos los campos', { progress: undefined });
          return;
        }
        if ((state.archivo_evento === null || state.archivo_qr === null) && !checked) {
          toast.error('Por favor, debe subir sus 2 imágenes', { progress: undefined });
          return;
        }
        if (state.cant_invitados > plan.cant_tickets) {
          toast.error('Supera la cantidad máxima de invitados: ' + this.state.max_invitados, { progress: undefined });
          return;
        }
        toast.info('Guardando, por favor espere', { progress: undefined });
        setLoading(true)
        const form = new FormData();
        const form2 = new FormData();
        if(!checked){
          form.append('files', state.archivo_evento, state.imagen_evento);
          form2.append('files', state.archivo_qr, state.imagen_qr);
        }
        try {
          let imagen = {ok:true, files:["PlantillaBanner1.jpg"]}
          let fondoqr = {ok:true, files:["PlantillaFondo1.png"]}
          if(!checked){
            imagen = await subirImagen(form);
            fondoqr = await subirImagen(form2);
          }  
          if (imagen.ok && fondoqr.ok) {
            const datos = {
              nombre: state.nombre,
              imagen: imagen.files[0],
              fondo_qr: fondoqr.files[0],
              fecha_evento: state.fecha_evento,
              cantidad_invitados: state.cant_invitados,
              id_cliente: state.id_cliente,
              id_administrador: state.id_cliente,
              id_pais: state.id_pais,
              id_ciudad: state.id_ciudad
            }
            const id_evento = await crearEvento(datos);
            if (id_evento !== null) {
              let invi = await GenerarInvitadoAlter(id_evento);
              if (!invi) { toast.error('Ocurrió un error al guardar Evento', { progress: undefined }); return; }
              let qr_generados = await GenerarAllQR({usuario: cliente.nombre_usuario},id_evento)
              if (!qr_generados.ok) { toast.error('Ocurrió un error al guardar Evento', { progress: undefined }); return; }
              let plan_desactivado = await DesactivarPlanUsuario(plan.id)
              if(plan_desactivado.ok){
                toast.success('Evento creado con éxito');
                navigate('/app/panel/eventos');
              }
            }
            else { toast.error('Ocurrió un error al guardar Evento', { progress: undefined }); }
          } else {
            toast.error('Ocurrió un error al subir las Imágenes', { progress: undefined });
          }
        } catch (error) {
          toast.error('Error Inesperado', { progress: undefined });
          console.log('Error', error);
        }
      }

    return (
         <form className="col-lg-8 mx-auto" onSubmit={handleSubmit}>
             <Row>
              <Col xs={12} className="mb-1" >
                <label className="form-label semiBold">Nombre de Evento:</label>
                <input type="text" className="form-control" disabled={loading} id="nombre" value={state.nombre} maxLength="35" placeholder='Nombre del Evento' required onChange={handleChange} />
              </Col>
              <Col xs={12} md={6} className="my-1">
                <label className="form-label semiBold">Fecha:</label>
                <DatePicker locale={es} minDate={new Date()} disabled={loading} showDisabledMonthNavigation required dateFormat="dd/MM/yyyy" selected={state.fecha_seleccionada} className="form-control" placeholderText="Fecha del Evento" onChange={handleChangeFecha} />
              </Col>
              <Col xs={12} md={6} className="my-1">
                <label className="form-label semiBold">Cantidad de Tickets:</label>
                <input type="number" max={plan.cant_tickets} min="0" disabled={loading} className="form-control" id="cant_invitados" value={state.cant_invitados} required placeholder='Cantidad de Tickets' onChange={handleChange} />
              </Col>
              <Col>
                  <label className="form-label semiBold" >Pais</label>
                  <Select id="country" options={dropdownData.paises} placeholder="Buscar Pais" required value={dropdownData.pais_seleccionado} onChange={handleChangePais} />
              </Col>
              <Col>
                  <label className="form-label semiBold" >Ciudad/Estado</label>
                  <Select id="state" options={dropdownData.filtro_ciudades} placeholder="Buscar Estado" required value={dropdownData.ciudad_seleccionado} onChange={handleChangeCiudad} />
              </Col>
            </Row>
            <Row className="my-1 border-top">
              <Col xs={12} className="my-1 d-flex flex-row">
                <label className="align-self-center semiBold">Usar plantilla predeterminada:</label>
                <Form.Check 
                  type="switch"
                  className='panel-toggle-switch align-self-center mx-2'
                  id={"custom-switch"}
                  onChange={()=> setChecked(!checked)}
                  checked={checked}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="my-1 text-center">
                <label>
                  <p className='form-label semiBold mt-3'>Banner del Evento:</p>
                  <ImgPreview className='rounded mb-1' src={!checked ? state.vp_banner : Banner1} width={300} height={100} />
                  { !checked 
                    && <>
                      <UploadButton type='button' className="form-control" onClick={handleUploadClick1} disabled={loading}><Upload /></UploadButton>
                      <input name="banner" type="file" accept="image/*" onChange={uploadFile} ref={hiddenFileInput1} style={{ display: 'none' }} disabled={loading} />
                    </>
                  }
                </label>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="my-1 text-center">
                <label>
                  <p className="form-label mt-3 semiBold">Imagen del Ticket:</p>
                  <div className='mb-1' style={{ width: '144px', height: '256px', position: 'relative', left: '0', top: '0' }}>
                    <QrPreview src={QRfake} />
                    {checked && <TitlePreview>Aqui estará el nombre del evento</TitlePreview>}
                    <ImgPreview className='rounded' src={!checked ? state.vp_ticket : Fondo1} width={144} height={256} />
                  </div>
                  { !checked 
                    && <>
                      <UploadButton type='button' className="form-control" onClick={handleUploadClick2} disabled={loading}><Upload /></UploadButton>
                      <input name="ticket" type="file" accept="image/*" onChange={uploadFile} ref={hiddenFileInput2} style={{ display: 'none' }} disabled={loading} />
                    </>
                  }
                </label>
              </Col>
            </Row>
            <Row className='mt-4 text-center'>
              <Col xs={12}>
                { loading == false 
                ? <>
                  <CustomButton type="button" id="cancel" title={"Cancelar"} className="mx-2 redBg whiteColor" action={()=>navigate('/app/panel/eventos')} />
                  <AppButton type="submit" disabled={loading} title={<><Calendar2Event size={24} />&nbsp; Guardar</>} />
                </>
                : <Spinner className="m-5" animation="border" />}
              </Col>
            </Row>
            <ToastContainer position="top-center"
        theme='colored'
        autoClose={2500}
        hideProgressBar={false}
        closeOnClick={false}
        pauseOnHover={true}
        draggable={false}
      />
        </form>
    );
}


const ImgPreview = styled.img`
background-color: #e8e8e8;
border: 1px solid #080808;
`;

const QrPreview = styled.img` 
  background: red;
  width: 48px;
  height: 48px;
  position: absolute;
  left: 47.8px;
  top: 152px;
  z-index: 1;
`
const UploadButton = styled.button`
  display: block;
  margin: auto;
  width: 100px;
  border: 1px solid #707070 !important;
  border-radius: 16px !important;
  color: black !important;
  background-color: #dcfdd4 !important;
`;

const TitlePreview = styled.span`
  position: absolute;
  color: white;
  top: 48px;
  font-size: 10px;
`;


export {
    CreateEvent0,
    // CreateEvent1,
    // CreateEvent2,
    // CreateEvent3
  }





    // function CreateEvent1({plan}){
        
    //     return (
    //          <form className="col-lg-8 mx-auto" onSubmit={handleSubmit}>
    //             <div className="mb-3">
    //                 <label htmlFor="nombre_ev" className="form-label">Nombre</label>
    //                 <input type="text" className="form-control" id="nombre" value={state.nombre} onChange={handleChange} />
    //             </div>
    //             <div className="mb-3">
    //                 <label htmlFor="cant_invitados" className="form-label">Cantidad de Tickets</label>
    //                 <input type="text" className="form-control" id="cant_invitados" value={state.cant_invitados} onChange={handleChange} />
    //             </div>
    //             <div className="mb-3">
    //                 <label className="form-label">Fecha Evento</label>
    //                 <DatePicker locale="es"  showDisabledMonthNavigation selected={state.fecha_seleccionada} dateFormat="dd/MM/yyyy" onChange={handleChangeFecha} />
    //             </div>
    //             <div className="mb-3">
    //                 <label htmlFor="imagen_ev" className="form-label">Imagen del Evento (Banner)</label>
    //                 <input className="form-control" type="file" onChange={uploadFile} />
    //             </div>
    //             <div className="mb-3">
    //                 <label htmlFor="imagen_qr" className="form-label">Diseño entrada QR (720x1280)</label>
    //                 <input className="form-control" type="file" onChange={uploadFile2} />
    //             </div>
    //             <button disabled={state.disabled} type="submit" className="btn btn-primary text-right">{!state.disabled ? <><Save style={{ verticalAlign: "text-bottom" }} /> Guardar </> : <Spinner animation='border' role='status'><span className='visually-hidden btn btn-sm'>Cargando...</span></Spinner>}</button>
    //         </form>
    //     );
    // }