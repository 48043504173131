import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import Login from '../Login/Login';
import { Helmet } from "react-helmet-async";
import Logout from '../Logout/Logout';
import Inicio from './Inicio/Inicio';
import useToken from '../useToken/useToken';
import Eventos from './Eventos/Eventos';

import Conexion from '../Conexion/Conexion';
import NuevoEventoForm from './Eventos/NuevoEventoForm';
//import Invitados from './Eventos/Invitados/Invitados';
import Precios from './Precios/Precios';
import Plan from './Plan/Plan';

import './Panel.css';
const { apiUrl } = Conexion();

async function datosCliente(cliente_id) {
  return fetch(`${apiUrl}/api/usuarios/cliente/${cliente_id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('token')
    }
  }).then(r => {
    if (r.status > 399) {
      if (r.status === 401) {
        alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
        sessionStorage.clear();
        window.location.replace(window.location.origin);
        console.log(r.status)
      }
      console.log("Error", "Could not reach server");
    }
    return r.json()
  })
}

let ValidarRol = (props) => {
  const { user } = useToken();
  if (!props.roles.includes(user.rol)) {
    return <Navigate push to="/app"/>
  }
  return props.element
}

export default function Panel() {
  //const [res, setRes] = useState(false);
  const { token, setToken, setUser, setTipo, user } = useToken();
  const [state, setState] = useState({ cliente: [], isFetched: false });


  useEffect(() => {
    if (user !== null) {
      cargarDatosCliente();
    }
  }, []);
  
  if (!token) {
    return <Login setToken={setToken} setUser={setUser} setTipo={setTipo} validar={"cliente"} />
  }


  const cargarDatosCliente = async () => {
    const dCliente = await datosCliente(user.id);
    if (dCliente) {
      setState({ cliente: dCliente, isFetched: true })
    }
  }

  return (<>
    <main className='panel'>
      <Helmet>
        <title>Mi Panel | Glup Ticket</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<ValidarRol roles={[1]}  element={<Inicio cliente={state.cliente} />} />} />
        <Route path="eventos" element={<ValidarRol roles={[1]}  element={<Eventos cliente={state.cliente} />} />} />
        <Route path="nuevo-evento" element={<ValidarRol roles={[1]}  element={<NuevoEventoForm cliente={state.cliente} />} />} />
        <Route path="precios" element={<ValidarRol roles={[1]}  element={<Precios cliente={state.cliente} />} />} />
        {/* <Route path="eventos/:eventoId" element={<ValidarRol roles={[1]}  element={<Invitados cliente={state.cliente} />} />} /> */}
        <Route path="comprar-plan" element={<ValidarRol roles={[1]}  element={<Plan cliente={state.cliente} />} />} />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="/*"
          element={
            <div style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </div>
          }
        />
      </Routes>
    </main>
  </>);

}