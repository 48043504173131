import React, {useState, useEffect} from "react";
import styled from "styled-components";
import _ from 'lodash'
// Components
import PricingTable from "../Elements/PricingTable";
import Conexion from "../../Conexion/Conexion";
import { useNavigate } from "react-router-dom";
const {apiUrl} = Conexion();

async function datosPlanes() {
  return fetch(`${apiUrl}/api/planes/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  }).then(r =>{
    return r.json()
  }) 
}

export default function Pricing({sessionToken, userToken}) {

  const [state, setState] = useState({planes:[], caracteristicas:[], isfetched:false});
  const navigate = useNavigate();

  const cargarDatosPlanes = async () => {
    const data = await datosPlanes();
    if (data.ok) {
        setState({
          planes:data.planes,
          caracteristicas:data.caracteristicas,
          isfetched:true
        });
    }
  }

  useEffect(() => {
    cargarDatosPlanes();
  }, []);

  const onBuyClick = (id, selected_price) => {
    if(sessionToken){
      navigate(`/app/panel/comprar-plan?id=${id}&selected=${selected_price}`)
    }else{
      navigate('/app/panel/precios')
    }

    
  }




  let planTable = state.planes.map((v)=>{
    return(
    <TableBox key={v.id}>
      <PricingTable
        plan_id={v.id}
        icon="shoppingCart"
        price={v.precios}
        quantity={v.cant_tickets}
        title={v.nombre}
        text={v.descripcion}
        offers={
          _.filter(state.caracteristicas, function(n) {
            return _.includes(v.caracteristicas,n.id)
          }).map((c)=>{
              return { name: c.nombre, cheked: true }
          })
        }
        action={onBuyClick}
      />
    </TableBox>
    )
  })


  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div>
          <HeaderInfo className="container">
            <h1 className="font40 extraBold">Precios</h1>
            <p className="font13">
            Adquiere nuestros paquetes ya que tenemos un precio ajustado a tus necesidades
              <br />
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            {planTable}
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 10px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 18%;
  min-width: 320px;
  margin: 0 10px;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




