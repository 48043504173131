import React from 'react';
import './sidebar.css';
import {Link} from 'react-router-dom';
import {BoxArrowLeft, Speedometer2, Table, Calendar3, PersonLinesFill, KeyFill} from 'react-bootstrap-icons';
import Logo from "../../../assets/images/glup-tickets-logo.png";  


class Sidebar extends React.Component {
  
  render(){
    return(
      <div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" style={{width: '200px',height: "100vh"}}>
          <Link to="/admin/" className={`d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none`}>
            <img src={Logo} alt="Logo Glup QR" width={150} />
          </Link>
          <hr />
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
              <Link to="/admin/" className={`nav-link text-white ${ this.props.dashboard }`} aria-current="page">
                <Speedometer2 size={20} />{' '}
                Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admin/tabla-eventos" className={`nav-link text-white ${ this.props.tabla_eventos }`} aria-current="page">
                <Table size={20} />{' '}
                Tabla Eventos
              </Link>
              
            </li>
            <li>
              <Link to="/admin/eventos" className={`nav-link text-white ${ this.props.eventos }`}>
              <Calendar3 size={20} />{' '}
                Eventos
              </Link>
            </li>
            <li>
              <Link to="/admin/clientes" className={`nav-link text-white ${ this.props.clientes }`}>
                <PersonLinesFill size={20} />{' '}
                Clientes
              </Link>
            </li>
          </ul>
          <hr />
          <div className="dropdown">
            <li className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown">
              <img src="https://avatars.githubusercontent.com/u/99840507?s=96&v=4" alt="" className="rounded-circle me-2" width={32} height={32} />
              <strong>Administrador</strong>
            </li>
            <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
              <li><Link to="/admin/cambiar-contrasena" className={`dropdown-item text-white`}>
              <KeyFill size={20} />{` `}
                Cambiar Contraseña
                </Link></li>
              <li><hr className="dropdown-divider" /></li>
              <li><Link to="/admin/logout" className={`dropdown-item text-white`}>
                <BoxArrowLeft size={20} />{` `}
                Cerrar Sesion
              </Link></li>
            </ul>
          </div>
        </div>
    );  
  }
}
export default Sidebar;