import React from 'react';
import { Helmet } from "react-helmet-async";
import TopNavbar from '../TopNavbar/TopNavbar';
import Footer from '../Footer/Footer';
import Pricing from '../../Landing/Sections/Pricing';
import useToken from '../../useToken/useToken';


export default function Precios(props) {

    const { token, user } = useToken();

    return (
        <>
            <Helmet>
                <title>Precios | Glup Ticket</title>
            </Helmet>
            <TopNavbar cliente={props.cliente} active={"precios"} />
            <Pricing sessionToken={token} sessionUser={user}/>
            <Footer />
        </>
    );
}