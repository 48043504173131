import React,{useState} from 'react';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import Conexion from '../../Conexion/Conexion';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar';
const {apiUrl} = Conexion();

async function crearInvitado(datos) {
  return fetch(`${apiUrl}/api/invitados`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('token')
      },
      body: JSON.stringify(datos)
  }).then(r =>{
    if (r.status > 399) {
      if(r.status === 401){
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
      }
      console.log("Error","Could not reach server");
    }
    return r.json()
  })
}
async function actualizarEstadoEvento(datos,id) {
  return fetch(`${apiUrl}/api/eventos/estado/`+id, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('token')
      },
      body: JSON.stringify(datos)
  }).then(r =>{
    if (r.status > 399) {
      if(r.status === 401){
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
      }
      console.log("Error","Could not reach server");
    }
    return r.json()
  })
}


function InputCSV(props) {
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState({
      isloading:false,
      progress:0
    });

   
    // process CSV data
    const processData = dataString => {
      const dataStringLines = dataString.split(/\r\n|\n/);
      const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
   
      const list = [];
      for (let i = 1; i < dataStringLines.length; i++) {
        const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        if (headers && row.length === headers.length) {
          const obj = {};
          for (let j = 0; j < headers.length; j++) {
            let d = row[j];
            if (d.length > 0) {
              if (d[0] === '"')
                d = d.substring(1, d.length - 1);
              if (d[d.length - 1] === '"')
                d = d.substring(d.length - 2, 1);
            }
            if (headers[j]) {
              obj[headers[j]] = d;
            }
          }
   
          // remove the blank rows
          if (Object.values(obj).filter(x => x).length > 0) {
            list.push(obj);
          }
        }
      }
   
      // prepare columns list from headers
      const columns = headers.map(c => ({
        name: c,
        selector: row => row[c],
      }));
   
      setData(list);
      setColumns(columns);
    }
   
    // handle file upload
    const handleFileUpload = e => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        processData(data);
      };
      reader.readAsBinaryString(file);
    }

   async function handleSubmit(e){
        e.preventDefault();
        setLoading({
          isloading:true,
          progress:0  
        })
        let totalInvitados = data.length;
        if(totalInvitados >0){
            var tieneInvitados = true;
            let contador = 1;
            for(let element of data){
                const datos = {
                  nro_invitado: contador,
                  nombre: element["Nombre"],
                  correo: element["Correo"],
                  qr: '',
                  id_evento: props.IdEvento
                }
                let progreso = (contador/totalInvitados)*100;
                setLoading({
                  isloading:true,
                  progress:progreso
                })
                contador++;
                const invitado = await crearInvitado(datos);
                if(invitado) {
                }
                else { 
                  alert("Error");
                  tieneInvitados = false;
                  break;
              }
            }
            if(tieneInvitados){
              const datos = {
                estado:  (props.TipoEvento == 3 ? 3 : 2)
              }
              const estado = await actualizarEstadoEvento(datos,props.IdEvento);
              if (estado){
                alert("Datos guardados correctamente");
                window.location.reload(false);
              }else{
                alert("Error");
              }
            }
        }else{
            alert("No hay datos para guardar")
        }
      }
   
    return (
      <div>
          <form className="mb-3" onSubmit={handleSubmit}>
        <h3>Importar lista de Invitados (.csv,.xlsx,.xls)</h3>
        <input
          type="file"
          accept=".csv, .xlsx, .xls"
          onChange={handleFileUpload}
        />
        <Button type="submit" disabled={loading.isloading}>Guardar</Button>
        </form>
        {!loading.isloading 
         ? <DataTable pagination highlightOnHover columns={columns} data={data}/>
         : <div className="container-md text-center mt-5">
         <Spinner as="span" animation="border" variant="warning" role="status" aria-hidden="true"/>
         <h4 >Subiendo Invitados... Por favor Espere</h4>
         <ProgressBar animated variant="warning" now={loading.progress} label={`${loading.progress.toFixed(0)}%`}/>
       </div>
        }
       
        
      </div>
    );
  }
 
export default InputCSV;