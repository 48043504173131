import React from "react";
import styled from "styled-components";
import {Link} from 'react-router-dom';

function FullButton({ title, action, border }) {
  return (
    <FullWrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </FullWrapper>
  );
}

function AppButton({ title, action, border, className }) {
    return (
      <AppWrapper
        className={"animate pointer radius8 " + className}
        onClick={action ? () => action() : null}
        border={border}
      >
        {title}
      </AppWrapper>
    );
  }

  function CustomButton({ title, action, border, className }) {
    return (
      <CustomWrapper
        type="button"
        className={"animate pointer radius8 " + className}
        onClick={action ? () => action() : null}
        border={border}
      >
        {title}
      </CustomWrapper>
    );
  }


function LinkButton({ title, to, action, border, className }) {
  return (
    <Link
      to={to}
    >
      <AppWrapper
        className={`animate pointer radius8 ${className}`}
        onClick={action ? () => action() : null}
        border={border}
      >
        {title}
      </AppWrapper>
    </Link>
  );
}

function EventLinkButton({ evento, fecha, apiUrl }) {
  let i = evento.id
  return (
    <Link to={`/app/evento/${evento.id}`} key={'div-evento'+i} style={{width:'300px', marginBottom:'8px'}} >
      <EventPaper className="radius8">
        <img className="animate pointer radius8" key={'img'+i} src={`${apiUrl}/api/eventos/imagen/${evento.imagen}`} width={300} height={105} alt="" />
        <EventText>
          <h4 className="font17 extraBold darkColor mb-0 px-2" style={{backgroundColor: '#ffffffa3'}} key={'evento-nombre'+i}>{evento.nombre}</h4>
          <p className="font13 darkColor px-2" key={'evento-fecha'+i} style={{backgroundColor: '#ffffffa3'}}>{(fecha)}</p>
        </EventText>
      </EventPaper>
    </Link>
  );
}

function EventImg({ evento, fecha, apiUrl }) {
  let i = evento.id
  return (
      <EventPaper className="radius8" style={{height:'80px'}}>
        <img className="animate pointer radius8" key={'img'+i} src={`${apiUrl}/api/eventos/imagen/${evento.imagen}`} style={{width: '100%',  height: '100%'}} alt="" />
        <EventText>
          <h4 className="font17 extraBold darkColor mb-0 px-2" style={{backgroundColor: '#ffffffa3'}} key={'evento-nombre'+i}>{evento.nombre}</h4>
          <p className="font13 darkColor px-2" key={'evento-fecha'+i} style={{backgroundColor: '#ffffffa3'}}>{(fecha)}</p>
        </EventText>
      </EventPaper>
  );
}

  //   border: 1px solid ${(props) => (props.border ? "#707070" : "#178fcf")};
  //   background-color: ${(props) => (props.border ? "transparent" : "#178fcf")};
  //   width: 100%;
  //   padding: 15px;
  //   outline: none;
  //   color: ${(props) => (props.border ? "#707070" : "#fff")};
  //   :hover {
  //     background-color: ${(props) => (props.border ? "transparent" : "#55479a")};
  //     border: 1px solid #55479a;
  //     color: ${(props) => (props.border ? "#55479a" : "#fff")};
  //   }

const AppWrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#178fcf")};
  background-color: ${(props) => (props.border ? "transparent" : "#178fcf")};
  width: 300px;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#55479a")};
    border: 1px solid #55479a;
    color: ${(props) => (props.border ? "#55479a" : "#fff")};
  }
`;

const CustomWrapper = styled.button`
  border: 1px solid transparent;
  padding: 15px;
  outline: none;
`;

const FullWrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#178fcf")};
  background-color: ${(props) => (props.border ? "transparent" : "#178fcf")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#55479a")};
    border: 1px solid #55479a;
    color: ${(props) => (props.border ? "#55479a" : "#fff")};
  }
`;

const EventPaper = styled.div`
  width: 100%;
  position: relative;
  background-color: #f3fafd;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
`;

const EventText = styled.div`
  width: 100%;
  margin: auto;
  text-align: left;
  position: absolute;
  top: 5px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export{
    FullButton,
    AppButton,
    LinkButton,
    EventLinkButton,
    EventImg,
    CustomButton
}