import React, { useState } from 'react';
import {Link,useParams} from 'react-router-dom';
import Logo from "../../../assets/images/glup-tickets-logo.png";
import ImagenIpsum from "../../../assets/images/Banner.jpeg";
import { QrCodeScan,ListCheck, ChevronLeft } from 'react-bootstrap-icons';

function datosEventoDemo(eventoId){
  let eventos = require('../ListaEventos/eventos.json');
  let evento=eventos[eventoId-1];
  return evento;
}



function Menu() {

    const {eventoId} = useParams();
    const [evento, setEvento] = useState([]);
    const [imagen, setImagen] = useState();


    const cargarDatosEvento = async () => {
      let dEvento =  datosEventoDemo(eventoId);
      if (dEvento) {
          setEvento(dEvento);
          var objectURL = "../../../assets/images/Cualquier-Ipsum.png";
          setImagen(objectURL);
      }
    }
    if(evento.length === 0){
      cargarDatosEvento();
    }

  return (
    <div className='app-wrapper wrapper'>
      <div >
        <div className='demo-top-left-circle'></div>
        <div className='demo-middle-rigth-circle'></div>
        <div>
          <img src={Logo} alt="Logo Glup QR" width="200px"/>
        </div>
        <div>
          <h1 className='demo-title'>{evento.nombre}</h1>
          <img className='demo-imagen' src={ImagenIpsum} alt="" width={300} height={105} />
          <div className='d-grid gap-3 col mx-auto mt-5 px-4'>
                <Link to={`/demo/evento/${eventoId}/scan-qr`} className='demo-button'><QrCodeScan size={20} /> &nbsp;Escanear QR</Link>
                <Link to={`/demo/evento/${eventoId}/ingresos`}  className='demo-button'><ListCheck size={20} /> &nbsp;Ver Ingresos</Link>
                <Link to={`/demo`}  className='demo-button'><ChevronLeft size={20} /> &nbsp; Volver</Link>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Menu;