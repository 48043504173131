import React from 'react';
import _ from 'lodash';
import DatePicker from "react-datepicker";
import * as MultiDatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Select from 'react-select';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import es from 'date-fns/locale/es';
import { Container, Row, Col } from 'react-bootstrap';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import "react-datepicker/dist/react-datepicker.css";
import Conexion from '../../Conexion/Conexion';
const { apiUrl } = Conexion();



async function crearEvento(datos) {
    return fetch(`${apiUrl}/api/eventos`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        },
        body: JSON.stringify(datos)
    })
        .then(r => {
            if (r.status > 399) {
                if (r.status === 401) {
                    alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                    sessionStorage.clear();
                    window.location.replace(window.location.origin);
                    console.log(r.status)
                }
                console.log("Error", "Could not reach server");
            }
            return r.json()
        })
}

async function crearEventoMembresia(datos) {
    return fetch(`${apiUrl}/api/eventos/membresia`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        },
        body: JSON.stringify(datos)
    })
        .then(r => {
            if (r.status > 399) {
                if (r.status === 401) {
                    alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                    sessionStorage.clear();
                    window.location.replace(window.location.origin);
                    console.log(r.status)
                }
                console.log(r.status)
                console.log("Error", "Could not reach server");
            }
            return r.json()
        })
}

async function crearEventoPadre(datos) {
    return fetch(`${apiUrl}/api/eventos/subevento`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        },
        body: JSON.stringify(datos)
    })
        .then(r => {
            if (r.status > 399) {
                if (r.status === 401) {
                    alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                    sessionStorage.clear();
                    window.location.replace(window.location.origin);
                    console.log(r.status)
                }
                console.log("Error", "Could not reach server");
            }
            return r.json()
        })
}

async function cargarClientes() {
    return fetch(`${apiUrl}/api/usuarios/clientes`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
};

async function cargarPaisesYCiudades() {
    return fetch(`${apiUrl}/api/eventos/geografia_info`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
};

async function subirImagen(datos) {
    return fetch(`${apiUrl}/api/eventos/imagen/`, {
        method: 'POST',
        headers: {
            'Authorization': sessionStorage.getItem('token')
        },
        body: datos
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
};

class EventoForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientes: [],
            paises: [],
            ciudades: [],
            filtro_ciudades:[],
            cliente_seleccionado: null,
            pais_seleccionado: null,
            ciudad_seleccionado: null,
            nombre: '',
            fecha_evento: '',
            limite: '',
            fecha_seleccionada: null,
            fecha_limite: null,
            imagen_evento: '',
            imagen_qr: '',
            dias:[],
            archivo_evento: null,
            archivo_qr: null,
            subEvento: 0,
            id_cliente: 0,
            id_pais: null,
            id_administrador: sessionStorage.getItem('user_id'),
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleRadio = this.handleRadio.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.uploadFile2 = this.uploadFile2.bind(this);
    }
    handleChange(e) {
        if (e.target.id === 'nombre') {
            this.setState({ nombre: e.target.value });
        }
        if (this.state.archivo_evento != null) {
            let nombre_imagen = e.target.value + '' + this.state.fecha_evento;
            this.setState({ imagen_evento: nombre_imagen });
        }
        if (this.state.archivo_qr != null) {
            let nombre_imagen = e.target.value + '' + this.state.fecha_evento + 'fondoqr';
            this.setState({ imagen_evento: nombre_imagen });
        }
    }

    handleRadio(v){
        this.setState({subEvento:v})
    }
    handleChangeClientes = cliente => {
        this.setState({ cliente_seleccionado: cliente });
        this.setState({ id_cliente: cliente.value });
        if (this.state.archivo_evento != null) {
            let nombre_imagen = this.state.nombre + '' + cliente.value + '' + this.state.fecha_evento;
            this.setState({ imagen_evento: nombre_imagen });
        }
        if (this.state.archivo_qr != null) {
            let nombre_imagen = this.state.nombre + '' + cliente.value + '' + this.state.fecha_evento + 'fondoqr';
            this.setState({ imagen_qr: nombre_imagen });
        }
    };
    handleChangePais = pais => {
        this.setState({ pais_seleccionado: pais });
        this.setState({ id_pais: pais.id });
        this.setState({ ciudad_seleccionado: null });
        this.setState({ filtro_ciudades: _.filter(this.state.ciudades, ['cod_pais',pais.value]), });
    };
    handleChangeCiudad = ciudad => {
        this.setState({ ciudad_seleccionado: ciudad });
    };
    handleChangeFecha = fecha => {
        const fecha_evento = formatInTimeZone(fecha, "UTC", "dd-MM-yyyy", {
            awareOfUnicodeTokens: true
        });
        this.setState({ fecha_evento: fecha_evento });
        this.setState({ fecha_seleccionada: fecha });
        if(this.state.fecha_limite==null || this.state.fecha_limite < fecha){
            this.setState({ limite: fecha_evento });
            this.setState({ fecha_limite: fecha });
        }
        if (this.state.archivo_evento != null) {
            let nombre_imagen = this.state.nombre + '' + this.state.id_cliente + '' + fecha_evento;
            this.setState({ imagen_evento: nombre_imagen });
        }
        if (this.state.archivo_qr != null) {
            let nombre_imagen = this.state.nombre + '' + this.state.id_cliente + '' + fecha_evento + 'fondoqr';
            this.setState({ imagen_qr: nombre_imagen });
        }
    }
    handleChangeLimite = fecha => {
        console.log(fecha)
        const fecha_limite = formatInTimeZone(fecha, "UTC", "dd-MM-yyyy", {
            awareOfUnicodeTokens: true
        });
        this.setState({ limite: fecha_limite });
        this.setState({ fecha_limite: fecha });
        this.setState({ dias: [] });
    }
    handleChangeDias = fechas => {
        let fecha_inicio = this.state.fecha_seleccionada;
        let fecha_fin = this.state.fecha_limite;
        let fechas2 = fechas.map((f)=>{ return new Date(f.format("MM/DD/YYYY")) });
        let _fechas = _.filter(fechas2, function(o) {  if(fecha_inicio <= o && fecha_fin >= o) return o; });
        this.setState({ dias: _fechas });
    }
    uploadFile(e) {
        let files = e.target.files;
        let nombre_imagen = this.state.nombre + '' + this.state.id_cliente + '' + this.state.fecha_evento;
        this.setState({ archivo_evento: files[0] });
        this.setState({ imagen_evento: nombre_imagen });
    }
    uploadFile2(e) {
        let files = e.target.files;
        let nombre_imagen = this.state.nombre + '' + this.state.id_cliente + '' + this.state.fecha_evento + 'fondoqr';
        this.setState({ archivo_qr: files[0] });
        this.setState({ imagen_qr: nombre_imagen });
    }
    async handleSubmit(e) {
        e.preventDefault(); 
        if (this.state.nombre === "" || 
            this.state.id_cliente === 0 || 
            this.state.fecha_evento === "" || 
            this.state.limite === "" || 
            this.state.id_pais === null || 
            this.state.ciudad_seleccionado === null) {
            window.alert("Por favor, Ingrese todos los datos");
            return;
        }

        if(this.state.subEvento===1){
            const form = new FormData();
            form.append('files', this.state.archivo_evento, this.state.imagen_evento);
            try {
                const imagen = await subirImagen(form);
                if (imagen.ok) {
                    const datos = {
                        nombre: this.state.nombre,
                        fecha_evento: this.state.fecha_evento,
                        fecha_limite: this.state.fecha_limite,
                        imagen: imagen.files[0],
                        subEvento:this.state.subEvento,
                        id_evento:0,
                        id_cliente: this.state.id_cliente,
                        id_administrador: this.state.id_administrador,
                        estado:3,
                        id_pais: this.state.id_pais,
                        id_ciudad: this.state.ciudad_seleccionado.id
                    }
                    const evento = await crearEventoPadre(datos);
                    if (evento) {
                        alert(evento);
                        window.location.reload(false);
                    }
                    else { alert("Error al guardar Evento"); }
                }
            }catch (error) {
                alert("Error al subir la imagen");
                console.log('Error', error);
            }
        }
        if(this.state.subEvento===2){
            if (this.state.archivo_evento === null ||
                this.state.archivo_qr === null ||
                this.state.dias.length === 0 ) {
                window.alert("Ingrese todos los datos");
                return;
            }
            const form = new FormData();
            const form2 = new FormData();
            form.append('files', this.state.archivo_evento, this.state.imagen_evento);
            form2.append('files', this.state.archivo_qr, this.state.imagen_qr);
            try {
                const imagen = await subirImagen(form);
                const fondoqr = await subirImagen(form2);
                if (imagen.ok && fondoqr.ok) {
                    let _dias = _.map(this.state.dias, (o) =>{
                        return formatInTimeZone(o, "UTC", "dd-MM-yyyy", {
                            awareOfUnicodeTokens: true
                        });
                    })
                    const datos = {
                        nombre: this.state.nombre,
                        imagen: imagen.files[0],
                        fondo_qr: fondoqr.files[0],
                        fecha_evento: this.state.fecha_evento,
                        fecha_limite: this.state.fecha_limite,
                        subEvento:this.state.subEvento,
                        id_cliente: this.state.id_cliente,
                        id_administrador: this.state.id_administrador,
                        dias: _dias,
                        id_pais: this.state.id_pais,
                        id_ciudad: this.state.ciudad_seleccionado.id
                    }
                    const evento = await crearEventoMembresia(datos);
                    if (evento) {
                        alert(evento);
                        window.location.reload(false);
                    }
                    else { alert("Error al guardar Evento"); }
                } else {
                    alert("Error al subir la imagen");
                }
            } catch (error) {
                alert("Error");
                console.log('Error', error);
            }
        }
        if(this.state.subEvento===0 || this.state.subEvento===3){
            if (this.state.archivo_evento === null ||
                this.state.archivo_qr === null) {
                window.alert("Ingrese todos los datos");
                return;
            }
            const form = new FormData();
            const form2 = new FormData();
            form.append('files', this.state.archivo_evento, this.state.imagen_evento);
            form2.append('files', this.state.archivo_qr, this.state.imagen_qr);
            try {
                const imagen = await subirImagen(form);
                const fondoqr = await subirImagen(form2);
                if (imagen.ok && fondoqr.ok) {
                    const datos = {
                        nombre: this.state.nombre,
                        imagen: imagen.files[0],
                        fondo_qr: fondoqr.files[0],
                        fecha_evento: this.state.fecha_evento,
                        fecha_limite: this.state.fecha_limite,
                        subEvento:this.state.subEvento,
                        id_cliente: this.state.id_cliente,
                        id_administrador: this.state.id_administrador,
                        id_pais: this.state.id_pais,
                        id_ciudad: this.state.ciudad_seleccionado.id
                    }
                    const evento = await crearEvento(datos);
                    if (evento) {
                        alert(evento);
                        window.location.reload(false);
                    }
                    else { alert("Error al guardar Evento"); }
                } else {
                    alert("Error al subir la imagen");
                }
            } catch (error) {
                alert("Error al subir la imagen");
                console.log('Error', error);
            }
        }
        

    }

    async componentDidMount() {
        let clientes = await cargarClientes();
        let { paises, ciudades } = await cargarPaisesYCiudades();
        let pais_datos = paises.map(({id, codigo, nombre}) => ({ value: codigo, label: nombre, id: id }))
        let ciudades_datos = ciudades.map(({id, codigo, nombre, codigo_pais}) => ({ value: codigo, label: nombre, cod_pais: codigo_pais, id: id }));
        let pais_bolivia = _.find(pais_datos,['value','BO']);
        this.setState({ 
            clientes: clientes.map(({ id, nombre, apellido_p, apellido_m, correo }) => ({ value: id, label: correo + ' - ' + nombre + ' ' + apellido_p + ' ' + apellido_m })) ,
            paises: pais_datos,
            ciudades: ciudades_datos,
            filtro_ciudades: _.filter(ciudades_datos, ['cod_pais',pais_bolivia.value]),
            pais_seleccionado: pais_bolivia,
            id_pais: pais_bolivia.id
        })
    }

    render() {
        return (
            <Container>
                <h2 className="text-center">Registrar Nuevo Evento</h2>
                <form className="col-lg-8 mx-auto" onSubmit={this.handleSubmit}>
                    <Row className="mb-3">
                        <Col>
                            <label htmlFor="nombre_ev" className="form-label">Nombre</label>
                            <input type="text" className="form-control" id="nombre" value={this.state.nombre} onChange={this.handleChange} />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <label className="form-label">Cliente</label>
                            <Select id="id_clientes" options={this.state.clientes} placeholder="Buscar Cliente" value={this.state.cliente_seleccionado} onChange={this.handleChangeClientes} />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <label className="form-label">Fecha Evento</label>
                            <DatePicker locale={es} selected={this.state.fecha_seleccionada} dateFormat="dd/MM/yyyy" onChange={this.handleChangeFecha}/>
                        </Col>
                        <Col>
                            <label className="form-label">Fecha Límite</label>
                            <DatePicker locale={es} minDate={new Date(this.state.fecha_seleccionada)} selected={this.state.fecha_limite} dateFormat="dd/MM/yyyy" onChange={this.handleChangeLimite} />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <p className="form-label">Tipo de Evento</p>
                            <ToggleButtonGroup type="radio" name='group_radio' value={this.state.subEvento} onChange={this.handleRadio} >
                                <ToggleButton id='btr_cerrado' value={0} variant={'outline-secondary'}>
                                    Cerrado
                                </ToggleButton>
                                <ToggleButton id='btr_subevento' value={1} variant={'outline-secondary'}>
                                    Subevento
                                </ToggleButton>
                                <ToggleButton id='btr_membresia' value={2} variant={'outline-secondary'}>
                                    Membresia
                                </ToggleButton>
                                <ToggleButton id='btr_multiuso' value={3} variant={'outline-secondary'}>
                                    Multiuso
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Col>
                    </Row>
                    <Row className="mb-3" hidden={this.state.subEvento != 2}>
                        <Col>
                            <p className="form-label">Dias de Reinicio</p>
                            <MultiDatePicker.default  value={this.state.dias} onChange={this.handleChangeDias}
                                multiple format='DD/MM/YYYY' inputClass='react-datepicker__input-container'
                                sort
                                minDate={new Date(this.state.fecha_seleccionada)}
                                maxDate={new Date(this.state.fecha_limite)}
                                plugins={[<DatePanel />]} 
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <label htmlFor="imagen_ev" className="form-label">Imagen del Evento (Banner)</label>
                            <input className="form-control" type="file" onChange={this.uploadFile} />
                        </Col>
                    </Row>
                    <Row className="mb-3" hidden={this.state.subEvento == 1}>
                        <Col>
                            <label htmlFor="imagen_qr" className="form-label">Imagen de Fondo (QR)</label>
                            <input className="form-control" type="file" onChange={this.uploadFile2} />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <label className="form-label">Pais</label>
                            <Select id="id_paises" options={this.state.paises} placeholder="Buscar Pais" value={this.state.pais_seleccionado} onChange={this.handleChangePais} />
                        </Col>
                    </Row>
                    <Row className="mb-3"  >
                        <Col>
                            <label className="form-label">Ciudad/Estado</label>
                            <Select id="id_ciudad" options={this.state.filtro_ciudades} placeholder="Buscar Ciudad" value={this.state.ciudad_seleccionado} onChange={this.handleChangeCiudad} />
                        </Col>
                    </Row>
                    <button type="submit" className="btn btn-primary text-right">Guardar</button>
                </form>
            </Container>
        );
    }
}
export default EventoForm;