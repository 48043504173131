import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, Button, Card, CardContent, CardHeader, Divider } from '@mui/material';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Conexion from '../../../Conexion/Conexion';

const { apiUrl } = Conexion();

async function obtenerCompras() {
  return fetch(`${apiUrl}/api/dashboard/compras-por-plan`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('token')
      }
  })
      .then(result => result.json())
}

const  months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Augosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const VentasPorPlan = (props) => {

  const [state, setState] = useState([]);


  const cargarDatos = async () => {
    const compras = await obtenerCompras();
    if (compras.ok) {
        let _data = _.map(compras.data, (d, i) =>{
            return {
                mes: months[d.mes-1],
                FREE: d.free,
                STELLAR: d.stellar
            }
        })
      setState(_data);
    }
  }

  useEffect(() => {
    cargarDatos();
  }, []);


  return (
    <Card {...props}>
      <CardHeader
        title="Ventas por Plan"
        subheader={`Últimos 6 meses`}
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: 'relative'
          }}
        >
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
            width={500}
            height={300}
            data={state}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="mes" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="FREE" fill="#8884d8" />
            <Bar dataKey="STELLAR" fill="#82ca9d" />
            </BarChart>
        </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};


export default VentasPorPlan;