import React from "react";
import styled from "styled-components";
import Logo from "../../../assets/images/s002.png";

export default function AppLogo() {
    return (
        <LogoWrapper>
            <img src={Logo} alt="Logo Glup Tickets" width={"200px"}/>
        </LogoWrapper>
    );
  }
  
  const LogoWrapper = styled.div`
    text-align: center;
    margin: 40px;
  `;
