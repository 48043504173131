import React from 'react';
import styled from 'styled-components';
import { Helmet } from "react-helmet-async";
import { useNavigate } from 'react-router-dom';
import TopNavbar from '../TopNavbar/TopNavbar';
import Footer from '../Footer/Footer';
import PanelCard from './PanelCard';
import { ToastContainer } from 'react-toastify';


const customStyles = {
  overlay: {
    zIndex:1040,
  },
};

export default function Inicio(props) {

  const navigate = useNavigate();

  return (
    <>
      <Helmet>
          <title>Mi Panel | Glup Ticket</title>
      </Helmet>
      <TopNavbar cliente={props.cliente} active={"inicio"}/>
      <div className="container px-4 py-5" style={{minHeight:'100vh'}}>
        <h2 className="pb-2 border-bottom font40 extraBold">Glup Ticket</h2>
        <TablesWrapper className="flexSpaceNull">
          <TableBox>
            <PanelCard
              title={"Crear eventos"}
              description={[
                'Crea tu evento en simples pasos.',
              ]}
              icon={1}
              btnText={"Crear Eventos"}
              action={() => navigate('/app/panel/nuevo-evento')}
            />
          </TableBox>
          <TableBox>
            <PanelCard
              title={"Gestionar eventos"}
              description={[
                'Gestiona tus eventos activos y no activos.',
                'Descarga los Tickets de tus eventos.'
              ]}
              icon={2}
              btnText={"Gestionar Eventos"}
              action={() => navigate('/app/panel/eventos')}
            />
          </TableBox>
          <TableBox>
            <PanelCard
              title={"Control de Ingresos"}
              description={[
                'Escanea los ingresos desde cualquier dispositivo.',
                'Crea roles en caso de que alguien mas escaneara tus eventos.',
                'Descarga los reportes de ingreso de tus eventos en tiempo real.',
              ]}
              icon={3}
              btnText={"Controlar Ingresos"}
              action={() => navigate('/app')}
            />
          </TableBox>
        </TablesWrapper>
      </div>
      <ToastContainer position="top-center"
        theme='colored'
        autoClose={2500}
        hideProgressBar={false}
        closeOnClick={false}
        pauseOnHover={true}
        draggable={false}
      />
     <Footer />
    </>
  );
}


const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;


const TableBox = styled.div`
  width: 18%;
  min-width: 340px;
  margin: 0 10px;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;
