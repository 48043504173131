import React, { useEffect, useState } from 'react';
import { Html5Qrcode, Html5QrcodeScanType } from "html5-qrcode";
import { Button, Stack, Box, Divider, Typography } from "@mui/material";
import { CameraFill, StopFill } from 'react-bootstrap-icons';
//Assets
import ScanGif from '../../assets/images/qrscananimation.gif';


const qrcodeRegionId = "html5qr-code-full-region";

function createConfig(props) {
    var config = {};
    if (props.fps) {
    config.fps = props.fps;
    }
    if (props.qrbox) {
    config.qrbox = props.qrbox;
    }
    if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
    }
    if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
    }
    // to scan only by camera method, not by uploading a file
    config.supportedScanTypes = [Html5QrcodeScanType.SCAN_TYPE_CAMERA]
    return config;
}
let config;
let html5QrCode;

export default function Html5QrcodePlugin(props){
    const [state, setstate] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        config = createConfig(props);

        // Suceess callback is required.
        if (!(props.qrCodeSuccessCallback )) {
            throw "qrCodeSuccessCallback is required callback.";
        }

        html5QrCode = new Html5Qrcode(qrcodeRegionId);

        return () => {
            handleStop()
        }
    }, []);

    const handleClickPlay = () => {
        try {
            html5QrCode.start({ facingMode: "environment" }, config, props.qrCodeSuccessCallback)
            .then((res)=>{
                setstate(true)
                setErrorMessage('')
            })
            .catch((err) => {
                console.log(err);
                setErrorMessage(err)
            });
            
        } catch (err){
            console.log(err.message)
        }
        
    };

    const handleStop = () => {
        try {
            html5QrCode
            .stop()
            .then((res) => {
                html5QrCode.clear();
                setstate(false)
            })
            .catch((err) => {
                console.log(err.message);
            });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div id={qrcodeRegionId} />
            {!state && 
            <Stack direction="row" spacing={2} justifyContent="center" sx={{mt:2}} >
                <Box sx={{width:266, border:'1px solid rgba(0, 0, 0, 0.05)', display:'flex', justifyContent:'center'}}>
                <img
                      src={ScanGif}
                      alt={"Scan Gif"} 
                      width="266"
                      height="200"
                    />
                </Box>
            </Stack>
            }
            <Divider sx={{my:1}} />
            <Stack direction="row" spacing={2} justifyContent="center" >
                {
                    Boolean(!state) 
                    ? <Button className="primaryBg" variant="contained" onClick={handleClickPlay} disabled={state} startIcon={<CameraFill />} >Iniciar Cámara</Button>
                    : <Button className="redBg" variant="contained" onClick={handleStop} disabled={!state} startIcon={<StopFill />} >Stop</Button>
                }
            </Stack>
            {errorMessage && <Typography sx={{mt:1}}color={'error'}>Error al acceder a la cámara: Permiso Denegado.</Typography>}
            <Divider sx={{my:1}} />
        </>
        );

}