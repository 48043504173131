const KEYS_CSV = ["nro_invitado", "qr"];

function filtrarKeys(objeto) {
    const keysPermitidas = KEYS_CSV;
    const objetoFiltrado = {};
  
    for (const key in objeto) {
      if (keysPermitidas.includes(key)) {
        objetoFiltrado[key] = objeto[key];
      }
    }
  
    return objetoFiltrado;
  }

function convertArrayOfObjectsToCSV(array) {
	let result;

	const columnDelimiter = ',';
	const lineDelimiter = '\n';
	const orderedKeys = KEYS_CSV;

	result = '';
	result += orderedKeys.join(columnDelimiter);
	result += lineDelimiter;

	array.forEach(item => {
		let ctr = 0;
		orderedKeys.forEach(key => {
			if (ctr > 0) result += columnDelimiter;

			result += item[key];
			
			ctr++;
		});
		result += lineDelimiter;
	});

	return result;
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
export function downloadCSV(array, nombreEvento) {
	const link = document.createElement('a');
    // let filteredArray = array
    const arrayFiltrado = array.map(objeto => filtrarKeys(objeto));
	let csv = convertArrayOfObjectsToCSV(arrayFiltrado);
	if (csv == null) return;

	if (!csv.match(/^data:text\/csv/i)) {
		csv = `data:text/csv;charset=utf-8,${csv}`;
	}

	link.setAttribute('href', encodeURI(csv));
	link.setAttribute('download', nombreEvento);
	link.click();
}

