import React, { useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { Link } from 'react-router-dom';
import ClienteNuevo from './ClienteNuevo';
import DataTable from 'react-data-table-component';
import Conexion from '../../Conexion/Conexion';
//import ClienteEditar from './ClienteEditar';
import { Button } from 'react-bootstrap';
const { apiUrl } = Conexion();

async function cargarClientes() {
  return fetch(`${apiUrl}/api/usuarios/clientes`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('token')
    }
  })
    .then(r => {
      if (r.status > 399) {
        if (r.status === 401) {
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
        }
        console.log("Error", "Could not reach server");
      }
      return r.json()
    })
}

const ExpandedComponent = (props) => {

  return (
    <> 
      <div className='container-fluid'>
          <Link to={`/admin/clientes/${props.data.id}/eventos`} className="btn btn-info">Ver Eventos</Link>
          <Link to={`/admin/clientes/${props.data.id}/roles`} className="btn btn-warning">Administrar Roles</Link>
          <button className="btn btn-danger">Dar de baja</button>
      </div>
    </>
  );
};

const inputStyle = {
  height: 32,
  width: 200,
  borderRadius: 3,
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  border: '1px solid #e5e5e5',
  padding: '0 32 0 16'
}
const buttonStyle = {
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  height: 34,
  width: 32,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <input
      id="search"
      type="text"
      placeholder="Buscar"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
      style={inputStyle}
    />
    <Button type="button" onClick={onClear} style={buttonStyle}>
      X
    </Button>
  </>
);

const DataCliente = (props) => {

  const actionsMemo = React.useMemo(() => <ClienteNuevo />, []);

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = props.data.filter(
    item => item.nombre + ' ' + item.apellido_p + ' ' +  item.apellido_m && (item.nombre + ' ' + item.apellido_p + ' ' +  item.apellido_m + ' ' + item.telefono + item.correo).toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);

  return <DataTable
    columns={columns}
    data={filteredItems}//props.data
    title="Clientes"
    expandableRows
    actions={actionsMemo}
    expandOnRowClicked
    expandableRowsComponent={ExpandedComponent}
    expandableRowsComponentProps={{}}
    pagination

    subHeader
    subHeaderComponent={subHeaderComponentMemo}
    persistTableHead />;
};

const columns = [{
  name: 'Nombre',
  selector: row => row.nombre + ' ' + row.apellido_p + ' ' + row.apellido_m
},
{
  name: 'Correo',
  selector: row => row.correo
},
{
  name: 'Contraseña',
  selector: row => row.password
},
{
  name: 'Telefono',
  selector: row => row.telefono,
  omit:true
},
{
  name: 'Estado',
  selector: row => {
    switch (row.estado) {
      case 1:
        return "Activo"
      case 2:
        return "Inactivo"
      default:
        return ""
    }
  },
  sortable: true
}];


const Clientes = () => {
  const [state, setState] = useState({
    data: [],
    fetched: false
  })
  // const [editform, setEditform] = useState({
  //   open: false,
  //   eventData: null
  // });

  const cargarDatos = async () => {
    const clientes = await cargarClientes();
    if (clientes) setState({ data: clientes, fetched: true });
  }

  // const componentDidMount=async ()=>{
  //   const clientes= await cargarClientes();
  //   console.log(clientes);
  //   this.setState({data: clientes})
  // }

  // const openEditModal = (evento) => {
  //   setEditform({
  //     open: true,
  //     eventData: evento,
  //   })
  // }

  // const changeEditModal = (evento) => {
  //   setEditform({
  //     open: true,
  //     eventData: evento
  //   })
  // }

  // const submitEditModal = (evento) => {
  //   setState({
  //     data: [],
  //     fetched: false
  //   })
  // }

//   const closeEditModal = () => {
//     setEditform({
//       open: false,
//       eventData: null
//     })
//   }


if(!state.fetched){
  cargarDatos();
}
  return ( <>
      <Sidebar clientes = 'active' />
      <div className='container overflow-auto' style={{height:"100vh",background:"#c1dee6"}}>
          <DataCliente data={state.data} />
      </div>
      {/* <ClienteEditar open={editform.open} data={editform.eventData} close={closeEditModal} change={changeEditModal} submit={submitEditModal}/> */}
    </>
  );
}
export default Clientes;