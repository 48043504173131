import React from "react";
import styled from 'styled-components';


export default function VideoPromo() {

  return (
    <Wrapper id="video" className="container flexSpaceCenter">
        <ImageWrapper>
        <Iframe src="https://www.youtube.com/embed/2OK3wOBTaSE?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></Iframe>
        </ImageWrapper>
    </Wrapper>
  );
}

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
`;

const Wrapper = styled.section`
  width: 100%;
  min-height: auto;
  margin-bottom: 50px;
`;
const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

