import React from "react";
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { Icon1SquareFill, Icon2SquareFill, Icon3SquareFill, Icon4SquareFill, Icon5SquareFill, Icon6SquareFill, Icon7SquareFill } from 'react-bootstrap-icons';



export default function Features() {


  return (
    <Wrapper id="features" className="container flexSpaceCenter">
      <LeftSide className="whiteBg flexCenter">
       <Fade left>
          <div>
            <h1 className="font40 extraBold pb-5">Ventajas</h1>
            <div class="container">
              <div class="row row-cols-1 row-cols-md-2 g-4">
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                     <Icon1SquareFill color="dodgerblue" size={26} /> Mayor seguridad y reducción de fraudes.
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                    <Icon2SquareFill color="dodgerblue" size={26} /> Ahorro de tiempo y mayor eficiencia.
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                    <Icon3SquareFill color="dodgerblue" size={26} /> Información en tiempo real.
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                    <Icon4SquareFill color="dodgerblue" size={26} />  Experiencia del usuario mejorada.
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                    <Icon5SquareFill color="dodgerblue" size={26} />  Personalización y accesibilidad
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                    <Icon6SquareFill color="dodgerblue" size={26} /> Reducción de costos
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                    <Icon7SquareFill color="dodgerblue" size={26} /> Flexibilidad para adaptarse a cualquier evento.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </LeftSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  width: 100%;
  min-height: 640px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

