import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {Spinner} from 'react-bootstrap'
import {DataGrid} from '@mui/x-data-grid';
import { Helmet } from "react-helmet-async";
import _ from 'lodash';
import QRCode from 'qrcode';
import { LinkButton, EventLinkButton, AppButton } from '../components/AppButton';
import AppLogo from '../components/AppLogo';
import {ChevronLeft, FileEarmarkArrowDown, Download} from 'react-bootstrap-icons';
import Conexion from "../../Conexion/Conexion";
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import styled from "styled-components";
import { saveAs } from 'file-saver'; 
import useToken from '../../useToken/useToken';
import { pdf, Page, View, Document, StyleSheet, Image, Text, Font } from '@react-pdf/renderer';
import { toast, ToastContainer } from 'react-toastify';

const {apiUrl} = Conexion();
var zip = require('jszip')();

async function cargarInvitados(eventoId) {
  return fetch(`${apiUrl}/api/invitados/evento/generado/${eventoId}`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('token')
      }
  }).then(r =>{
      if (r.status > 399) {
        if(r.status === 401){
            alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
            sessionStorage.clear();
            window.location.replace(window.location.origin);
            console.log(r.status)
        }
        console.log("Error","Could not reach server",r);
      }
      return r.json()
    })
}
async function GenerarInvitadoFake(eventoId,datos) {
  return fetch(`${apiUrl}/api/invitados/evento/generar/${eventoId}`, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('token')
      },
      body: JSON.stringify(datos)
  }).then(r =>{
      if (r.status > 399) {
        if(r.status === 401){
            alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
            sessionStorage.clear();
            window.location.replace(window.location.origin);
            console.log(r.status)
        }
        if(r.status === 403){
          window.location.replace(window.location.origin+"/app");
        }
        console.log("Error","Could not reach server",r);
      }
      return r.json()
    })
}

async function datosEvento(eventoId) {
  return fetch(`${apiUrl}/api/eventos/${eventoId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('token')
    }
  }).then(r =>{
    if (r.status > 399) {
      if(r.status === 401){
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
      }
      if(r.status === 403){
        window.location.replace(window.location.origin+"/app");
      }
      console.log("Error","Could not reach server");
    }
    return r.json()
  })
    
}



const OpcionesDeGenerarTicket1 = (props) => {
  const { eventoId } = useParams();
  const { user } = useToken();
  const [state, setState] = useState({
    datos:[],
    evento:[],
    fecha_evento: '',
    cant_max:0,
    cant_actual:0,
    isFetched:false,
  });
  const [descargando,setDescargando]=useState(false);

  const cargarDatos = async () => {
    let invitados = await cargarInvitados(eventoId);
    
    if (invitados) {
        invitados.datos.forEach(async element => {
            if (element["qr"] !== "") {
                const qr = await QRCode.toDataURL(element["qr"]);
                element["qr"] = qr;
            };
        })
        
        let dEvento = await datosEvento(eventoId);
        if(dEvento){
          setState({
            datos: user.rol!==1?_.filter(invitados.datos,function(d){return d.usuario===props.cliente.correo}):invitados.datos,
            evento:dEvento,
            fecha_evento: formatInTimeZone(dEvento.fecha_evento,'UTC', "dd/MM/yyyy", {
              awareOfUnicodeTokens: true
            }),
            cant_max:invitados.cantidad_max,
            cant_actual:invitados.cantidad_actual,
            isFetched:true});
        }
        
    }
  };
    
  if(state.isFetched === false){
    cargarDatos();
  };




  function BlobPDFZip(i){
      pdf(Doc(i, state.evento.fondo_qr, state.evento.nombre, state.fecha_evento)).toBlob().then(res=>{
        saveAs(res,state.evento.nombre + " " + (i.nro_invitado) + ".pdf");
      });
  }

  const BlobPDFZipTotal = () => {
    //var zip=new JSZip();
    setDescargando(true)
    var i = 0;
    for (i; i < views.length; i++) {
      var blobPDF = pdf(views[i]).toBlob();
      zip.folder(state.evento.nombre).file(state.evento.nombre + " " + (i + 1) + ".pdf", blobPDF);
    }

    // once you finish adding all the pdf to the zip, return the zip file
    //const blob = await zip.generateAsync({ type: "blob" });
    zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, state.evento.nombre + ".zip");
        zip.remove(state.evento.nombre);
        
        setDescargando(false)
    })
    //saveAs(blob, state.dEvento.nombre+".zip");

  }

  const handleClickDownload =  React.useCallback(
    (id) => () => {
      let invitado = _.find(state.datos, ['id', id])
      if(invitado) BlobPDFZip(invitado);
      else console.log("Error")
    },
    [state],
  );

  const handleButtonGenerar=(e)=>{
    e.preventDefault();
    if(state.evento.subevento == 3){
      toast.error("Los invitados de este eventos están siendo gestionados por el Administrador");
    }else{
      handleGenerar();
    }
  }

  const handleGenerar= async ()=>{
    const datos={
      user:props.cliente.id,
    }
    let ticket=  await GenerarInvitadoFake(eventoId,datos);
    if(ticket.ok){
      toast.success(ticket.mensaje, {
        theme: "colored",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        });
    }else{
      toast.warn(ticket.mensaje, {
        theme: "colored",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        });
    }
    setState({datos: state.datos,evento:state.evento,isFetched:false});
  }

  const views = state.datos.map((d, i) => {
  if(state.isFetched){
    return (<Document>
          <Page key={i} size={[720]} style={styles.page}>
            <View style={styles.section} >
                <Image
                    src={`${apiUrl}/api/eventos/imagen/${state.evento.fondo_qr}`}
                    style={styles.fondo_img}
                />
            </View>
            <View style={styles.section3}>
                <Text style={styles.text_page_number}>{d.nro_invitado}</Text>
            </View>
            { state.evento.fondo_qr === "PlantillaFondo1.png" &&
              <View style={styles.sectionTitle}>
                <Text style={styles.event_title}>{state.evento.nombre}</Text>
                <Text style={styles.event_date}>{state.fecha_evento}</Text>
              </View>
            }
            <View style={styles.section2}>
                <Image
                    style={styles.qr_img}
                    src={d.qr}
                />
            </View>
          </Page>
        </Document>
      )
  }
  else{
    return ("")
  }
    
  });


  const columns = React.useMemo(
    () => [
    {
      headerName: 'Ticket',
      field: 'nombre',
      //sortable: true,
      width: 100,
    },
    {
      headerName: 'Generado por',
      field: 'usuario',
      //sortable: true,
      width: 200,
    },
    {
      headerName: 'Usos',
      field: 'usos',
      //sortable: true,
      width: 50,
      hide: state.evento.subevento != 3,
    },
    {
      field: 'id',
      type: 'actions',
      width: 50,
      renderCell: (params) => {
        return <ButtonDescarga  
          className='radius8 secondaryBg2'
          //onClick={deleteUser(params.id)
          onClick={handleClickDownload(params.row.id)} 
          id={params.row.id}
        >
          <Download/>
        </ButtonDescarga>
      }
      // cell:(row) => <ButtonDescarga style={{background: `url(${icono})`, backgroundSize: "cover"}} onClick={clickHandler} id={row.id}></ButtonDescarga>,
      // ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
      // maxWidth: '50px',
    },

  ],[handleClickDownload]);

  const DataEventos = (props) => {

    return <div className='overflow-auto' style={{height:"500px", width:"100%", padding:"10px"}}>
      <DataGrid
        columns={columns}
        rows={props.data}//props.data
        pageSize={30}
        rowsPerPageOptions={[30,50,100]}
        rowHeight={30}
      />
    </div>
  };
  return (
    <div className='container-sm'>
      <Helmet>
          <title>Descargar Tickets - Glup Ticket</title>
      </Helmet>
      <div className='text-center'>
        <AppLogo />
        <div className="d-flex flex-column align-items-center mb-4">
            { state.isFetched && <EventLinkButton evento={state.evento} fecha={''} apiUrl={apiUrl} /> }
        </div>
        <div>
          <h4 className='extraBold'>Tickets Generados: {state.cant_actual}/{state.cant_max}</h4>
          <DataEventos data={state.datos} />
        </div>
        <div className="d-flex flex-column align-items-center mt-2">
        {
        (state.cant_actual===state.cant_max && state.cant_actual!==0 && state.isFetched==true) || state.datos.length >= state.evento.max_tickets 
          ?user.rol==1 
            ? <AppButton action={BlobPDFZipTotal} disabled={descargando} title=
              {descargando == true 
              ? <Spinner animation="border" />
              : <><FileEarmarkArrowDown size={24}/>&nbsp; Descargar Todo</>} />
            :<p>Tickets Agotados o Alcanzó su límite</p>
          : (state.evento.subevento != 3)
            ? <AppButton action={handleButtonGenerar} title={'Generar 1 ticket'} />
            : ''}
          
          <LinkButton  border className='my-4' to={`/app/evento/${eventoId}`} title={<><ChevronLeft size={24}/>&nbsp; Volver</>}/>
        </div>
        <ToastContainer />
        </div>
    </div>
  );
};

export default OpcionesDeGenerarTicket1;


Font.register({ family: 'Gotham', src: 'https://fonts.cdnfonts.com/s/14807/Gotham Bold.woff' });
const styles = StyleSheet.create({
  page: { margin: 0 },
  section: { backgroundColor: '#1b1227', margin: 0, position: 'fixed', top: '0px', },
  section2: { backgroundColor: '#5b5257', margin: 0, position: 'absolute', top: '760px', left: '239px', },
  section3: { backgroundColor: '#5b5257', margin: 0, position: 'relative', top: '0px', },
  sectionTitle: { backgroundColor: 'transparent', width: '90%', margin:'0 auto' , position: 'relative', display: 'flex', justifyContent: 'center', alignItems:'center' },
  fondo_img: { width: '720px', height: '1280px' },
  qr_img: { width: '240px' },
  text_page_number: { fontSize: '40px', width: '50px', left: '15px', color: 'black', fontStyle: 'bold', paddingVertical: '6px', position: 'absolute', bottom: '1230px', backgroundColor: 'rgba(18,28,38,0)', },
  event_title: { fontSize: '60px', width:'100%', color: 'white', paddingVertical: '6px', position: 'absolute', bottom: '906px', textAlign: 'center', fontFamily: 'Gotham' },
  event_date: { fontSize: '35px', width:'100%', color: 'white', paddingVertical: '6px', position: 'absolute', bottom: '160px', textAlign: 'center', fontFamily: 'Gotham' }
});


const Doc =(data, fondo, titulo, fecha_evento) => {
  return(
        <Document>
            <Page size={[720]} style={styles.page}>                     
                <View style={styles.section} >          
                    <Image
                            src={`${apiUrl}/api/eventos/imagen/${fondo}`}
                            style={styles.fondo_img}
                        />
                </View>
                <View style={styles.section3}>
                  <Text style={styles.text_page_number}>{data.nro_invitado}</Text>
                </View>
                { fondo === "PlantillaFondo1.png" &&
                  <View style={styles.sectionTitle}>
                    <Text style={styles.event_title}>{titulo}</Text>
                    <Text style={styles.event_date}>{fecha_evento}</Text>
                  </View>
                }
                <View style={styles.section2}> 
                  <Image
                      style={styles.qr_img}
                      src={data.qr}
                  />
              </View>
            </Page>
        </Document>
  )
};



const ButtonDescarga = styled.button`
  position: relative;
  width: 30px;
  height: 30px;
  background-color: #dcfdd4;
  border-radius: 1rem;
  border: 1px solid  #B1F873;
`;
const ButtonGenerar = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    border:none;
    position: relative;
    width: 322px;
    height: 58px;
    

    background: #B1F873;
    /* 3d button */

    box-shadow: inset 2px 2px 3px rgba(255, 255, 255, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 32px;
`;
const style = {
  'display': 'flex',
  'flexDirection': 'row',
  'justifyContent': 'center',
  'alignItems': 'center',
  'padding': '8px',
  'gap': '10px',

  'position': 'relative',
  'width': '322px',
  'height': '58px',
  'textDecoration':'none',
  'color':'#ffffff',
  

  'background': '#2CBCE4',
  /* 3d button */

  'boxShadow': 'inset 2px 2px 3px rgba(255, 255, 255, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0.2)',
  'borderRadius': '32px',
};

