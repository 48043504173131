import React, {useState } from 'react';
import {Link} from 'react-router-dom';
import Logo from "../../../assets/images/glup-tickets-logo.png";
import { FileEarmarkArrowDown, ChevronLeft } from 'react-bootstrap-icons';
import ImagenIpsum from "../../../assets/images/Banner.jpeg";
import ElPDF from "../Menu/EventoDemo.pdf";


function ListaEventos() {
  const [state, setState] = useState({
    eventos: require('./eventos.json'),
    images: ["../../../assets/images/Cualquier-Ipsum.png","../../../assets/images/Cualquier-Ipsum.png","../../../assets/images/Cualquier-Ipsum.png"]
  });


  if(state.eventos.length === 0){
    //cargarDatosEventos();
    console.log(state.eventos);
  }

  const Lista = () =>{
    let eventos = state.eventos;
    //var images = state.images;
    var fecha = new Date();
    var lista = eventos.map((evento, i) => (<Link to={`/demo/evento/${evento.id}`} key={'div-evento'+i} className='demo-evento-link'>
      <img className='demo-evento-imagen' key={'img'+i} src={ImagenIpsum} width="300" height="100" alt="" />
      <div style={{width:'300px' ,margin:'auto', textAlign:'left'}}>
        <p className='demo-evento-nombre' key={'evento-nombre'+i}>{evento.nombre}</p>
        <p className='demo-evento-fecha' key={'evento-fecha'+i}>{(fecha.toLocaleDateString())}</p>
      </div>
    </Link>));

    return (
        <div>
          {lista}
        </div>
    )
  }


  return (
    <div className='app-wrapper wrapper'>
      <div className='demo-top-left-circle'></div>
      <div className='demo-middle-rigth-circle'></div>
      <div style={{textAlign:"center"}} >
          <img src={Logo} alt="Logo Glup QR" width="200px" />
      </div>
      <div>
        <h1 className='demo-title'>Bienvenido</h1>
        <p className='demo-description'>Descargue la invitación e ingrese al evento demo </p>
        <br/>
        <Lista></Lista>
        <div className="d-grid gap-3 col mx-auto mt-5 px-4" >
          <a className='demo-button' href={ElPDF} download><FileEarmarkArrowDown size={20} />&nbsp; Descargar Invitacion  </a>
          <Link to={`/`} className='demo-button'>
          <ChevronLeft  size={20} /> &nbsp; <span>Volver</span> 
              </Link>
        </div>
      </div>
      
    </div>
  );
}

export default ListaEventos;