import React from 'react';
import { Spinner } from 'react-bootstrap';
import Conexion from '../../Conexion/Conexion';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
const {apiUrl} = Conexion();

async function editarContraseña(datos) {
    return fetch(`${apiUrl}/api/usuarios/admin-password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
      },
      body: JSON.stringify(datos)
    })
      .then(r =>{
        if (r.status > 399) {
          if(r.status === 401){
              alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
              sessionStorage.clear();
              window.location.replace(window.location.origin);
              console.log(r.status)
          }
          console.log("Error","Could not reach server");
        }
        return r.json()
    })
  }

class CambiarContrasenaForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contraseñaActual: '',
            contraseñaNueva: '',
            contraseñaConfirmacion: '',
            guardando: false,
            passwordType: "password"
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.togglePassword = this.togglePassword.bind(this);
    }
    handleChange(e) {    
        if (e.target.id === 'contraseñaActual') {
            this.setState({ contraseñaActual: e.target.value.trim() });
        }else if (e.target.id === 'contraseñaNueva') {
            this.setState({ contraseñaNueva: e.target.value.trim() });
        } else if (e.target.id === 'contraseñaConfirmacion') {
            this.setState({ contraseñaConfirmacion: e.target.value.trim() });
        }
     }

     togglePassword (e) {
        e.preventDefault();
        if(this.state.passwordType==="password")
        {
            this.setState({passwordType: "text"});
            return;
        }
        this.setState({passwordType: "password"});
      }

    async handleSubmit(e) {
        e.preventDefault();
        this.state.guardando=true;
        if(this.state.contraseñaNueva === this.state.contraseñaConfirmacion){
            const CambiarContraseña = await editarContraseña(this.state);
            if(CambiarContraseña.ok){
              alert(CambiarContraseña.mensaje);
            } 
            else {alert(CambiarContraseña.mensaje);}
        }else{
            alert('Confirme su contraseña correctamente');
        } 
        this.state.guardando=false;
    }

    render() {
        return (
            <div className="p-5" style={{background:"white"}}>
                <h2 className="text-center">Cambiar Contraseña</h2>
                <form className="col-lg-8 mx-auto" onSubmit={this.handleSubmit}>
                    <div className="mb-3 col-sm-8">
                        <label htmlFor="contraseñaActual" className="form-label">Contraseña Actual</label>
                        <input type="password" className="form-control" id="contraseñaActual" value={this.state.password} onChange={this.handleChange}/>
                    </div>
                    <div className="mb-3 col-sm-8">
                        <label htmlFor="contraseñaNueva" className="form-label">Nueva Contraseña</label>
                        <input type={this.state.passwordType} className="form-control" id="contraseñaNueva" value={this.state.password} onChange={this.handleChange}/>
                        <button className="btn" onClick={this.togglePassword}>
                            { this.state.passwordType === "password"? <EyeFill ></EyeFill> :<EyeSlashFill ></EyeSlashFill> }
                        </button>
                    </div>
                    <div className="mb-3 col-sm-8">
                        <label htmlFor="contraseñaConfirmacion" className="form-label">Confirmar Nueva Contraseña</label>
                        <input type="password" className="form-control" id="contraseñaConfirmacion" value={this.state.confpass} onChange={this.handleChange}/>
                    </div>
                    <button type="submit" className="btn btn-primary" disabled={this.state.guardando}>{this.state.guardando?<Spinner animation="border" />: 'Guardar'}</button>
                </form>
            </div>
        );
    }
}

export default CambiarContrasenaForm;