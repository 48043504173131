import {useEffect, useState} from 'react';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import {DataGrid} from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { PersonXFill, CheckCircleFill, DashCircleFill, InfoCircleFill } from 'react-bootstrap-icons';
import Conexion from '../../Conexion/Conexion';
import Sidebar from '../Sidebar/Sidebar';

const { apiUrl } = Conexion();

async function cargarEventos() {
    return fetch(`${apiUrl}/api/eventos/dashboard`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    })
        .then(result => result.json())
}

const columns = [
  { field: 'id', headerName: 'ID', width: 60 },
  {
    field: 'nombre',
    headerName: 'Nombre',
    width: 250,
  },
  {
    field: 'cliente',
    headerName: 'Cliente',
    width: 200,
  },
  {
    field: 'fecha_evento',
    headerName: 'Fecha Inicio',
    type: 'date',
    width: 130,
    valueGetter: (params) => {
      return new Date(formatInTimeZone(params.value, 'UTC', 'MM/dd/yyyy'));
    },
    valueFormatter: (params) => {
      return formatInTimeZone(params.value, 'UTC', 'dd/MM/yyyy');
    }
  },
  {
    field: 'pais',
    headerName: 'Pais',
    width: 150,
    valueGetter: (params) => {
      return params.value ? params.value : '';
    }
  },
  {
    field: 'ciudad',
    headerName: 'Ciudad',
    width: 150,
    valueGetter: (params) => {
      return params.value ? params.value : '';
    }
  },
  {
    field: 'subevento',
    headerName: 'Tipo',
    width: 150,
    renderCell: (params) => {
      switch (params.value) {
        case 0:
            if(params.row.id_evento == 0){
              return <Chip label={"Cerrado"} />
            }else{
              return <Chip color='warning' label={"SubEvento"} />
            }
            
        case 1:
            return <Chip color={'error'} label={"Evento Padre"} />
        case 2:
            return <Chip color={'secondary'} label={"Membresia"} />
        case 3:
            return <Chip color={'info'} label={"Multiuso"} />
        default:
            return <Chip label={""} />
      }
    }
  },
  {
    field: 'estado',
    headerName: 'Estado',
    width: 150,
    renderCell: (params) => {
      switch (params.value) {
        case 1:
            return <Chip icon={<PersonXFill />} color={'error'} label={"Sin Invitados"} />
        case 2:
            return <Chip icon={<DashCircleFill />} color={'warning'} label={"Pendiente"} />
        case 3:
            return <Chip icon={<InfoCircleFill />} color={'success'} label={"Listo"} />
        case 4:
            return <Chip icon={<CheckCircleFill />} color={'primary'} label={"Terminado"} />
        default:
            return <Chip label={""} />
      }
    }
  },
  {
    field: 'invitados',
    headerName: '# Invitados',
    type: 'number',
    width: 85,
    
  },
  {
    field: 'checked',
    headerName: '# Ingresos',
    type: 'number',
    width: 85,
  },
  {
    field: 'usos',
    headerName: '% Uso',
    width: 100,
    renderCell: (params) => {
      let valor = params.row.invitados != 0 ? (params.row.checked*100/params.row.invitados).toFixed(2) : 0;
      return <CircularProgressWithLabel value={Number(valor)} />
    }
  },
  {
    field: 'fecha_limite',
    headerName: 'Fecha Fin',
    type: 'date',
    width: 130,
    valueGetter: (params) => {
      return new Date(formatInTimeZone(params.value, 'UTC', 'MM/dd/yyyy'));
    },
    valueFormatter: (params) => {
      return formatInTimeZone(params.value, 'UTC', 'dd/MM/yyyy');
    }
  },
  {
    field: 'fecha_creacion',
    headerName: 'Fecha Creacion',
    type: 'timestamp',
    width: 180,
    valueGetter: (params) => {
      return new Date(params.value);
    },
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleString();
    }
  },
  {
    field: 'id_administrador',
    headerName: 'Creado por',
    width: 150,
    valueGetter: (params) => {

      return params.value !== params.row.id_cliente ? 'Administrador' : params.row.cliente;
    }
  },
];

export default function TablaEventos() {
  const [state, setState] = useState({
        data: [],
        fetched: false
  });

  const cargarDatos = async () => {
    const eventos = await cargarEventos();
    if (eventos) setState({ data: eventos, fetched: true });
  }

  useEffect(() => {
    cargarDatos();
  }, []);


  return(
    <>
    <Sidebar tabla_eventos='active'/>
    <div className='overflow-auto' style={{height:"100vh", width:"100%", padding:"10px"}}>
          <DataGrid 
            rows={state.data}
            columns={columns}
            
          />
      </div>
    </>
  );
}







function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" color='secondary' {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}