import React,{useState} from 'react';
import DataTable from 'react-data-table-component';
import {Link, useParams} from 'react-router-dom';
import { ChevronLeft } from 'react-bootstrap-icons';
import Logo from "../../../assets/images/glup-tickets-logo.png";

function cargarInvitadosDemo(eventoId){
    let invitados=require('./Ingresos.json');
    return invitados;
}


export default function Ingresos(){

    const {eventoId} = useParams();
    const [datos, setDatos] = useState([]);
    const [cantidad, setCantidad] = useState({
        total: 0,
        checked: 0
    });

    const tableStyles = {
        header: {
            style: {
                backgroundColor: '#222121',
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: 16+'px',
                lineHeight: 24+'px',
                color: '#FFFFFF',
            },
        },
        rows: {
            style: { 
                minHeight: '24px',// override the row height
                color:'#FFFFFF',
                border: 'none !important'
            },
        },
        headCells: {
            style: {
                backgroundColor: '#151e23',
                color:'#b1f873',
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderBottomColor: '#b1f873',
            },
        },
        cells: {
            
        },
        pagination: {
            style: {
                color: '#b1f873',
                fontSize: '13px',
                minHeight: '56px',
                backgroundColor: '#222121',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderTopColor: '#b1f873',
            },
            pageButtonsStyle: {
                borderRadius: '50%',
                height: '40px',
                width: '40px',
                padding: '8px',
                margin: 'px',
                cursor: 'pointer',
                transition: '0.4s',
                color: '#b1f873',
                fill: '#b1f873',
                backgroundColor: 'transparent',
                '&:disabled': {
                    cursor: 'unset',
                    color: '#84a368',
                    fill: '#84a368',
                },
                '&:hover:not(:disabled)': {
                    backgroundColor: '#7de423',
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: '#7de423',
                },
            },
        },
    };

    const conditionalRowStyles = [
        {
            when: row => row,
            style: row => ({
                backgroundColor: row.nro_invitado % 2 == 0 ? '#252f38' : '#303b44',
            }),
        },
      ];

    const cargarDatos = async () => {
        const invitados = cargarInvitadosDemo(eventoId);
        if(invitados){
            var checked= 0;
            invitados.forEach(element => {
                if(element.hora_ingreso!=null){
                //var date = new Date(element.hora_ingreso);
                //element.hora_ingreso = date.toLocaleTimeString()
                checked++;
            }
                
            });
            setCantidad({
                total: invitados.length,
                checked: checked
            })
            setDatos(invitados);
        }
    }

    if(datos.length === 0){
        cargarDatos();
    }

    const columns = [
        {
            name: '#',
            width:70+'px',
            selector: row => row.nro_invitado,
            sortable: true
        },{
            name: 'Nombre',
            selector: row => row.nombre,
            
            sortable: true
        },{
            name: 'Hora de Ingreso',
            width:'25%',
            selector: row => row.hora_ingreso? row.hora_ingreso : "--:--:--",
            sortable: true,
        },{
            name: 'Estado',
            width:'30%',
            selector: row => row.estado>1?"CHECK":"NO CHECK",
            sortable: true,
            conditionalCellStyles: [
                {
                    when: row => row.estado >1,
                    style: {
                        color:'#1c1c1c',
                        backgroundColor: '#b1f873',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.estado <= 1,
                    style: {
                        color:'#1c1c1c',
                        backgroundColor: '#d56e6e',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                }
            ]
        }
    ];

    
function convertArrayOfObjectsToCSV(array) {
	let result;
	const columnDelimiter = ',';
	const lineDelimiter = '\n';
	const keys = ['nro_invitado','nombre','hora_ingreso','estado']
    const validkeys = ['nro_invitado','nombre','hora_ingreso','estado']
	result = '';
	result += keys.join(columnDelimiter);
	result += lineDelimiter;
	array.forEach(item => {
		let ctr = 0;
		keys.forEach(key => {
            if(validkeys.includes(key)){
                if (ctr > 0) result += columnDelimiter;
                if(key === 'estado'){
                    if(item[key] === 2){
                        result += 'CHECKED';    
                    }else{
                        result += 'NO CHECKED';
                    }
                }else{
                    item[key] !== null ? result += item[key] : result += ' ';
                    
                }
			    
			    ctr++;
            }
			
		});
		result += lineDelimiter;
	});
	return result;
}

function downloadCSV(array) {
	const link = document.createElement('a');
	let csv = convertArrayOfObjectsToCSV(array);
	if (csv == null) return;
	const filename = 'Ingresos_GlupQR.csv';
	if (!csv.match(/^data:text\/csv/i)) {
		csv = `data:text/csv;charset=utf-8,${csv}`;
	}
	link.setAttribute('href', encodeURI(csv));
	link.setAttribute('download', filename);
	link.click();

}

    const Export = ({ onExport }) => {
        return <button className='demo-button' style={{width: '100%',height: 40+'px',borderRadius:30+'px',overflow: 'hidden',background: '#B1F873', color:'#1c1c1c'}} onClick={e => onExport(e.target.value)}>EXPORTAR</button>
    }

    return (
      <div className='wrapper'>
        <div className=''>
            <div className='demo-top-left-circle'>
            </div>
            <div className='demo-bottom-right-circle'>
            </div>
            <div style={{textAlign:"center"}} >
            <img src={Logo} alt="Logo Glup Tickets" width={"200px"}/>
            </div>
      
        </div>
        <div className='container overflow-auto'>
            <DataTable
            customStyles={tableStyles}
            columns={columns}
            data={datos}
            title={"INGRESOS "+cantidad.checked+"/"+cantidad.total}
            actions={<div><Export onExport={() => downloadCSV(datos)} /></div>}
            pagination
            conditionalRowStyles={conditionalRowStyles}
             />

            <div className='group-btns text-center mx-auto '> 
                <Link className='demo-button' to={`/demo/evento/${eventoId}`}><ChevronLeft size={20}/> &nbsp; Atras</Link>
            </div>
        </div>
        
        
      </div>
    )
}