import React from "react";
import styled from "styled-components";
import { Instagram,Facebook,Whatsapp } from 'react-bootstrap-icons';
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../../assets/images/s001.png";

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="primaryBg">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            <Link
              className="flexCenter animate pointer"
              to="home"
              smooth={true}
              offset={-80}
            >
              <img
                className="radius8"
                src={LogoImg}
                alt="GlupTickets Logo"
                style={{ zIndex: 9, width: "136px", height: "40px" }}
              />
            </Link>
            <div>
              
              <StyleP className="whiteColor font13">
                © {getCurrentYear()} -{" "}
                <span className="font13">GlupTickets</span> Todos los derechos
              </StyleP>
            </div>
            <div>
                <ul className="justify-content-center pl-0 list-unstyled d-flex">
                  <li className="ms-3">
                    <StyleA
                      href="https://wa.me/59175019839"
                      target="_blank"
                    >
                      <Whatsapp size={30} />
                    </StyleA>
                  </li>
                  <li className="ms-3">
                    <StyleA
                      href="https://www.instagram.com/glup.bo"
                      target="_blank"
                    >
                      <Instagram size={30} />
                    </StyleA>
                  </li>
                  <li className="ms-3">
                    <StyleA
                      href="https://www.facebook.com/Glup.bo"
                      target="_blank"
                    >
                      <Facebook size={30} />
                    </StyleA>
                  </li>
                </ul>
              </div>
            {/* <Link
              className="whiteColor animate pointer font13"
              to="home"
              smooth={true}
              offset={-80}
            >
              Volver arriba
            </Link> */}
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  margin: 0;
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;

const StyleA = styled.a`
  color: white;
`;