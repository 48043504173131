import React,{useState} from 'react';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import Conexion from '../../Conexion/Conexion';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar';
const {apiUrl} = Conexion();

async function actualizarEstadoEvento(datos,id) {
  return fetch(`${apiUrl}/api/eventos/estado/`+id, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('token')
      },
      body: JSON.stringify(datos)
  }).then(r =>{
    if (r.status > 399) {
      if(r.status === 401){
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
      }
      console.log("Error","Could not reach server");
    }
    return r.json()
  })
}

async function crearInvitadoAlter(datos) {
    return fetch(`${apiUrl}/api/invitados/invitados`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        },
        body: JSON.stringify(datos)
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
}

function GenerarInvitado(props) {
    const [data, setData] = useState({
        nro_invitado:1
    });
    const [loading, setLoading] = useState({
      isloading:false,
      progress:0
    });

   
    const handleCantidad = e =>{
        setData({ nro_invitado: e.target.value });
    }

   async function handleSubmit(e){
        e.preventDefault();
        setLoading({
          isloading:true,
          progress:0  
        })
        const datos = {
            nro_invitado: data.nro_invitado,
            id_evento: props.IdEvento
            }
            const invitado = await crearInvitadoAlter(datos);
            if(invitado) {
                const dato={estado: (props.TipoEvento == 3 ? 3 : 2)}
                const estado = await actualizarEstadoEvento(dato,props.IdEvento);
                if (estado){
                    alert("Datos guardados correctamente");
                    window.location.reload(false);
                }else{
                    alert("Error");
                }
            }else{
                alert("No hay datos para guardar")
            }
        }
   
    return (
      <div>
          <form className="mb-3" onSubmit={handleSubmit}>
            <h3>Generar lista de Invitados</h3>
            <div className="mb-3">
                <label htmlFor="cant_invitados" className="form-label">Cantidad de Invitados</label>
                <input type="text" className="form-control" id="cant_invitados" onChange={handleCantidad} />
            </div>
            <Button type="submit" disabled={loading.isloading}>Guardar</Button>
        </form>
      </div>
    );
  }
 
export default GenerarInvitado;