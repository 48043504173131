import React, { useState } from 'react';
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import {Link, useParams} from 'react-router-dom';
import Logo from "../../../assets/images/glup-tickets-logo.png";
import {ChevronLeft} from 'react-bootstrap-icons';
import Conexion from "../../Conexion/Conexion";
import ExportarIngresos from './ExportarExcel';
const { apiUrl } = Conexion();

/*async function cargarInvitados(eventoId) {
    return fetch(`${apiUrl}/api/invitados/evento/${eventoId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            if (r.status === 403) {
                //window.location.replace(window.location.origin + "/app");
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
}*/
async function cargarInvitadosSubEventos(eventoId) {
    return fetch(`${apiUrl}/api/invitados/subevento/${eventoId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            if (r.status === 403) {
                //window.location.replace(window.location.origin + "/app");
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
} 

async function cargarInvitadosMultiUso(eventoId) {
    return fetch(`${apiUrl}/api/invitados/multi-uso/${eventoId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            if (r.status === 403) {
                window.location.replace(window.location.origin + "/app");
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
}

 async function VerificarTipoEvento(eventoId) {
    return fetch(`${apiUrl}/api/eventos/tipo-evento/${eventoId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            if (r.status === 403) {
                //window.location.replace(window.location.origin + "/app");
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
}




export default function IngresosMulti() {

    const { eventoId,eventoPadre } = useParams();
    const [datos, setDatos] = useState([]);
    const [tipo, setTipo] = useState(0);
    const [cantidad, setCantidad] = useState({
        total: 0,
        checked: 0
    });

    const tableStyles = {
        header: {
            style: {
                backgroundColor: 'transparent',
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: 16+'px',
                lineHeight: 24+'px',
                color: '#FFFFFF',
            },
        },
        rows: {
            style: {
                minHeight: '24px', // override the row height
                color:'#FFFFFF',
                border: 'none !important'
            },
        },
        headCells: {
            style: {
                backgroundColor: '#151e23',
                color:'#b1f873',
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderBottomColor: '#b1f873',
            },
        },
        cells: {
            style: {
            },
        },
        pagination: {
            style: {
                color: '#b1f873',
                fontSize: '13px',
                minHeight: '56px',
                backgroundColor: '#222121',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderTopColor: '#b1f873',
            },
            pageButtonsStyle: {
                borderRadius: '50%',
                height: '40px',
                width: '40px',
                padding: '8px',
                margin: 'px',
                cursor: 'pointer',
                transition: '0.4s',
                color: '#b1f873',
                fill: '#b1f873',
                backgroundColor: '#222121',
                '&:disabled': {
                    cursor: 'unset',
                    color: '#84a368',
                    fill: '#84a368',
                },
                '&:hover:not(:disabled)': {
                    backgroundColor: '#7de423',
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: '#7de423',
                },
            },
        },
    };
    const conditionalRowStyles = [
        {
            when: row => row,
            style: row => ({
                backgroundColor: row.nro_invitado % 2 == 0 ? '#252f38' : '#303b44',
            }),
        },
      ];

    const cargarDatos = async () => {
        const tipoEvento = await VerificarTipoEvento(eventoId);
        var invitados = []

        if (tipoEvento.subevento == 1) {
            invitados = await cargarInvitadosSubEventos(eventoId);
        }else if(tipoEvento.subevento == 3) {
            invitados = await cargarInvitadosMultiUso(eventoId);    
        }else{
            //invitados = await cargarInvitados(eventoId);
            return;
        }

        if (invitados) {
            var checked = 0;
            invitados.forEach(element => {
                if (element.hora_ingreso != null) {
                    var date = new Date(element.hora_ingreso);
                    element.hora_ingreso = date.toLocaleTimeString()
                    checked++;
                }
            });
            console.log(invitados)
            console.log(_.map(invitados, 'usados'))
            setCantidad({
                total: tipoEvento.subevento == 3 ? _.sum(_.map(invitados, 'usos')) : invitados.length,
                checked: tipoEvento.subevento == 3 ? _.sum(_.map(invitados, 'usados')) : checked
            })
            setTipo(tipoEvento.subevento);
            setDatos(invitados);
        }
    }

    if (datos.length === 0) {
        cargarDatos();
    }

    const columns = [
        {
            name: '#',
            selector: row => row.nro_invitado,
            width: '70px',
            sortable: true
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true
        },
        {
            name: 'Hora de Ingreso',
            selector: row => row.hora_ingreso ? row.hora_ingreso : "--:--:--",
            sortable: true,
            omit: tipo == 3
        },
        {
            name: 'Estado',
            selector: row => {
                if (tipo == 3) {
                    return (row.usados != 0 ? row.usados : "-") + "/" + (row.usos != null ? row.usos : "-");
                }else{
                    return row.estado > 1 ? "CHECK" : "NO CHECK"
                }
            },
            sortable: true,
            width: '100px',
            conditionalCellStyles: [
                {
                    when: row => {
                        if (tipo == 3) {
                            return row.usados > 0
                        }else{
                            return row.estado > 1
                        }
                    },
                    style: {
                        color:'#1c1c1c',
                        backgroundColor: '#b1f873',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                        justifyContent: 'center',
                    },
                },
                {
                    when: row => {
                        if (tipo == 3) {
                            return row.usados == 0;
                        }else{
                            return row.estado <= 1
                        }
                    },
                    style: {
                        color:'#1c1c1c',
                        backgroundColor: '#d56e6e',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                        justifyContent: 'center',
                    },
                },
            ],
        },
        {
            name: 'Generado por',
            selector: row => row.usuario,
            sortable: true,
        },
        {
            name: 'Categoria',
            selector: row => row.nombree,
            sortable: true
        }
    ];

    return (
      <div className='wrapper'>
        <div className=''>
            <div className='app-top-left-circle'></div>
            <div className='app-bottom-right-circle'></div>
            <div style={{textAlign:"center"}} >
                <img src={Logo} alt="Logo Glup Tickets" width={"200px"}/>
            </div>
        </div>
        <div className='container overflow-auto'>
            <DataTable
            customStyles={tableStyles}
            columns={columns}
            data={datos}
            title={"INGRESOS ALTER "+cantidad.checked+"/"+cantidad.total}
            actions={ tipo == 3 ? '' : <div><ExportarIngresos data={datos} nombre={"Ingresos_GlupTickets"} /></div>}
            pagination
            conditionalRowStyles={conditionalRowStyles} />

            <div className='group-btns text-center mx-auto' style={{position:'relative'}}> 
                <Link className='app-button' to={`/app/subevento/${eventoPadre}`}><ChevronLeft size={24}/>&nbsp; Volver</Link>
            </div>
        </div>
      </div>
    )
    
}
