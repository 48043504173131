import React, {useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import {BoxArrowLeft, Calendar2PlusFill, ChevronLeft, PersonLinesFill, QrCodeScan} from 'react-bootstrap-icons';
import Logo from "../../../assets/images/glup-tickets-logo.png";
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import Conexion from "../../Conexion/Conexion";
import styled from "styled-components";
import useToken from '../../useToken/useToken';
const {apiUrl} = Conexion();

// async function datosCliente(cliente_id) {
//   return fetch(`${apiUrl}/api/usuarios/cliente/${cliente_id}`, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': sessionStorage.getItem('token')
//     }
//   }).then(r =>{
//     if (r.status > 399) {
//       if(r.status === 401){
//           alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
//           sessionStorage.clear();
//           window.location.replace(window.location.origin);
//           console.log(r.status)
//       }
//       console.log("Error","Could not reach server");
//     }
//     return r.json()
//   })
// }

/*async function traerEventos() {
  return fetch(`${apiUrl}/api/eventos/cliente/id`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('token')
    }
  }).then(r =>{
    if (r.status > 399) {
      if(r.status === 401){
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
      }
      console.log("Error","Could not reach server");
    }
    return r.json()
  })
}*/

async function traerSubEventos(evento_id) {
  return fetch(`${apiUrl}/api/eventos/subevento/${evento_id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('token')
    }
  }).then(r =>{
    if (r.status > 399) {
      if(r.status === 401){
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
      }
      console.log("Error","Could not reach server");
    }
    return r.json()
  })
}

function ListaEventosMulti() {
  const [state, setState] = useState({
    eventos: [],
    isfetched: false
  });

  const {eventoId}=useParams();
  const { user } = useToken();
  const cargarDatosEventos = async () => {
    const dEventos = await traerSubEventos(eventoId);
    if (dEventos) {
      setState({
        eventos:  dEventos,
        isfetched: true
      })
    }
  }

  if(!state.isfetched && state.eventos.length==0){
    cargarDatosEventos();
  }

 
  

  const Lista = () =>{
    let eventos = state.eventos;
    var lista = eventos.map((evento, i) =>{
     
     let fecha = formatInTimeZone(evento.fecha_evento,'UTC', "dd/MM/yyyy", {
        awareOfUnicodeTokens: true
    })
      return (<Link to={`/app/subevento/${eventoId}/ingresos/${evento.id}`} key={'div-evento'+i} className='app-evento-link'>
      <div style={{width:'300px',height:"50px" ,margin:'auto', textAlign:'left', background:'#2CBCE4',borderRadius:"5px"}}>
        <p className='app-evento-nombre' key={'evento-nombre'+i}>{evento.nombre}</p>
        <p className='app-evento-fecha' key={'evento-fecha'+i}>{(fecha)}</p>
      </div>
      <br/>
    </Link>)
    } );

      return (
      <div style={{textAlign: 'center'}}>
        {state.isfetched == false ? <h2 className='text-info'>Cargando...</h2>: eventos.length > 0  ?lista: <h2 className='text-danger'>No tienes eventos</h2>}
      </div>
    )
  }


  return (
    <div className='app-wrapper wrapper'>
      <div className=''>
        <div className='app-top-left-circle'></div>
        <div className='app-middle-rigth-circle'></div>
        <div style={{textAlign:"center"}} >
          <img src={Logo} alt="Logo Glup Tickets" width={"200px"}/>
        </div>
        <div>
          <h1 className='app-title'>Ingresos</h1>
          <Lista></Lista>
          <Link to={`/app/evento/${eventoId}`}  className='app-button'><ChevronLeft size={24}/>&nbsp; Volver</Link><br/>
        </div>      
      </div>
    </div>
  );
}
export default ListaEventosMulti;