import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import {LandingPage as Landing} from "./components/Landing/Landing";
import App from './components/App/App';
import Demo from './components/Demo/Demo';
import Admin from "./components/Admin/Admin";
import ReactGA from "react-ga4";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './components/Landing/styles/index.css';
import './components/Landing/styles/flexboxgrid.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'popper.js/dist/umd/popper';
import 'bootstrap/dist/js/bootstrap';

const rootElement = document.getElementById('root');

ReactGA.initialize("G-0BC1W28KDK");


ReactDOM.render(
  <AppRouter />,
  rootElement
);

function AppRouter(){

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return(
    <HelmetProvider>
      <Helmet>
        <title>Glup Ticket - Control QR para tus eventos</title>
        <meta
          name="description"
          content="Glup Ticket es un sistema de emisión de Tickets y membresías , ideal para cualquier tipo de evento o establecimiento con tarifas económicas y con un sistema de control ejecutable desde cualquier dispositivo móvil y muy fácil de usar."
        />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="admin/*" element={<Admin />} />
          <Route path="app/*" element={<App />} />
          <Route path="demo/*" element={<Demo />} />
          <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>404 Not Found</p>
            </main>
          }
        />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  )
}
