import React from "react";
import ReactExport from "react-export-excel";
import _ from 'lodash';
import formatInTimeZone from "date-fns-tz/formatInTimeZone";
import format from "date-fns-tz/format";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



export default function ExportIngresos(props) {

    
    let data = props.data;
    // data.forEach(e => {
    //   console.log('Hora', e['hora_ingreso']);
    //   if(e['hora_ingreso'] !== null){
    //     let date_data = new Date(e['hora_ingreso']+"Z");
    //     e['fecha_ingreso'] = format( date_data,  "dd-MM-yyyy");
    //     e['hora_ingreso'] = format( date_data,  "HH.mm.ss");
    //   }
    // });

    /*console.log(data);
    let _data = _.map(data,(d, i)=>{
        return [
            {value: d.nro_invitado},
            {value: d.nombre},
            {value: d.hora_ingreso},
            {value: d.estado},
        ]
    })
    //console.log(_data);
    const styledMultiDataSet = [
        {
          columns: [
            {
              value: "#",
              widthPx: 50,
              style: { font: { sz: "24", bold: true } },
            },
            {
              value: "Nombre",
              widthPx: 180,
              style: { font: { sz: "24", bold: true } },
            },
            {
              value: "Hora de Ingreso",
              widthPx: 180,
              style: { font: { sz: "24", bold: true } }, // if no width set, default excel column width will be used ( 64px )
            },
            {
                value: "Estado",
                widthPx: 180,
                style: { font: { sz: "24", bold: true } }, // if no width set, default excel column width will be used ( 64px )
              },
          ],
          data: [
            [
              { value: "H1", style: { font: { sz: "24", bold: true } } },
              { value: "Bold", style: { font: { bold: true } } },
              {
                value: "Red",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
            ],
            [
              { value: "H2", style: { font: { sz: "18", bold: true } } },
              { value: "underline", style: { font: { underline: true } } },
              {
                value: "Blue",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FF0000FF" } },
                },
              },
            ],
            [
              { value: "H3", style: { font: { sz: "14", bold: true } } },
              { value: "italic", style: { font: { italic: true } } },
              {
                value: "Green",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FF00FF00" } },
                },
              },
            ],
            [
              { value: "H4", style: { font: { sz: "12", bold: true } } },
              { value: "strike", style: { font: { strike: true } } },
              {
                value: "Orange",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFF86B00" } },
                },
              },
            ],
            [
              { value: "H5", style: { font: { sz: "10.5", bold: true } } },
              { value: "outline", style: { font: { outline: true } } },
              {
                value: "Yellow",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFFFF00" } },
                },
              },
            ],
            [
              { value: "H6", style: { font: { sz: "7.5", bold: true } } },
              { value: "shadow", style: { font: { shadow: true } } },
              {
                value: "Light Blue",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
                },
              },
            ],
          ],
        },
      ];*/


      let reportes = props.reportes;
      let hojas = reportes.map((r, i) =>{
        
        let _datetime = format( new Date(r.hora),  "dd-MM-yyyy HH.mm.ss");
        let accion = r.accion
        let _invitados = _.cloneDeep(r.json_agg)
        let dataset = []
        data.forEach(element => {
          let row = {nro_invitado: element.nro_invitado, nombre: element.nombre,usuario:element.usuario, hora_ingreso:null, estado:1}
          if(_.some(_invitados, { 'nro_invitado': element.nro_invitado })){
            let chequeado = _.filter(_invitados,{ 'nro_invitado': element.nro_invitado })
            let _hora_ingreso = '';
            let _fecha_ingreso = '';
            if(chequeado[0].hora_ingreso != null){
              let date_data = new Date(chequeado[0].hora_ingreso+"Z");
              _hora_ingreso =  format( date_data,  "HH.mm.ss");
              _fecha_ingreso = format( date_data,  "dd-MM-yyyy");
            }
            row = {nro_invitado: element.nro_invitado, nombre: element.nombre,usuario:element.usuario, hora_ingreso:_hora_ingreso, fecha_ingreso:_fecha_ingreso, estado:chequeado[0].estado};
            dataset.push(row);
          }else{
            dataset.push(row)
          }
        
        });

        return (
          <ExcelSheet key={i} data={dataset} name={accion+" "+_datetime}>
                <ExcelColumn label="#" value="nro_invitado"/>
                <ExcelColumn label="Nombre" value="nombre"/>
                <ExcelColumn label="Generado por" value="usuario"/>
                <ExcelColumn label="Fecha de Ingreso" value="fecha_ingreso"/>
                <ExcelColumn label="Hora de Ingreso" value="hora_ingreso"/>
                <ExcelColumn label="Estado" 
                                value={(col) => col.estado > 1 ? "CHECK" : "NO CHECK"}/>
          </ExcelSheet>
        )

        
      })

    return (
        <ExcelFile filename={"Ingresos - "+ props.nombre} element={<button className='btn btn-sm btn-success' >Descargar Ingresos</button>}>
            <ExcelSheet data={data} name="Ingresos Actuales">
                <ExcelColumn label="#" value="nro_invitado"/>
                <ExcelColumn label="Nombre" value="nombre"/>
                <ExcelColumn label="Generado por" value="usuario"/>
                <ExcelColumn label="Fecha de Ingreso" value="fecha_ingreso"/>
                <ExcelColumn label="Hora de Ingreso" value="time_ingreso"/>
                <ExcelColumn label="Estado" 
                                value={(col) => col.estado > 1 ? "CHECK" : "NO CHECK"}/>
            </ExcelSheet>
            {hojas}
            
        </ExcelFile>
    );
}