import React from "react";
import { AppButton, CustomButton } from '../../App/components/AppButton';


export default function FormularioPago({data, cancelAction}){
    const {
        access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,merchant_defined_data1,merchant_defined_data4,merchant_defined_data6,merchant_defined_data9,merchant_defined_data11,merchant_defined_data87,merchant_defined_data90,merchant_defined_data91,
        bill_to_forename,
        bill_to_surname,
        bill_to_email,
        bill_to_address_line1,
        bill_to_address_country,
        bill_to_address_state,
        bill_to_address_city,
        bill_to_address_postal_code,
        signature
    } = data

return(
    <form id="payment_form" action="https://secureacceptance.cybersource.com/pay" method="post">
        <div className="section">
            <input type="hidden" name="access_key" value={access_key} />
            <input type="hidden" name="profile_id" value={profile_id} />
            <input type="hidden" name="transaction_uuid" value={transaction_uuid} />
            <input type="hidden" name="signed_field_names" value={signed_field_names} />
            <input type="hidden" name="unsigned_field_names" value={unsigned_field_names} />
            <input type="hidden" name="signed_date_time" value={signed_date_time} />
            <input type="hidden" name="locale" value={locale} />
            <input type="hidden" name="bill_to_forename" value={bill_to_forename} />
            <input type="hidden" name="bill_to_surname" value={bill_to_surname} />
            <input type="hidden" name="bill_to_email" value={bill_to_email} />
            <input type="hidden" name="bill_to_address_line1" value={bill_to_address_line1} />
            <input type="hidden" name="bill_to_address_country" value={bill_to_address_country} />
            <input type="hidden" name="bill_to_address_state" value={bill_to_address_state} />
            <input type="hidden" name="bill_to_address_city" value={bill_to_address_city} />
            <input type="hidden" name="bill_to_address_postal_code" value={bill_to_address_postal_code} />
            <input type="hidden" name="merchant_defined_data1" value={merchant_defined_data1} />
            <input type="hidden" name="merchant_defined_data4" value={merchant_defined_data4} />
            <input type="hidden" name="merchant_defined_data6" value={merchant_defined_data6} />
            <input type="hidden" name="merchant_defined_data9" value={merchant_defined_data9} />
            <input type="hidden" name="merchant_defined_data11" value={merchant_defined_data11} />
            <input type="hidden" name="merchant_defined_data87" value={merchant_defined_data87} />
            <input type="hidden" name="merchant_defined_data90" value={merchant_defined_data90} />
            <input type="hidden" name="merchant_defined_data91" value={merchant_defined_data91} />
        </div>
        <div id="paymentDetailsSection" className="section">
            <input type="hidden" name="transaction_type" size="25" value="sale" />
            <input type="hidden" name="reference_number" size="25" value={reference_number} />
            <input type="hidden" name="amount" size="25" value={amount} />
            <input type="hidden" name="currency" size="25" value="USD" />
            <input type="hidden" name="signature" size="25" value={signature} />
        </div>
        <div className="flex flex-row justify-content-end">
            <CustomButton type="button" id="cancel" title={"Cancelar"} className="mx-2 redBg whiteColor" action={()=>{cancelAction(reference_number, transaction_uuid)}} />
            <AppButton type="submit" id="submit" className="" title={"Proceder al pago"} />
            {/* <input type="button" id="cancel" name="cancel" className="mx-2 btn btn-danger btn-lg btn-block" value="Cancelar" onClick={()=>{cancelAction(reference_number, transaction_uuid)}} />
            <input type="submit" id="submit" name="submit" className="btn btn-success btn-lg btn-block" value="Proceder al pago"/> */}
        </div>
    </form>
    )
}