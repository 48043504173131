import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import CambiarContrasenaForm from './CambiarContrasenaForm';
export default function CambiarContrasena() {
  return(
    <>
    <Sidebar />
    <div className='container overflow-auto' style={{height:"100vh",background:"#ed8282"}}>
          <CambiarContrasenaForm />
      </div>
    </>
  );
}