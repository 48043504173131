import React, { useState, useEffect }from 'react';
import _ from 'lodash';
import Html5QrcodePlugin from '../../Html5QrcodePlugin/Html5QrcodePlugin';
import { LinkButton } from '../components/AppButton';
import { Helmet } from "react-helmet-async";
import AppLogo from '../components/AppLogo';
import {ChevronLeft} from 'react-bootstrap-icons';
import {useParams} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import formatDistance from 'date-fns/formatDistance';
import { es } from 'date-fns/locale';

import 'react-toastify/dist/ReactToastify.css';

import Logo from "../../../assets/images/glup-tickets-logo.png";
import beep from "../../../assets/sounds/beep.mp3";
import BotonVerde from "../../../assets/images/Boton Verde.svg";
import BotonRojo from "../../../assets/images/Boton Rojo.svg";
import Conexion from '../../Conexion/Conexion';
const {apiUrl} = Conexion();

async function datosEvento(eventoId) {
  return fetch(`${apiUrl}/api/eventos/${eventoId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('token')
    }
  }).then(r =>{
    if (r.status > 399) {
      if(r.status === 401){
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
      }
      if(r.status === 403){
        window.location.replace(window.location.origin+"/app");
      }
      console.log("Error","Could not reach server");
    }
    return r.json()
  })
    
}

async function buscarInvitadoAlter(eventoId, datos) {
  return fetch(`${apiUrl}/api/invitados/validar-qr/sub/${eventoId}`, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('token')
      },
      body: JSON.stringify(datos)
  }).then(r =>{
    if (r.status > 399) {
      if(r.status === 401){
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
      }
      if(r.status === 403){
        window.location.replace(window.location.origin+"/app");
      }
      console.log("Error","Could not reach server");
    }
    return r.json()
  })
}

function vibrate() {
  if (!window) {
      return;
  }

  if (!window.navigator) {
      return;
  }

  if (!window.navigator.vibrate) {
      return;
  }

  window.navigator.vibrate([500]);
}

function EscanearQr(props){

  const [state,setState] = useState({
    nombre_evento: '',
    result: '',
    evento_id: 0,
    messageBtn: BotonRojo
  });

  const [scanResult, setScanResult] = useState('')

  const onNewScanResult = (decodedText, decodedResult) => {
    setScanResult(decodedText)
  }

  const handleScan = async (data) => {
    let audio = new Audio(beep);
    audio.play();
    if(data == null) return
      vibrate();
      const datos = {
        qr: data
      }
      const validarQR = await buscarInvitadoAlter(state.evento_id, datos);
      let mensaje = "";
      let _state = _.cloneDeep(state);
      if(validarQR.Success){
      const Invitado = validarQR.Data;
      if(validarQR.Repetido){
        if(Invitado.hora_ingreso != null){
          var hora_ingreso = formatDistance(new Date(Invitado.hora_ingreso),new Date(),{locale: es}) 
          mensaje = `${Invitado.nombre} ingresó hace ${hora_ingreso}. (Escaneado ${Invitado.repetido+1} veces )` ;
        }else{ mensaje = "El ticket ya no está habilitado" }
        
        _state.result = mensaje;
        _state.messageBtn= BotonRojo;
        setState(_state);
        toast.error(mensaje, {
          theme: "colored",
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
          });
      } else {
        mensaje = "Aceptado: "+ Invitado.nombre;
        _state.result = mensaje;
        _state.messageBtn = BotonVerde;
        setState(_state);
      toast.success('INGRESO ACEPTADO', {
        theme: "colored",
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        });
      }
    }else{
      mensaje = validarQR.Mensaje;
      _state.result = mensaje;
      _state.messageBtn = BotonRojo;
      setState(_state);
      toast.warning('QR NO VÁLIDO', {
        theme: "colored",
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
        });
    }
    function timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }

  const cargarDatos= async () =>{
    const {eventoId} = props.params;
    const datos_evento = await datosEvento(eventoId);
    const dEvento = await datosEvento(eventoId);
    let _state = _.cloneDeep(state);
    if (dEvento) {
      _state.evento_id=eventoId;
      _state.nombre_evento=dEvento.nombre;
      setState(_state);
    }else{        
      _state.evento_id=eventoId;
      setState(_state);
    }
  }


  useEffect(() => {
    cargarDatos();
  }, []);

  useEffect( async () => {
    if(scanResult != ''){
        handleScan(scanResult)
        await new Promise(resolve => setTimeout(resolve, 4500) )
        setScanResult('')
    } 
}, [scanResult]);

 
  return(
    <div className='container-sm'>
      <Helmet>
        <title>Escanear Tickets - Glup Ticket</title>
      </Helmet>
      <div className='text-center'>
        <AppLogo />
        <div>
          <h1 className='font30 extraBold'>{state.nombre_evento}</h1>
        </div>
        <div style={{maxWidth:'500px', margin:'auto'}} >
        <Html5QrcodePlugin 
                fps={10}
                qrbox={250}
                aspectRatio={1.0}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
          />
        </div>
        <div className="d-flex flex-column">
          <p className='mt-3 font17 semiBold' >
            <img src={state.messageBtn} alt="Boton" width={"25px"}/>   
            {state.result}
          </p>
          <LinkButton to={`/app/evento/${state.evento_id}`} className='my-4' border title={<><ChevronLeft size={24}/>&nbsp; Atras</>} />
        </div>
      </div>
      <ToastContainer style={{fontSize: 30}} />
    </div> 
  )
}

const QR = (props) =>(
  <EscanearQr
    {...props}
    params={useParams()}
  />
);

export default QR;