import React, { useState } from 'react';
import _ from 'lodash';
import { Helmet } from "react-helmet-async";
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Container, Col, Row, Spinner} from 'react-bootstrap';
import { ChevronLeft, PersonPlusFill, EyeFill, EyeSlashFill} from 'react-bootstrap-icons';
import { toast, ToastContainer } from 'react-toastify';
import { LinkButton, AppButton } from '../components/AppButton';
import AppLogo from '../components/AppLogo';
import Conexion from "../../Conexion/Conexion";
import 'react-toastify/dist/ReactToastify.css';
const {apiUrl} = Conexion();

async function crearRol(datos) {
  return fetch(`${apiUrl}/api/usuarios/roles/cliente`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('token')
    },
    body: JSON.stringify(datos)
  })
    .then(r =>{
      if (r.status > 399) {
        if(r.status === 401){
            alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
            sessionStorage.clear();
            window.location.replace(window.location.origin);
            console.log(r.status)
        }
        console.log("Error","Could not reach server");
      }
      return r.json()
  })
}



function RolForm() {

  const navigate = useNavigate();
  const [state, setState] = useState({
    nombre: '',
    correo: '',
    password: '',
    confPassword: '',
    id_rol: 0,
    isLoading: false
  });

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword =(e)=>{
    e.preventDefault();
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }

  const handleChange = (e) =>{
    let _state = _.cloneDeep(state);
    let value = e.target.value;
    if(e.target.name == 'nombre'){
      _state.nombre=value;
    }
    if(e.target.name == 'correo'){
      _state.correo=value;
    }
    if(e.target.name == 'password'){
      _state.password=value;
    }
    if(e.target.name == 'confPassword'){
      _state.confPassword=value;
    }
    setState(_state)
    
  }

  const handleRol = (e) =>{
    let _state = _.cloneDeep(state);
    if(e.target.name == 'manager'){
      _state.id_rol=2;
    }else{
      _state.id_rol=3;
    }
    setState(_state)
  }

  const handleSubmit = async (e) =>{
    e.preventDefault();
      if(state.password !== state.confPassword){
        toast.warn('Confirme la contraseña correctamente', {
          theme: "colored",
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          });
          return
      }
        if(state.id_rol == 2 || state.id_rol == 3){
            const usuario = await crearRol(state);
            if(usuario.ok){
              toast.success(usuario.mensaje, {
                theme: "colored",
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                });
                navigate(`/app/roles`)
            } 
            else {
              toast.error(usuario.mensaje, {
              theme: "colored",
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              });}
        }else{
          toast.warn('Seleccione un Tipo de permiso', {
            theme: "colored",
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            });
        } 
  }

  return (
    <div className='container-sm'>
      <Helmet>
          <title>Crear Rol - Glup Ticket</title>
      </Helmet>
      <div className='text-center'>
        <AppLogo />
        <Container style={{zIndex:'1'}}>
        <form onSubmit={handleSubmit}>
          <h4 className='extraBold' >Crear Permiso</h4>
          <Row className='mb-4'>
              <FormControl  className='mb-1'>
                <FormLabel>Nombre: </FormLabel>
                <StyledInput type="text"  name="nombre" value={state.nombre} placeholder='Nombre' onChange={handleChange} required/>
              </FormControl>
              <FormControl className='mb-1'>
                <FormLabel>Correo:</FormLabel>
                <StyledInput type="email" name="correo" value={state.correo} placeholder='Correo' onChange={handleChange} required/>
              </FormControl>
              <FormControl className='mb-1'>
                <FormLabel>Contraseña:</FormLabel>
                <div style={{display:'flex'}}>
                <StyledInput type={passwordType} name="password" value={state.password} placeholder='Contraseña' onChange={handleChange} required/>
                <button className="btn" onClick={togglePassword}>
                  { passwordType==="password"? <EyeFill ></EyeFill> :<EyeSlashFill ></EyeSlashFill> }
                </button>
                </div>
                
              </FormControl>
              <FormControl>
                <FormLabel className="form-label">Confirmar Contraseña:</FormLabel>
                <StyledInput type={passwordType}  name="confPassword" value={state.confPassword} placeholder='Confirmar Contraseña' onChange={handleChange} required/>
              </FormControl>
              
              
              <Col xs={12} className="mb-1" style={{textAlign:'left'}}>
                  <FormLabel className="form-label">Tipo de Permiso:</FormLabel>
              </Col>
              <Col xs={6} className="mb-1" >
                  <ButtonSelect type="button" active={state.id_rol===2} className="form-control"  name="manager" value={'Vendedor'} onClick={handleRol} required/>
              </Col>
              <Col xs={6} className="mb-1">
                  <ButtonSelect type="button" active={state.id_rol===3} className="form-control" name="scanner" value={'Scanner'} onClick={handleRol} required/>
              </Col>
          </Row>
          
          <div className="d-flex flex-column">
            {state.isLoading ?
              <Spinner animation="border" />
            : <AppButton type={'submit'} className='mx-auto mb-1' title={<><PersonPlusFill size={24} />&nbsp; Crear Permiso</>} />
            } <LinkButton border className='my-4' to={`/app/roles`} title={<><ChevronLeft size={24} />&nbsp; Atras</>} />
          </div>
          </form>
        </Container>
      </div>
      <ToastContainer />
    </div>
  );
}


const StyledInput = styled.input`
    background: #2F2F2F;
    border: none;
    background-color: #eee;
    width: 100%;
    padding: 0px;
    border-radius: 4px;
    min-width: 200px;
    font-family: Arial, sans-serif;
    padding-left: 5px;
    font-style: normal;
    font-size: 1rem; 
    line-height: 36px; 
`;

const FormControl = styled.div`
    border: none;
    width: 100%;
    padding: 0px;
    border-radius: 4px;
    text-align:left
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 600;
  line-height: 36px; 
`;

const ButtonSelect = styled.input`
  height: 50px;
  background-color: ${(props) => (props.active ? "#dcfdd4" : "transparent")};
  color: ${(props) => (props.active ? "#000" : "#178fcf")};
  :hover {
    background-color: ${(props) => (props.active ? "#dcfdd4" : "transparent")};
    border: 1px solid #dcfdd4;
    color: ${(props) => (props.active ? "#000" : "#178fcf")};
  }
  :focus {
    background-color: ${(props) => (props.active ? "#dcfdd4" : "transparent")};
    border: 1px solid #dcfdd4;
    color: ${(props) => (props.active ? "#000" : "#178fcf")};
  }
`

export default RolForm;