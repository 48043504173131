import React, { useState, useEffect } from 'react';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { Helmet } from "react-helmet-async";
import PropTypes from 'prop-types';
import Logo from "../../assets/images/s002.png";
import { toast, ToastContainer } from 'react-toastify';
import Divider from '@mui/material/Divider'
import FullButton from "../Landing/Buttons/FullButton";
import { EyeFill, EyeSlashFill, Google } from 'react-bootstrap-icons';
import Conexion from '../Conexion/Conexion';
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';


const {apiUrl} = Conexion(); 

async function loginUserAdmin(credentials) {
  return fetch(`${apiUrl}/api/login/admin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

async function loginUserCliente(credentials) {
  return fetch(`${apiUrl}/api/login/app`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

async function loginUserGoogle(profileObj) {
  return fetch(`${apiUrl}/api/login/google`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(profileObj)
  })
    .then(data => data.json())
}


export default function Login({ setToken, setUser, validar }) {
  const [nombre_usuario, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState("password");

  const clientId = '109424360290-rbv8n5qgn03hvr4rpba17v2caap2vh6h.apps.googleusercontent.com';

  useEffect(() => {
    const initClient = () => {
          gapi.client.init({
          clientId: clientId,
          scope: ''
        });
      };
      gapi.load('client:auth2', initClient);
  });

  const onSuccess = async (res) => {
      let token = 0;
      console.log('Sesion Iniciada');
      console.log('success:', res);
      token = await loginUserGoogle(res.profileObj);
      if(token.ok){
        setToken(token.token);
        setUser(token.user);
        window.location.reload(false);
      }else{
        toast.warn(token.mensaje, {theme:'colored'});
      }


  };
  const onFailure = (err) => {
      console.log('failed:', err);
  };

  const togglePassword =(e)=>{
    e.preventDefault();
    console.log(e)
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }

  const handleSubmit = async e => {
    e.preventDefault();
    let token = 0;
    if(validar==="admin"){
      token = await loginUserAdmin({nombre_usuario,password});
    }
    if (validar==="cliente") {
      token = await loginUserCliente({nombre_usuario,password});
    } 
    if(token){
      setToken(token.token);
      setUser(token.user);
      window.location.reload(false);
    } 
    else if(token === 0){
      toast.error("USUARIO O CONTRASEÑA INVÁLIDOS" , {theme:'colored'});
    }else{
      toast.warn("USUARIO DESHABILITADO, COMUNÍQUESE CON SU ADMINISTRADOR", {theme:'colored'});
    } 
  }
  return (
    <div className="login-wrapper">
      <Helmet>
        <title>Login | Glup Ticket</title>
      </Helmet>
      <div className='logo-login'>
        <img src={Logo} alt="Logo Glup QR" width={"250px"}/>
      </div>
      <form  onSubmit={handleSubmit}>
        <div className='login-input' style={{background:'none'}}>
          <label>
            <p className='login-text'>Usuario</p>
            <input type="text" className='login-input login-text' placeholder='Ingresa tu correo' onChange={e => setUserName(e.target.value.trim())} />
          </label>
        </div>
        <div className='login-input' style={{background:'none'}}>
          <label>
            <p className='login-text'>Contraseña</p>
            <input type={passwordType} className='login-input login-text password-input' style={{width:'208px'}}  placeholder='Ingresa tu contraseña' onChange={e => setPassword(e.target.value.trim())} />
            <button type="button" className="btn password-eye" onClick={togglePassword} >
              { passwordType==="password"? <EyeFill ></EyeFill> :<EyeSlashFill ></EyeSlashFill> }
            </button>
          </label>
        </div>
        <div style={{textAlign:'center'}}>
            <FullButton type="submit" title="Inicia sesión" />
        </div>
      </form>
      {validar==="cliente" && 
      <>
      <div>
        <Divider light={false} sx={{width:'320px', m:2}}>O</Divider>
      </div>
      <div style={{marginBottom:'30px'}}>
       
          <GoogleLogin
              clientId={clientId}
              render={renderProps => (
                <FullButton type="submit" title={<><Google />&nbsp;Inicia sesión con Google</>} action={renderProps.onClick} />
              )}
              onSuccess={onSuccess}
              onFailure={onFailure}
              cookiePolicy={'single_host_origin'}
              isSignedIn={false}
          />
      </div>
      </>
      }
      <div className='login-footer-text'>
        <p>Para obtener acceso a su usuario y contraseña contactar con su representante de Glup Tickets</p>
      </div>
      <ToastContainer />
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}