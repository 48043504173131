import React from 'react';
import styled from "styled-components";
import * as Icons from 'react-bootstrap-icons';
import {Link} from 'react-router-dom';
//Components
import {Navbar, Container, Nav, NavDropdown} from 'react-bootstrap';
// Assets
import LogoImg from "../../../assets/images/s002.png";


export default function TopNavbar({sessionToken, sessionUser, cliente, active}) {


    return(
        <>
            <Navbar collapseOnSelect expand="lg" bg="white" fixed="top">
                <Container>
                    <Navbar.Brand>
                        <Link to="/app/panel">
                            <img className="radius8" src={LogoImg} alt="GlupTickets Logo" style={{zIndex: 9, width:'170px'}} />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mx-auto">
                            <Nav.Item>
                                <Link style={{textDecoration: 'none'}} className={`nav-link ${active==="inicio"?'active coralBg':''}`} to="/app/panel">
                                <Icons.HouseDoorFill />&nbsp;<span className="align-middle">Inicio</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link style={{textDecoration: 'none'}} className={`nav-link ${active==="eventos"?'active coralBg':''}`} to="/app/panel/eventos">
                                <Icons.CalendarWeekFill />&nbsp;<span className="align-middle">Eventos</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link style={{textDecoration: 'none'}} className={`nav-link ${active==="precios"?'active coralBg':''}`} to="/app/panel/precios">
                                <Icons.TagFill />&nbsp;<span className="align-middle">Precios</span>
                                </Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Collapse id="responsive-navbar-nav" style={{flexGrow:0}}>
                        <Nav className="mx-auto">
                        <Nav.Item>
                            <Link style={{textDecoration: 'none'}} className='nav-link' to="/app">
                            <Icons.QrCodeScan />&nbsp;<span className="align-middle">App</span>
                            </Link>
                        </Nav.Item>
                        <NavDropdown title={<><Icons.PersonFill />&nbsp;{cliente.nombre +" "+ cliente.apellido_p }</>} id="navbarScrollingDropdown">
                            <NavDropdown.Item  href="/app/panel/logout"><Icons.BoxArrowLeft />&nbsp;Cerrar Sesión</NavDropdown.Item>
                        </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <BlankSpace>&nbsp;</BlankSpace>
        </>
    );
}

const BlankSpace = styled.div`
    height: 74px;
`