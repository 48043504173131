import React from 'react';
import ListaEventos from './ListaEventos/ListaEventos';
import {Routes, Route} from "react-router-dom";
import Menu from './Menu/Menu';
import EscanearQr from './EscanearQR/EscanearQR';
import Ingresos from './Ingresos/Ingresos';
//import "./Demo.css";

function Demo() {
  //const { token, setToken,setUser,tipo,setTipo } = useToken();
  // if(!token  || tipo!==3) {
  //   return <Login setToken={setToken} setUser={setUser} setTipo={setTipo} validar={"cliente"}/>
  // }
  return (
    <div>
     
      <Routes>
        <Route path="/" element={<ListaEventos />} />
        <Route path="/evento/:eventoId" element={<Menu />} />
        <Route path="/evento/:eventoId/scan-qr" element={<EscanearQr />} />
        <Route path="/evento/:eventoId/ingresos" element={<Ingresos />} />
        <Route
          path="*"
          element={
            <div style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </div>
          }
        />
      </Routes>
    </div>
  );
}

export default Demo;
