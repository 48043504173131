import React, { useState } from 'react';
import _ from 'lodash';
import {useParams} from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import {DataGrid} from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import {ChevronLeft} from 'react-bootstrap-icons';
import Conexion from "../../Conexion/Conexion";
import { LinkButton } from '../components/AppButton';
import AppLogo from '../components/AppLogo';
import ExportarIngresos from './ExportarExcel';
const { apiUrl } = Conexion();

async function cargarInvitados(eventoId) {
    return fetch(`${apiUrl}/api/invitados/evento/${eventoId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            if (r.status === 403) {
                window.location.replace(window.location.origin + "/app");
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
}
async function cargarInvitadosSubEventos(eventoId) {
    return fetch(`${apiUrl}/api/invitados/subevento/${eventoId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            if (r.status === 403) {
                window.location.replace(window.location.origin + "/app");
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
} 

async function cargarInvitadosMultiUso(eventoId) {
    return fetch(`${apiUrl}/api/invitados/multi-uso/${eventoId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            if (r.status === 403) {
                window.location.replace(window.location.origin + "/app");
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
}

 async function VerificarTipoEvento(eventoId) {
    return fetch(`${apiUrl}/api/eventos/tipo-evento/${eventoId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            if (r.status === 403) {
                window.location.replace(window.location.origin + "/app");
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
}




export default function Ingresos() {

    const { eventoId } = useParams();
    const [datos, setDatos] = useState([]);
    const [tipo, setTipo] = useState(0);
    const [cantidad, setCantidad] = useState({
        total: 0,
        checked: 0
    });


    const cargarDatos = async () => {
        const tipoEvento = await VerificarTipoEvento(eventoId);
        var invitados = []

        if (tipoEvento.subevento == 1) {
            invitados = await cargarInvitadosSubEventos(eventoId);
        }else if(tipoEvento.subevento == 3) {
            invitados = await cargarInvitadosMultiUso(eventoId);    
        }else{
            invitados = await cargarInvitados(eventoId);
        }

        if (invitados) {
            var checked = 0;
            invitados.forEach(element => {
                if (element.hora_ingreso != null) {
                    var date = new Date(element.hora_ingreso);
                    element.hora_ingreso = date.toLocaleTimeString()
                    checked++;
                }
            });
            setCantidad({
                total: tipoEvento.subevento == 3 ? _.sum(_.map(invitados, 'usos')) : invitados.length,
                checked: tipoEvento.subevento == 3 ? _.sum(_.map(invitados, 'usados')) : checked
            })
            setTipo(tipoEvento.subevento);
            setDatos(invitados);
        }
    }

    if (datos.length === 0) {
        cargarDatos();
    }

    const columns = [
        {
            field: 'nro_invitado',
            headerName: '#',
            headerClassName: 'extraBold',
            headerAlign: 'center',
            width: 25,
        },
        {
            headerName: 'Nombre',
            field: 'nombre',
            headerClassName: 'extraBold',
            headerAlign: 'center',
            width: 100,
        },
        {
            headerName: 'Hora de Ingreso',
            field: 'hora_ingreso',
            headerClassName: 'extraBold',
            headerAlign: 'center',
            valueGetter: (params) => {
                return params.value ? params.value : "--:--:--"
            },
            width: 100,
            hide: tipo == 3
        },
        {
            headerName: 'Estado',
            field: 'estado',
            headerClassName: 'extraBold',
            headerAlign: 'center',
            renderCell: (params) => {
                return params.value > 1 ? <Chip className="darkColor secondaryBg2 mx-auto" size="small" label={"CHECK"} /> : <Chip className="redBg mx-auto" size="small" label={"NO CHECK"} />
            },
            width: 120,
        },
        {
            headerName: 'Generado por',
            field: 'usuario',
            headerClassName: 'extraBold',
            headerAlign: 'center',
            width: 200,
        },
        {
            headerName: 'Categoria',
            field: 'nombree',
            headerClassName: 'extraBold',
            headerAlign: 'center',
            width: 150,
        }
    ];

    return (
    <div className='container-sm'>
        <Helmet>
            <title>Reporte de Ingresos - Glup Ticket</title>
        </Helmet>
        <div className='text-center'>
            <AppLogo />
            <div>
                <h4 className='extraBold'>{"Ingresos: "+cantidad.checked+"/"+cantidad.total}</h4>
                { tipo == 3 
                    ? '' 
                    : <div className='d-flex justify-content-end'>
                        <ExportarIngresos data={datos} nombre={"Ingresos_GlupTickets"} />
                    </div>}
            </div>
            <div className='overflow-auto' style={{height:"500px", width:"100%", padding:"10px"}}>
                <DataGrid
                    columns={columns}
                    rows={datos}
                    pageSize={30}
                    rowsPerPageOptions={[30,50,100]}
                    rowHeight={25} 
                    disableColumnMenu
                />
            </div>
            <div className="d-flex flex-column">
                <LinkButton className='my-4' border to={`/app/evento/${eventoId}`} title={<><ChevronLeft size={24}/>&nbsp; Volver</>} />
            </div>
        </div>
      </div>
    )
    
}


