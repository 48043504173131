import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import { signData } from '../functions/signForm';
import { Helmet } from "react-helmet-async";
import TopNavbar from '../TopNavbar/TopNavbar';
import Footer from '../Footer/Footer';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import FormularioPago from './FormularioPago'
import { AppButton } from '../../App/components/AppButton';
import Conexion from "../../Conexion/Conexion";
import Badge from 'react-bootstrap/Badge';
//Assets
import CyberLogo from '../../../assets/images/cybslogo.png'
//Css
import 'react-toastify/dist/ReactToastify.css';

const {apiUrl} = Conexion();

async function obtenerPlan(id,selected) {
    return fetch(`${apiUrl}/api/planes/${id}?selected=${selected}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
      }
    }).then(r => {
      if (r.status > 399) {
        if (r.status === 401) {
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
        }
        console.log("Error", "Could not reach server");
      }
      return r.json()
    })
}

async function comprarPlan(data) {
    return fetch(`${apiUrl}/api/planes/comprar-plan`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        },
        body: JSON.stringify(data)
    }).then(r => {
        if (r.status > 399) {
        if (r.status === 401) {
            alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
            sessionStorage.clear();
            window.location.replace(window.location.origin);
            console.log(r.status)
        }
        console.log("Error", "Could not reach server");
        }
        return r.json()
    })
};

async function cancelarPlan(planes_usuarios_id, data) {
    return fetch(`${apiUrl}/api/planes/cancelar-plan-usuario/${planes_usuarios_id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        },
        body: JSON.stringify(data)
    }).then(r => {
        if (r.status > 399) {
        if (r.status === 401) {
            alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
            sessionStorage.clear();
            window.location.replace(window.location.origin);
            console.log(r.status)
        }
        console.log("Error", "Could not reach server");
        }
        return r.json()
    })
};

async function cargarPaisesYCiudades() {
    return fetch(`${apiUrl}/api/eventos/geografia_info`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
};

export default function Plan(props) {

    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [plan, setPlan] = useState(null);
    const [dropdownData, setDropdownData] = useState({
        paises:[],
        ciudades:[],
        pais_seleccionado: null,
        ciudad_seleccionado: null,
        filtro_ciudades: []
    })
    const [loading, setLoading] = useState(false);
    const [purchaseData, setPurchaseData] = useState(null);
    const [state, setState] = useState({
        nombre: '',
        apellido: '',
        email: props.cliente.correo,
        dni:'',
        direccion: '',
        pais: '',
        estado: '',
        ciudad: '',
        zip_code: ''
    });
    //const [selectData, setSelectData] = useState({ciudades:[], paises:[]});

    const cargarDatos = async (id, selected) =>{
        let data = await obtenerPlan(id, selected);
        if(data.ok){
            setPlan(data.plan);
        }else{
            console.log(data.mensaje);
            navigate("/app/panel/precios");
        }
        let _state = _.cloneDeep(state)
        const { paises, ciudades } = await cargarPaisesYCiudades();
        let _paises = paises.map(({id, codigo, nombre}) => ({ value: codigo, label: nombre, id: id }));
        let _ciudades = ciudades.map(({codigo, nombre, codigo_pais}) => ({ value: codigo, label: nombre, cod_pais: codigo_pais }));
        let _pais_default = _.find(_paises,['value','BO']); //Bolivia
        setDropdownData({ 
            paises: _paises,
            ciudades: _ciudades,
            pais_seleccionado: _pais_default,
            ciudad_seleccionado: null,
            filtro_ciudades: _.filter(_ciudades, ['cod_pais',_pais_default.value])
        })
        _state.pais = _pais_default.value
        _state.zip_code = "0000"
        setState(_state)
    }

    useEffect(() => {
        cargarDatos(searchParams.get("id"),searchParams.get("selected"));
    }, []);

    const handleChange = (e) => {
        let _state = _.cloneDeep(state);
        if (e.target.id === 'firstName') {
            _state.nombre = e.target.value
        } else if(e.target.id === 'lastName'){
            _state.apellido = e.target.value
        }else if(e.target.id === 'email'){
            _state.email = e.target.value
        }else if(e.target.id === 'dni'){
            _state.dni = e.target.value
        }else if(e.target.id === 'address'){
            _state.direccion = e.target.value
        }else if(e.target.id === 'zip'){
            _state.zip_code = e.target.value
        }else if(e.target.id === 'country'){
            _state.pais = e.target.value
        }else if(e.target.id === 'city'){
            _state.ciudad = e.target.value
        }
        setState(_state);
    }
    const handleChangePais = pais => {
        let _dropdownData = _.cloneDeep(dropdownData);
        let _state = _.cloneDeep(state);
        _dropdownData.pais_seleccionado = pais;
        _dropdownData.ciudad_seleccionado = null;
        _dropdownData.filtro_ciudades= _.filter(dropdownData.ciudades, ['cod_pais',pais.value]);
        _state.pais = pais.value;
        _state.estado = ""
        if(pais.value === "BO"){
            _state.zip_code = "0000"
        } else{
            _state.zip_code = ""
        }
        setDropdownData(_dropdownData);
        setState(_state);
    };
    const handleChangeCiudad = departamento => {
        let _state = _.cloneDeep(state);
        let _dropdownData = _.cloneDeep(dropdownData);
        _dropdownData.ciudad_seleccionado = departamento;
        _state.estado = departamento.value
        setDropdownData(_dropdownData);
        setState(_state);
    };

    const cancelPurchase = async (reference_number, transaction_uuid) =>{
        let datos ={
            transaccion_uuid: transaction_uuid
        }
        await cancelarPlan(reference_number, datos);
        console.log(cancelarPlan)
        setPurchaseData(null);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (state.nombre === "" ||
            state.apellido === "" ||
            state.email === "" || 
            state.dni === "" ||
            state.direccion === "" ||
            state.pais === "" || 
            state.estado === "" || 
            state.ciudad === "" || 
            state.zip_code === "" ) {
            toast.warn("Rellene todos los campos")
            setLoading(false);
            return;
        }
        if(!_.isNumber(Number(state.zip_code))){
            toast.warn("El código postal debe contener un valor numérico")
            setLoading(false);
            return;
        }
        let datos ={
            id_plan: plan.id,
            opcion: searchParams.get("selected")
        }
        let compra = await comprarPlan(datos);
        if(compra.ok === false){
            toast.error(compra.mensaje);
            await new Promise(resolve => setTimeout(resolve, 2500));
            navigate("/app/panel/nuevo-evento");
            setLoading(false);
            return;
        }

        if(plan.precios === 0){
            toast.success("Paquete FREE habilitado");
            await new Promise(resolve => setTimeout(resolve, 2500));
            navigate("/app/panel/nuevo-evento");
            return;
        }
        let signed_data = signData(plan.precios,compra.data.transaccion_uuid, compra.data.id, plan.nombre, state.dni, compra.last_purchase_date);
        if(signed_data){
            let _purchaseData = {
                ...signed_data,
                bill_to_forename: state.nombre,
                bill_to_surname: state.apellido,
                bill_to_email: state.email,
                bill_to_address_line1: state.direccion,
                bill_to_address_country: state.pais,
                bill_to_address_state: state.estado,
                bill_to_address_city: state.ciudad,
                bill_to_address_postal_code: state.zip_code,
                amount: plan.precios,
            }
            setPurchaseData(_purchaseData);
        }
        setLoading(false);
    }

    return (
        <>
        <Helmet>
            <title>Comprar Plan | Glup Ticket</title>
        </Helmet>
        <TopNavbar cliente={props.cliente} />
        <div className="container px-4 pb-5" style={{minHeight:'100vh'}}>
            <h2 className="pb-2 border-bottom font40 extraBold">Comprar Plan</h2>
            <div className="row">
                <div className="col-md-4 order-md-2 mb-4">
                <h4 className="d-flex justify-content-between align-items-center mb-3">
                    <span className="text-muted">Tu orden</span>
                    <Badge pill bg="secondary">{plan ? 1 : 0}</Badge>
                </h4>
                <ul className="list-group mb-3">
                    {plan &&
                        <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h6 className="my-0">PLAN {plan.nombre}</h6>
                            <small className="text-muted">{plan.cant_tickets} tickets</small><br/>
                            <small className="text-muted">{plan.descripcion}</small>
                        </div>
                        <span className="text-muted">${plan.precios}</span>
                        </li>
                    }
                    {/* <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <li className="list-group-item d-flex justify-content-between bg-light">
                    <div className="text-success">
                        <h6 className="my-0">Promo code</h6>
                        <small>EXAMPLECODE</small>
                    </div>
                    <span className="text-success">-$5</span>
                    </li> */}
                    <li className="list-group-item d-flex justify-content-between">
                    <span>Total (USD)</span>
                    <strong>${plan && plan.precios}</strong>
                    </li>
                </ul>
                {plan && plan.precios > 0 &&
                    <div className='text-end' style={{ width:'100%' }}>
                        <img src={CyberLogo} width="150" />
                    </div>
                }

                {/* <form className="card p-2">
                    <div className="input-group">
                    <input type="text" className="form-control" placeholder="Promo code"/>
                    <div className="input-group-append">
                        <button type="submit" className="btn btn-secondary">Redeem</button>
                    </div>
                    </div>
                </form> */}
                </div>
                <div className="col-md-8 order-md-1">
                    <h4 className="mb-3">Dirección de facturación</h4>
                    <form className="needs-validation" noValidate="" onSubmit={handleSubmit} >
                        <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="firstName">Nombre</label>
                            <input type="text" className="form-control" id="firstName" placeholder="" value={state.nombre} required onChange={handleChange} disabled={Boolean(purchaseData)} />
                            <div className="invalid-feedback">
                            Valid first name is required.
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="lastName">Apellido</label>
                            <input type="text" className="form-control" id="lastName" placeholder="" value={state.apellido} required onChange={handleChange} disabled={Boolean(purchaseData)} />
                            <div className="invalid-feedback">
                            Valid last name is required.
                            </div>
                        </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email">Correo {/*<span className="text-muted">(Optional)</span>*/}</label> 
                            <input type="email" className="form-control" id="email" placeholder="you@example.com" value={state.email} required onChange={handleChange} disabled={Boolean(purchaseData)} />
                            <div className="invalid-feedback">
                                Please enter a valid email address for shipping updates.
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email">Documento de identidad {/*<span className="text-muted">(Optional)</span>*/}</label> 
                            <input type="text" className="form-control" id="dni" value={state.dni} required onChange={handleChange} disabled={Boolean(purchaseData)} />
                            <div className="invalid-feedback">
                                Valid DNI is required.
                            </div>
                        </div>

                        <div className="mb-3">
                        <label htmlFor="address">Dirección</label>
                        <input type="text" className="form-control" id="address" placeholder="1234 Main St" required value={state.direccion}onChange={handleChange} disabled={Boolean(purchaseData)} />
                        <div className="invalid-feedback">
                            Please enter your shipping address.
                        </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="country">Pais</label>
                                <Select id="country" options={dropdownData.paises} placeholder="Buscar Pais" value={dropdownData.pais_seleccionado} onChange={handleChangePais} disabled={Boolean(purchaseData)} />
                                <div className="invalid-feedback">
                                    Please select a valid country.
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="state">Estado</label>
                                    <Select id="state" options={dropdownData.filtro_ciudades} placeholder="Buscar Estado" value={dropdownData.ciudad_seleccionado} onChange={handleChangeCiudad} disabled={Boolean(purchaseData)} />
                                {/* { dropdownData.pais_seleccionado && dropdownData.pais_seleccionado.value === "BO" ?
                                        <Select id="state" options={dropdownData.ciudades} placeholder="Buscar Departamento" value={dropdownData.ciudad_seleccionado} onChange={handleChangeCiudad} disabled={Boolean(purchaseData)} />
                                    :
                                        <input type="text" className="form-control" id="state" value={state.estado} required placeholder="Estado" onChange={handleChange} disabled={Boolean(purchaseData)} />
                                } */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="zip">Ciudad</label>
                                <input type="text" className="form-control" id="city" placeholder="" value={state.ciudad} required onChange={handleChange} disabled={Boolean(purchaseData)} />
                                <div className="invalid-feedback">
                                City required.
                                </div>
                            </div>
                            <div className="col-md-3 mb-3">
                                <label htmlFor="zip">Código Postal</label>
                                <input type="text" className="form-control" id="zip" placeholder="" value={state.zip_code} required onChange={handleChange} disabled={Boolean(purchaseData) || state.pais === "BO"} />
                                <div className="invalid-feedback">
                                Zip code required.
                                </div>
                            </div>
                        </div>
                        {/* <hr className="mb-4"/>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="same-address"/>
                            <label className="custom-control-label" htmlFor="same-address">Shipping address is the same as my billing address</label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="save-info"/>
                            <label className="custom-control-label" htmlFor="save-info">Save this information for next time</label>
                        </div> */}
                        <hr className="mb-4"/>
                        {!purchaseData 
                         && <AppButton type="submit" disabled={loading} title={ plan && plan.precios === 0 
                                ? 'Pruebalo Gratis' 
                                :'Siguiente'
                            } />
                        }
                    </form>
                    {purchaseData && <FormularioPago data={purchaseData} cancelAction={cancelPurchase} />}
                </div>
            </div>
            <ToastContainer position="top-center"
                theme='colored'
                autoClose={2500}
                hideProgressBar={false}
                closeOnClick={false}
                pauseOnHover={true}
                draggable={false}
            />
        </div>
        <Footer />
        </>
    );
}