import React, { useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import EventoEditar from './EventoEditar';
import EventoEliminar from './EventoEliminar';
import EventoNuevo from './EventoNuevo';
import DataTable from 'react-data-table-component';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import ModalInvitados from './ModalInvitados';
import { Link } from 'react-router-dom';
import Conexion from '../../Conexion/Conexion';
import SubEventos from './SubEventos';
import { Button } from 'react-bootstrap';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';

const { apiUrl } = Conexion();

async function cargarEventos() {
    return fetch(`${apiUrl}/api/eventos`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    })
        .then(result => result.json())
}

// async function eliminarEvento(id) {
//     return fetch(`${apiUrl}/api/eventos/${id}`, {
//         method: 'DELETE',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': sessionStorage.getItem('token')
//         }
//     })
//     .then(result => result.json())
// }


const ExpandedComponent = (props) => {
    // let clickEliminar2 = async () =>{
    //     const eliminado = await eliminarEvento(props.data.id);
    //     if(eliminado){
    //         alert("Eliminado con Exito");
    //         window.location.reload();
    //     }else{
    //         alert("Error al eliminar");
    //     }
    // };
    let clickEliminar = () => {
        props.openDelete(props.data)
    };
    let clickEditar = () => {
        props.openEdit(props.data)
    };
    let disabled = '';
    if (props.data.estado <= 1) {
        disabled = 'disabled';
    }
    return (
        <div className='container-fluid'>
            {props.data.subevento !== 1 ? <Link to={`/admin/eventos/${props.data.id}`} className={disabled + ' btn btn-primary'} >
                Ver Invitados
            </Link> : ""}
            <button className={`btn btn-${props.data.estado == 4 ? "secondary" : "warning"}`} onClick={clickEditar}>{props.data.estado == 4 ? "Habilitar" : "Editar" }</button>
            <button className="btn btn-danger" onClick={clickEliminar}>Eliminar</button>

        </div>

    )
};


const columns = [
    {
        name: 'Nombre',
        selector: row => row.nombre,
        sortable: true
    },
    {
        name: 'Fecha',
        selector: row => formatInTimeZone(row.fecha_evento, 'UTC', "dd/MM/yyyy", {
            awareOfUnicodeTokens: true
        }),
    },
    {
        name: 'Cliente',
        selector: row => row.cliente,
    },
    {
        name: 'Estado',
        selector: row => {
            switch (row.estado) {
                case 0:
                    return "Eliminado"
                case 1:
                    return "Sin Invitados"
                case 2:
                    return "Pendiente"
                case 3:
                    return "Listo"
                case 4:
                    return "Terminado"
                default:
                    return "Iniciado"
            }
        },
        sortable: true
    },
    {
        name: 'Accion',
        selector: row => {
            switch (row.estado) {
                case 1:
                    if (row.subevento === 1 || row.subevento==4)
                        return <Link to={'/admin/eventos/subeventos'} state={{ props: row }} className='btn btn-secondary'>SubEventos</Link>
                    else return <ModalInvitados IdEvento={row.id} TipoEvento={row.subevento} />
                case 2:
                    if (row.subevento === 1 || row.subevento==4)
                        return <button className='btn btn-secondary'>SubEventos</button>
                    else return ""
                default:
                    if (row.subevento === 1 || row.subevento==4)
                        return <Link to={'/admin/eventos/subeventos'} state={{ props: row }} className='btn btn-secondary'>SubEventos</Link>
                    else return ""
            }
        },
    },
];
const inputStyle = {
    height: 32,
    width: 200,
    borderRadius: 3,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    border: '1px solid #e5e5e5',
    padding: '0 32 0 16'
}
const buttonStyle = {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    height: 34,
    width: 32,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}
const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <input
            id="search"
            type="text"
            placeholder="Buscar por nombre"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
            style={inputStyle}
        />
        <Button type="button" onClick={onClear} style={buttonStyle}>
            X
        </Button>
    </>
);

const DataEventos = (props) => {
    const actionsMemo = React.useMemo(() => <EventoNuevo />, []);

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = props.data.filter(
        item => item.nombre && item.nombre.toLowerCase().includes(filterText.toLowerCase()),
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    return <DataTable
        columns={columns}
        data={filteredItems}//props.data
        title="Eventos"
        expandableRows
        actions={actionsMemo}
        expandOnRowClicked
        expandableRowsComponent={ExpandedComponent}
        expandableRowsComponentProps={{ openEdit: props.openEdit, openDelete: props.openDelete }}
        pagination

        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead />
};
const Eventos = () => {
    const [state, setState] = useState({
        data: [],
        fetched: false
    })
    const [editform, setEditform] = useState({
        open: false,
        eventData: null
    })

    const [deleteform, setDeleteform] = useState({
        open: false,
        eventData: null
    })

    const cargarDatos = async () => {
        const eventos = await cargarEventos();
        if (eventos) setState({ data: eventos, fetched: true });
    }

    const openEditModal = (evento) => {
        setEditform({
            open: true,
            eventData: evento,
        })
    }

    const changeEditModal = (evento) => {
        setEditform({
            open: true,
            eventData: evento
        })
    }

    const submitEditModal = (evento) => {
        setState({
            data: [],
            fetched: false
        })
    }

    const closeEditModal = () => {
        setEditform({
            open: false,
            eventData: null
        })
        setState({
            data: state.data,
            fetched: false
        })
    }
    //ELIMINAR
    const openDeleteModal = (evento) => {
        setDeleteform({
            open: true,
            eventData: evento,
        })
    }

    const changeDeleteModal = (evento) => {
        setDeleteform({
            open: true,
            eventData: evento
        })
    }

    const submitDeleteModal = (evento) => {
        setState({
            data: [],
            fetched: false
        })
    }

    const closeDeleteModal = () => {
        setDeleteform({
            open: false,
            eventData: null
        })
    }


    if (!state.fetched) {
        cargarDatos();
    }

    return (
        <>
            <Sidebar eventos='active' />
            <div className='container overflow-auto' style={{ height: "100vh",background:"#c5edbb" }}>
                {state.fetched ? <DataEventos data={state.data} openEdit={openEditModal} openDelete={openDeleteModal} /> : <h3>Loading...</h3>}
            </div>
            <EventoEditar open={editform.open} data={editform.eventData} close={closeEditModal} change={changeEditModal} submit={submitEditModal} />
            <EventoEliminar open={deleteform.open} data={deleteform.eventData} close={closeDeleteModal} change={changeDeleteModal} submit={submitDeleteModal} />
        </>
    )
}


export default Eventos;