import React from 'react';
import ReactModal from 'react-modal';
import RolForm from './RolForm';
import "react-datepicker/dist/react-datepicker.css";

ReactModal.setAppElement('#root');

class RolNuevo extends React.Component {
    constructor (props) {
      super(props);
      this.state = {
        showModal: false,
        idPadre: props.idPadre
      };
      
      this.handleOpenModal = this.handleOpenModal.bind(this);
      this.handleCloseModal = this.handleCloseModal.bind(this);
    }
    
    handleOpenModal () {
      this.setState({ showModal: true });
    }
    
    handleCloseModal () {
      this.setState({ showModal: false });
    }
    
    
    render () {
      return (
        <div>
          <button className="btn btn-success" onClick={this.handleOpenModal}>Crear Rol</button>
          <ReactModal
             isOpen={this.state.showModal}
             contentLabel="Nuevo CLiente"
             htmlOpenClassName="col-md-6"
          >
            <button className="btn btn-danger" onClick={this.handleCloseModal}>Cerrar</button>
            <RolForm idPadre={this.state.idPadre} />
          </ReactModal>
        </div>
      );
    }
  }

  export default RolNuevo;