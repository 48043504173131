import React, { useState } from 'react';
import Sidebar from '../../Sidebar/Sidebar';
import DataTable from 'react-data-table-component';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { Modal, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import QRCode from 'qrcode';
import MD5 from 'md5';
import Spinner from 'react-bootstrap/Spinner';
import { saveAs } from 'file-saver';
import {
  pdf,
  Page,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  Text,
} from '@react-pdf/renderer';
import Conexion from '../../../Conexion/Conexion';
import { toast, ToastContainer } from 'react-toastify';
import { downloadCSV } from './exportCSV';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { apiUrl } = Conexion();
var zip = require('jszip')();

async function cargarInvitados(eventoId) {
  return fetch(`${apiUrl}/api/invitados/evento/${eventoId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('token'),
    },
  }).then((r) => {
    if (r.status > 399) {
      if (r.status === 401) {
        alert('Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente');
        sessionStorage.clear();
        window.location.replace(window.location.origin);
        console.log(r.status);
      }
      console.log('Error', 'Could not reach server');
    }
    return r.json();
  });
}

async function actualizarInvitado(invitadoId, datos) {
  console.log('fetchs', invitadoId);
  return fetch(`${apiUrl}/api/invitados/${invitadoId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('token'),
    },
    body: JSON.stringify(datos),
  }).then((r) => {
    if (r.status > 399) {
      if (r.status === 401) {
        alert('Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente');
        sessionStorage.clear();
        window.location.replace(window.location.origin);
      }
      console.log(r.status);
      console.log('Error', 'Could not reach server');
    }
    console.log(r);
    return r.json();
  });
}
async function actualizarEvento(eventoId, datos) {
  return fetch(`${apiUrl}/api/eventos/estado/${eventoId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('token'),
    },
    body: JSON.stringify(datos),
  }).then((r) => {
    if (r.status > 399) {
      if (r.status === 401) {
        alert('Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente');
        sessionStorage.clear();
        window.location.replace(window.location.origin);
        console.log(r.status);
      }
      console.log('Error', 'Could not reach server');
    }
    return r.json();
  });
}

async function traerEvento(eventoId) {
  return fetch(`${apiUrl}/api/eventos/${eventoId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('token'),
    },
  }).then((r) => {
    if (r.status > 399) {
      if (r.status === 401) {
        alert('Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente');
        sessionStorage.clear();
        window.location.replace(window.location.origin);
        console.log(r.status);
      }
      console.log('Error', 'Could not reach server');
    }
    return r.json();
  });
}

async function GenerarInvitadoUso(id_invitado, datos) {
  return fetch(
    `${apiUrl}/api/invitados/evento/admin-generar-uso/${id_invitado}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
      },
      body: JSON.stringify(datos),
    },
  ).then((r) => {
    if (r.status > 399) {
      if (r.status === 401) {
        alert('Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente');
        sessionStorage.clear();
        window.location.replace(window.location.origin);
        console.log(r.status);
      }
      if (r.status === 403) {
        window.location.replace(window.location.origin + '/app');
      }
      console.log('Error', 'Could not reach server', r);
    }
    return r.json();
  });
}

async function AumentarTickets(id_invitado, datos) {
  return fetch(
    `${apiUrl}/api/eventos/aumentar-tickets/${id_invitado}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
      },
      body: JSON.stringify(datos),
    },
  ).then((r) => {
    if (r.status > 399) {
      if (r.status === 401) {
        alert('Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente');
        sessionStorage.clear();
        window.location.replace(window.location.origin);
        console.log(r.status);
      }
      if (r.status === 403) {
        window.location.replace(window.location.origin + '/app');
      }
      console.log('Error', 'Could not reach server', r);
    }
    return r.json();
  });
}

export default function Invitados() {
  let navigate = useNavigate();
  const { eventoId } = useParams();
  const [state, setState] = useState({
    datos: [],
    dEvento: [],
    showBtnQr: 'none',
    showBtnPdf: 'none',
    isFetched: false,
  });
  const [modal, setModal] = useState({
    show: false,
    datos: {},
  });
  const [modalAumentarTkt, setModalAumentarTkt] = useState({
    show: false,
    datos: {},
  });
  const [generatezip, setGeneratezip] = useState(false);
  const cargarDatos = async () => {
    let invitados = await cargarInvitados(eventoId);
    if (invitados) {
      invitados.datos.forEach(async (element) => {
        if (element['qr'] !== '') {
          const qr = await QRCode.toDataURL(element['qr']);
          element['qrcode'] = qr;
        } else {
          element['qrcode'] = '';
        }
      });
      let evento = await traerEvento(eventoId);
      if (evento) {
        setState({
          datos: invitados.datos,
          dEvento: evento,
          showBtnQr: evento.estado === 2 ? 'inline-block' : 'none',
          showBtnPdf: evento.estado >= 3 ? 'inline-block' : 'none',
          isFetched: true,
        });
      }
    }
  };

  if (!state.isFetched) {
    cargarDatos();
  }
  // Register font on PDF
  Font.register({
    family: 'Montserrat',
    src: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap',
  });
  const styles = StyleSheet.create({
    page: { margin: 0 },
    section: {
      backgroundColor: '#1b1227',
      margin: 0,
      position: 'fixed',
      top: '0px',
    },
    section2: {
      backgroundColor: '#5b5257',
      margin: 0,
      position: 'absolute',
      top: '760px',
      left: '239px',
    },
    section3: {
      backgroundColor: '#5b5257',
      margin: 0,
      position: 'relative',
      top: '0px',
    },
    fondo_img: { width: '720px', height: '1280px' },
    qr_img: { width: '240px' },
    text_page_number: {
      fontSize: '40px',
      width: '50px',
      left: '15px',
      color: 'black',
      fontStyle: 'bold',
      paddingVertical: '6px',
      position: 'absolute',
      bottom: '1230px',
      backgroundColor: 'rgba(18,28,38,0)',
    },
  });

  const views = state.datos.map((d, i) => {
    return (
      <Document>
        <Page key={i} size={[720]} style={styles.page}>
          <View style={styles.section}>
            <Image
              src={`${apiUrl}/api/eventos/imagen/${state.dEvento.fondo_qr}`}
              style={styles.fondo_img}
            />
          </View>
          <View style={styles.section3}>
            <Text style={styles.text_page_number}>{d.nro_invitado}</Text>
          </View>
          <View style={styles.section2}>
            <Image style={styles.qr_img} src={d.qrcode} />
          </View>
        </Page>
      </Document>
    );
  });

  const viewsA = state.datos.map((d, i) => {
    //Test
    return (
      <Document>
        <Page key={i} size={[1080]} style={styles.page}>
          <View style={styles.section}>
            <Image
              src={`${apiUrl}/api/eventos/imagen/${state.dEvento.fondo_qr}`}
              style={styles.fondo_img}
            />
          </View>
          <View style={styles.section3}>
            <Text style={styles.text_page_number}>{d.nro_invitado}</Text>
          </View>
          <View style={styles.section2}>
            <Image style={styles.qr_img} src={d.qrcode} />
          </View>
          <View style={styles.section}>
            <Image
              src={`${apiUrl}/api/eventos/imagen/${state.dEvento.fondo_qr}`}
              style={styles.fondo_img}
            />
          </View>
          <View style={styles.section3}>
            <Text style={styles.text_page_number}>{d.nro_invitado}</Text>
          </View>
          <View style={styles.section2}>
            <Image style={styles.qr_img} src={d.qrcode} />
          </View>
        </Page>
      </Document>
    );
  });

  const BlobPDFZip = () => {
    setGeneratezip(true);
    var i = 0;
    for (i; i < views.length; i++) {
      var blobPDF = pdf(views[i]).toBlob();
      zip
        .folder(state.dEvento.nombre)
        .file(state.dEvento.nombre + ' ' + (i + 1) + '.pdf', blobPDF);
    }

    // once you finish adding all the pdf to the zip, return the zip file

    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, state.dEvento.nombre + '.zip');
      zip.remove(state.dEvento.nombre);
      setGeneratezip(false);
    });
  };

  const generarQR = async (array) => {
    setGeneratezip(true);
    let count = 0;
    await Promise.all(
      array.map(async (element) => {
        const md5 = MD5(
          element['id'] + element['nombre'] + element['id_evento'],
        );
        let qr = md5;
        const datosInvitado = {
          id: element['id'],
          qr: qr,
          estado: element['estado'],
        };
        const Invitado = await actualizarInvitado(
          datosInvitado['id'],
          datosInvitado,
        );
        if (Invitado) {
          count++;
        } else {
          alert('Error al generar QR');
          return false;
        }
      }),
    );
    const datosEvento = {
      id: eventoId,
      estado: 3,
    };
    const Evento = await actualizarEvento(datosEvento['id'], datosEvento);
    if (Evento) {
      setGeneratezip(false);
      alert('OK: ' + count + ' qr generados');
      window.location.reload(false);
    } else {
      alert('Error al actualizar Evento');
    }
  };

  const handleClose = (e) => {
    if (e != undefined) e.preventDefault();
    setModal({
      show: false,
      datos: {},
    });
  };
  const handleShow = (id_invitado, nombre) =>
    setModal({
      show: true,
      datos: {
        id_invitado: id_invitado,
        nombre: nombre,
        id_usuario: state.dEvento.id_cliente,
      },
    });

  const handleShowAumentarTkt = () => {
    setModalAumentarTkt({
      show: true,
      datos: {
        eventoId,
      },
    });
  }
    
  const handleCloseAumentarTkt = (e) => {
    if (e != undefined) e.preventDefault();
    setModalAumentarTkt({
      show: false,
      datos: {},
    });
  };

  const handleGenerarPorUsos = async (id_invitado, nro_usos) => {
    const datos = {
      user: state.dEvento.id_cliente,
      usos: nro_usos,
    };
    let ticket = await GenerarInvitadoUso(id_invitado, datos);
    if (ticket.ok) {
      toast.success(ticket.mensaje, {
        theme: 'colored',
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    } else {
      toast.warn(ticket.mensaje, {
        theme: 'colored',
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    setState({
      ...state,
      isFetched: false,
    });
  };

  const aumentatTkts = async (id_evento, cantidad) => {
    const datos = { cantidad };
    let ticket = await AumentarTickets(id_evento, datos);
    if (ticket.ok) {
      toast.success(ticket.mensaje, {
        theme: 'colored',
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      setModalAumentarTkt(false)
    } else {
      toast.warn(ticket.mensaje, {
        theme: 'colored',
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    setState({
      ...state,
      isFetched: false,
    });
  };

  const GenerarQR = ({ onGenerate }) => {
    return (
      <button
        className="btn btn-sm btn-success"
        disabled={generatezip}
        style={{ display: state.showBtnQr }}
        onClick={(e) => onGenerate(e.target.value)}>
        {!generatezip ? (
          'Generar QR'
        ) : (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Cargando...</span>
          </Spinner>
        )}
      </button>
    );
  };

  const ExportCSV = ({ onExport }) => (
    <button
      onClick={(e) => onExport(e.target.value)}
      className="dropdown-item"
      type="button"
      disabled={generatezip}
      style={{ display: state.showBtnPdf }}>
      Descargar CSV
    </button>
  );

  const ExportarExcel = ({ data, nombreEvento }) => {
    return (
      <ExcelFile
        filename={`Invitados - ${nombreEvento}`}
        element={
          <button
            className="dropdown-item"
            type="button"
            disabled={generatezip}
            style={{ display: state.showBtnPdf }}>
            Descargar Excel
          </button>
        }>
        <ExcelSheet data={data} name={`Invitados - ${nombreEvento}`}>
          <ExcelColumn label="nro_invitado" value="nro_invitado" />
          <ExcelColumn label="qr" value="qr" />
        </ExcelSheet>
      </ExcelFile>
    );
  };

  const columns = [
    {
      name: 'Nro',
      selector: (row) => row.nro_invitado,
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: (row) => row.nombre,
      sortable: true,
    },
    {
      name: 'Qr',
      selector: (row) => {
        if (row.qrcode !== '')
          return <img height="100px" width="100px" alt="" src={row.qrcode} />;
        else return row.qrcode;
      },
    },
    {
      name: 'Cant. de Usos',
      selector: (row) => row.usos,
      omit: state.dEvento.subevento != 3,
    },
    {
      name: 'Generar QR',
      selector: (row) => {
        if (row.usos == null)
          return (
            <button
              className="btn btn-sm btn-primary"
              onClick={() => handleShow(row.id, row.nombre)}>
              Generar QR
            </button>
          );
        else return '';
      },
      omit: state.dEvento.subevento != 3,
    },
  ];

  const customStyles = {
    header: {
      style: {
        overflow: 'initial',
      },
    },
};

  return (
    <>
      <Sidebar eventos="active" />
      <div
        className="container overflow-auto"
        style={{ background: '#c9bbed' }}>
        <DataTable
          columns={columns}
          data={state.datos}
          title={state.dEvento.nombre}
          customStyles={customStyles}
          actions={
            <div>
              <GenerarQR
                onGenerate={() => {
                  generarQR(state.datos);
                }}
              />
              <ButtonToolbar>
                <ButtonGroup className="mx-2">
                  <div className="dropdown">
                    <button
                      className="btn btn-primary dropdown-toggle"
                      type="button"
                      id="dropdownMenu2"
                      disabled={generatezip}
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      {!generatezip ? (
                        'Exportar'
                      ) : (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden btn btn-sm">
                            Cargando...
                          </span>
                        </Spinner>
                      )}
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenu2">
                      <li>
                        <button
                          onClick={BlobPDFZip}
                          className="dropdown-item"
                          type="button"
                          disabled={generatezip}
                          style={{
                            display:
                              state.dEvento.subevento != 3
                                ? state.showBtnPdf
                                : 'none',
                          }}>
                          Descargar PDF
                        </button>
                      </li>
                      <li>
                        <ExportCSV
                          onExport={() =>
                            downloadCSV(state.datos, state.dEvento.nombre)
                          }
                        />
                      </li>
                      <li>
                        <ExportarExcel
                          data={state.datos}
                          nombreEvento={state.dEvento.nombre}
                        />
                      </li>
                    </ul>
                  </div>
                </ButtonGroup>
                <ButtonGroup className="mx-2">
                  <button
                    onClick={handleShowAumentarTkt}
                    className="btn btn-sm btn-warning"
                    disabled={generatezip}
                    style={{ display: state.showBtnPdf }}>
                    Aumentar Invitados
                  </button>
                </ButtonGroup>
                <ButtonGroup className="mx-2">
                  <button
                    onClick={() => navigate('./ingresos')}
                    className="btn btn-sm btn-success"
                    disabled={generatezip}
                    style={{ display: state.showBtnPdf }}>
                    Ver Ingresos
                  </button>
                </ButtonGroup>
              </ButtonToolbar>
            </div>
          }
          pagination
        />
        <ModalAumentarTkt
          show={modalAumentarTkt.show}
          close={handleCloseAumentarTkt}
          datos={modalAumentarTkt.datos}
          aumentatTkts={aumentatTkts}
        />
        <ModalGenerar
          show={modal.show}
          close={handleClose}
          datos={modal.datos}
          confirmar={handleGenerarPorUsos}
        />
        <ToastContainer />
      </div>
    </>
  );
}

function ModalAumentarTkt({ show, close, aumentatTkts, datos }) {
  const [cantidad, setCantidad] = useState(1);
  const [aumentarTxt, setAumentarTxt] = useState("");
  const handleChange = (e) => {
    setCantidad(Number(e.target.value));
  };
  const handleChangeTxt = (e) => {
    setAumentarTxt(e.target.value);
  };
  const handleConfirmar = (e) => {
    e.preventDefault();
    if(aumentarTxt === "Aumentar Tickets"){
      aumentatTkts(datos.eventoId, cantidad);
      close();
    }else{
      alert('Escribe la frase de confirmación correctamente e intentalo de nuevo');
    }
    
  };
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>Aumentar Tickets</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>
          <Row className="justify-content-md-left">
            <Col xs={4} className="mb-3">
              <label className="form-label">Cantidad:</label>
              <input
                type="number"
                max={1000}
                min={1}
                className="form-control app-form-control"
                id="cantidad"
                value={cantidad}
                required
                placeholder="Ingrese la cantidad"
                onChange={handleChange}
                style={{ textAlign: 'right' }}
              />
            </Col>
            <Col xs={12} className="mb-1">
              <label className="form-label">Escriba la frase <strong style={{color: "red"}} >Aumentar Tickets</strong> para comfirmar la acción:</label>
              <input
                type="text"
                className="form-control app-form-control"
                id="aumentarTxt"
                value={aumentarTxt}
                required
                placeholder="Aumentar Tickets"
                onChange={handleChangeTxt}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="app-button"
            style={{ width: '40%', margin: '0 10 0 10', height: 42 }}
            onClick={handleConfirmar}>
            Generar
          </button>
          <button
            className="btn-logout"
            style={{ width: '40%', margin: '0 10 0 10', height: 42 }}
            onClick={close}>
            Cancelar
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

function ModalGenerar(props) {
  const [cantidad, setCantidad] = useState(1);
  const handleChange = (e) => {
    setCantidad(Number(e.target.value));
  };
  const handleConfirmar = (e) => {
    e.preventDefault();
    props.confirmar(props.datos.id_invitado, cantidad);
    props.close();
  };

  return (
    <Modal
      show={props.show}
      onHide={props.close}
      backdrop="static"
      keyboard={false}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>Generar Ticket - {props.datos.nombre}</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>
          <Row className="justify-content-md-center">
            <Col xs={4} className="mb-1">
              <label className="form-label">Cantidad de Usos</label>
              <input
                type="number"
                max={1000}
                min={0}
                className="form-control app-form-control"
                id="cantidad"
                value={cantidad}
                required
                placeholder="Cantidad de Usos"
                onChange={handleChange}
                style={{ textAlign: 'right' }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="app-button"
            style={{ width: '40%', margin: '0 10 0 10', height: 42 }}
            onClick={handleConfirmar}>
            Generar
          </button>
          <button
            className="btn-logout"
            style={{ width: '40%', margin: '0 10 0 10', height: 42 }}
            onClick={props.close}>
            Cancelar
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
