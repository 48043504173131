import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { Helmet } from "react-helmet-async";
import {QrCodeScan, ChevronLeft, ListCheck, Ticket} from 'react-bootstrap-icons';
import { LinkButton, EventLinkButton } from '../components/AppButton';
import AppLogo from '../components/AppLogo';
import Conexion from "../../Conexion/Conexion";
import useToken from '../../useToken/useToken';
const {apiUrl} = Conexion();

async function datosEvento(eventoId) {
  return fetch(`${apiUrl}/api/eventos/${eventoId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('token')
    }
  }).then(r =>{
    if (r.status > 399) {
      if(r.status === 401){
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
      }
      if(r.status === 403){
        window.location.replace(window.location.origin+"/app");
      }
      console.log("Error","Could not reach server");
    }
    return r.json()
  })
    
}

function Menu() {

    const {eventoId} = useParams();
    const [evento, setEvento] = useState(null);
    const [fecha, setFecha] = useState('');
    const { user } = useToken();
    const cargarDatosEvento = async () => {
      const dEvento = await datosEvento(eventoId);
      if (dEvento) {
          let fecha_formated = formatInTimeZone(dEvento.fecha_evento,'UTC', "dd/MM/yyyy", {
            awareOfUnicodeTokens: true
          })
          setEvento(dEvento);
          setFecha(fecha_formated)
      }
    }

    useEffect(() => {
      cargarDatosEvento();
    }, []);


  return (
    <div className='container-sm'>
      <Helmet>
          <title>Evento - Glup Ticket</title>
      </Helmet>
      <div className='text-center'>
        <AppLogo />
        <div>
          <div className="d-flex flex-column align-items-center mb-4">
          { evento && <EventLinkButton evento={evento} fecha={fecha} apiUrl={apiUrl} /> }
          </div>   
          <div className="d-flex flex-column">
            { evento && 
              <>
              {evento.estado === 4 ?<h4 className='text-danger fw-bold'>EVENTO TERMINADO</h4>:""}
              {user.rol!==2 && evento.estado === 3 
                ?<LinkButton to={`/app/evento/${eventoId}/scan-qr`} className='mb-1' title={<><QrCodeScan size={24}/>&nbsp; Escanear QR</>} />
                :""}
              {user.rol!==2
                ?<LinkButton to={`/app/evento/${eventoId}/ingresos`}  className='mb-1' title={<><ListCheck size={24}/>&nbsp; Ver Ingresos</>}/>
                :""}
              {user.rol!==3 && (evento.subevento === 0 || evento.subevento === 3) && evento.estado === 3 
                ?<LinkButton to={`/app/evento/${eventoId}/generar`}  className='mb-1' title={<><Ticket size={24}/>&nbsp; Generar Tickets</>}/>
                :""}
              </>
            }
              
              <LinkButton to="/app" border className='my-4' title={<><ChevronLeft size={24}/>&nbsp; Volver</>} />
          </div> 
        </div>
      </div>
    </div>
  );
}


export default Menu;