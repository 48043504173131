import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Conexion from '../../../Conexion/Conexion';
import Sidebar from '../../Sidebar/Sidebar';
import ModalInvitados from '../../Eventos/ModalInvitados';
import { formatInTimeZone } from 'date-fns-tz';
import EventoEliminar from '../../Eventos/EventoEliminar';
const { apiUrl } = Conexion();

async function cargarEventos(idCliente) {
    return fetch(`${apiUrl}/api/eventos/cliente/admin/${idCliente}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    })
        .then(result => result.json())
}
  

  const DatosEventos = (props) => {

    //const actionsMemo = React.useMemo(() => <RolNuevo />, []);

    /*const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = props.data.filter(
        item => item.nombre && item.nombre.toLowerCase().includes(filterText.toLowerCase()),
    );*/

    /*const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);*/
  
    return <DataTable
      columns={columns}
      data={props.data}//filteredItems
      title="Eventos"
      expandableRows
      //actions={actionsMemo}
      expandOnRowClicked
      expandableRowsComponent={ExpandedComponent}
      //expandableRowsComponentProps={{ openEdit: props.openEdit }}
      pagination
  
      //subHeader
      //subHeaderComponent={subHeaderComponentMemo}
      persistTableHead />;
  };

  const ExpandedComponent = (props) => {
    let clickEliminar = () => {
        props.openDelete(props.data)
    };
    let clickEditar = () => {
        props.openEdit(props.data)
    };
    let disabled = '';
    if (props.data.estado <= 1) {
        disabled = 'disabled';
    }
    return (
        <div className='container-fluid'>
            {props.data.subevento !== 1 ? <Link to={`/admin/eventos/${props.data.id}`} className={disabled + ' btn btn-primary'} >
                Ver Invitados
            </Link> : ""}

        </div>

    )
};

  const columns = [
    {
        name: 'Nombre',
        selector: row => row.nombre,
        sortable: true
    },
    {
        name: 'Fecha',
        selector: row => formatInTimeZone(row.fecha_evento, 'UTC', "dd/MM/yyyy", {
            awareOfUnicodeTokens: true
        }),
        sortable: true
    },
    {
        name: 'Estado',
        selector: row => {
            switch (row.estado) {
                case 0:
                    return "Eliminado"
                case 1:
                    return "Sin Invitados"
                case 2:
                    return "Pendiente"
                case 3:
                    return "Listo"
                case 4:
                    return "Terminado"
                default:
                    return "Iniciado"
            }
        },
        sortable: true
    },
    {
        name: 'Accion',
        selector: row => {
            switch (row.estado) {
                case 1:
                    if (row.subevento === 1)
                        return <Link to={'/admin/eventos/subeventos'} state={{ props: row }} className='btn btn-secondary'>SubEventos</Link>
                    else return <ModalInvitados IdEvento={row.id} />
                case 2:
                    if (row.subevento === 1)
                        return <button className='btn btn-secondary'>SubEventos</button>
                    else return ""
                default:
                    if (row.subevento === 1)
                        return <Link to={'/admin/eventos/subeventos'} state={{ props: row }} className='btn btn-secondary'>SubEventos</Link>
                    else return ""
            }
        },
    },
];
/*
  const columns = [{
    name: 'Nombre',
    selector: row => row.nombre + ' ' + row.apellido_p + ' ' + row.apellido_m
  },
  {
    name: 'Correo',
    selector: row => row.correo
  },
  {
    name: 'Contraseña',
    selector: row => row.password
  },
  {
    name: 'Telefono',
    selector: row => row.telefono,
  },
  {
    name: 'Rol',
    selector: row => row.descripcion,
  },
  {
    name: 'Estado',
    selector: row => {
      switch (row.estado) {
        case 1:
          return "Activo"
        case 2:
          return "Inactivo"
        default:
          return ""
      }
    },
    sortable: true
  }
  ];
*/

const ClienteEventos = () => {
    const { clienteId } = useParams();
    const [state, setState] = useState({
        datos:[],
        isfetched:false
    });
    const [deleteform, setDeleteform] = useState({
      open: false,
      eventData: null
  })
    const cargarDatos= async ()=>{
        let eventos= await cargarEventos(clienteId);
        if(eventos){
            setState({
                datos:eventos,
                isfetched:true
            })
        }
    }

    //ELIMINAR
    const openDeleteModal = (evento) => {
      setDeleteform({
          open: true,
          eventData: evento,
      })
  }

  const changeDeleteModal = (evento) => {
      setDeleteform({
          open: true,
          eventData: evento
      })
  }

  const submitDeleteModal = (evento) => {
      setState({
          data: [],
          fetched: false
      })
  }

  const closeDeleteModal = () => {
      setDeleteform({
          open: false,
          eventData: null
      })
  }

    if(state.isfetched===false){
        cargarDatos();
    }

    return ( <>
        <Sidebar clientes='active' />
        <div className='container overflow-auto' style={{height:"100vh",background:"#c5edbb" }}>
            <DatosEventos data={state.datos} openDelete={openDeleteModal} />
        </div>
        <EventoEliminar open={deleteform.open} data={deleteform.eventData} close={closeDeleteModal} change={changeDeleteModal} submit={submitDeleteModal} /></>
    );
}
export default ClienteEventos;