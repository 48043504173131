import React from 'react';
import ReactModal from 'react-modal';
import EventoForm from './EventoForm';
import SubEventoForm from './SubEventoForm';



class SubEventoNuevo extends React.Component {
    constructor (props) {
      super(props);
      this.state = {
        showModal: false,
        nombre:props.datos.nombre,
        eventoPadre:props.datos
      };
      
      this.handleOpenModal = this.handleOpenModal.bind(this);
      this.handleCloseModal = this.handleCloseModal.bind(this);
    }
    
    handleOpenModal () {
      this.setState({ showModal: true });
    }
    
    handleCloseModal () {
      this.setState({ showModal: false });
    }
    
    
    render () {
      return (
        <div>
          <button className="btn btn-success" onClick={this.handleOpenModal}>Nuevo SubEvento</button>
          <ReactModal
             isOpen={this.state.showModal}
             contentLabel="Nuevo Evento"
             htmlOpenClassName="col-md-6"
          >
            <button className="btn btn-danger" onClick={this.handleCloseModal}>Cerrar</button>
            <SubEventoForm cliente={this.state.eventoPadre}/>
          </ReactModal>
        </div>
      );
    }
  }

  export default SubEventoNuevo;