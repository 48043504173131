import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { PieChart, Pie, Sector, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Conexion from '../../../Conexion/Conexion';

const { apiUrl } = Conexion();

async function obtenerUsuarios() {
  return fetch(`${apiUrl}/api/dashboard/eventos-por-ciudad`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('token')
      }
  })
      .then(result => result.json())
}

 const COLORS = ['#FF6633', '#6680B3', '#E64D66', '#1AB399', '#E6B333',  '#FF33FF', '#3366E6', '#999966', '#99FF99', '#B34D4D', '#FFFF99','#80B300', '#809900', '#E6B3B3', '#66991A', '#FFB399','#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC','#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#00B3E6', '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933','#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

const EventosPorCiudad = (props) => {
  
    const [state, setState] = useState([{name:'Nada', value:100}]);


    const cargarDatos = async () => {
        const datos = await obtenerUsuarios();
        if (datos.ok) {
            let _datos = _.map(datos.data, (d) =>{
                return {name:d.ciudad,
                value: Number(d.valor)}
            })
            setState(_datos);
        }
    }

    useEffect(() => {
        cargarDatos();
    }, []);
   

  return (
    <Card {...props}>
      <CardHeader title="Eventos por Ciudad" 
      subheader="Eventos de hace 30 dias y eventos activos"/>
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative'
          }}
        >
         <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
                <Pie
                    data={state}
                    cx="50%"
                    cy="50%"
                    outerRadius={90}
                    fill="#8884d8"
                    label
                >
                    {state.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
        </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};


export default EventosPorCiudad;