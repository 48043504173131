import React, { useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import EventoEditar from './EventoEditar';
import EventoEliminar from './EventoEliminar';
import EventoNuevo from './EventoNuevo';
import DataTable from 'react-data-table-component';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import ModalInvitados from './ModalInvitados';
import {Link, useLocation} from 'react-router-dom';
import Conexion from '../../Conexion/Conexion';
import SubEventoNuevo from './SubEventoNuevo';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';

const {apiUrl} = Conexion();

async function cargarEventos(eventoId) {
    return fetch(`${apiUrl}/api/eventos/subevento/${eventoId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    })
    .then(result => result.json())
}

// async function eliminarEvento(id) {
//     return fetch(`${apiUrl}/api/eventos/${id}`, {
//         method: 'DELETE',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': sessionStorage.getItem('token')
//         }
//     })
//     .then(result => result.json())
// }


const ExpandedComponent = (props) =>{
    // let clickEliminar2 = async () =>{
    //     const eliminado = await eliminarEvento(props.data.id);
    //     if(eliminado){
    //         alert("Eliminado con Exito");
    //         window.location.reload();
    //     }else{
    //         alert("Error al eliminar");
    //     }
    // };
    let clickEliminar = () =>{
        props.openDelete(props.data)
    };
    let clickEditar = () =>{
        props.openEdit(props.data)
    };
    let disabled = '';
    if(props.data.estado <= 1) {
        disabled = 'disabled';
    }
    return(
                    <div className='container-fluid'>
                {props.data.subevento==1?"":<Link to={`/admin/eventos/${props.data.id}`} className={disabled+' btn btn-primary'} >
                    Ver Invitados
                </Link>}
                <button className="btn btn-secondary" onClick={clickEditar}>Editar</button>
                <button className="btn btn-danger" onClick={clickEliminar}>Eliminar</button>

            </div>
        
)};


const columns = [
    {
        name: 'Nombre',
        selector: row => row.nombre,
        sortable: true
    },
    {
        name: 'Fecha',
        selector: row => formatInTimeZone(row.fecha_evento,'UTC', "dd/MM/yyyy", {
            awareOfUnicodeTokens: true
        }),
        sortable: true
    },
    {
        name: 'Cliente',
        selector: row => row.cliente,
    },
    {
        name: 'Estado',
        selector: row => {switch (row.estado) {
            case 0:
                return "Eliminado"
            case 1:
                return "Sin Invitados"
            case 2:
                return "Pendiente"
            case 3:
                return "Listo"
            case 4:
                return "Terminado"
            default:
                return "Iniciado"
        }},
        sortable: true
    },
    {
        name: 'Accion',
        selector: row => {switch (row.estado) {
            case 1:
                    if (row.subevento === 1)
                        return <Link to={'/admin/eventos/subeventos/sector'} state={{ props: row }} className='btn btn-secondary'>SubEventos</Link>
                    else return <ModalInvitados IdEvento={row.id} TipoEvento={row.subevento} />
                case 2:
                    if (row.subevento === 1)
                        return <button className='btn btn-secondary'>SubEventos</button>
                    else return ""
                default:
                    if (row.subevento === 1)
                        return <Link to={'/admin/eventos/subeventos/sector'} state={{ props: row }} className='btn btn-secondary'>SubEventos</Link>
                    else return ""
        }},
    },
];

const DataEventos = (props) => {
    const actionsMemo = React.useMemo(() => <SubEventoNuevo datos={props.cliente}/>, []);
    console.log(props);
    return <DataTable
        columns={columns}
        data={props.data}
        title={props.cliente.nombre}
        expandableRows
        actions={!props.data[0] ? actionsMemo :props.data[0].origen==2? "":actionsMemo}
        expandOnRowClicked
        expandableRowsComponent={ExpandedComponent}
        expandableRowsComponentProps={{openEdit: props.openEdit,openDelete: props.openDelete}}
        pagination />
};
const SubEventos = () => {
    const location=useLocation();
    const {props}=location.state;
    const [state, setState]= useState({
        data:[],
        fetched: false
    })
    const [editform, setEditform] = useState({
        open: false,
        eventData: null
    })

    const [deleteform, setDeleteform] = useState({
        open: false,
        eventData: null
    })

    const cargarDatos = async ()=>{
        const eventos = await cargarEventos(props.id);
        if(eventos) setState({data: eventos,fetched: true});
    }

    const openEditModal = (evento)=>{
        setEditform({
            open: true,
            eventData: evento,
        })
    }

    const changeEditModal = (evento) =>{
        setEditform({
            open: true,
            eventData: evento
        })
    }

    const submitEditModal = (evento) =>{
        setState({
            data:[],
            fetched: false
        })
    }

    const closeEditModal = ()=>{
        setEditform({
            open: false,
            eventData: null
        })
    }
    //ELIMINAR
    const openDeleteModal = (evento)=>{
        setDeleteform({
            open: true,
            eventData: evento,
        })
    }

    const changeDeleteModal = (evento) =>{
        setDeleteform({
            open: true,
            eventData: evento
        })
    }

    const submitDeleteModal = (evento) =>{
        setState({
            data:[],
            fetched: false
        })
    }

    const closeDeleteModal = ()=>{
        setDeleteform({
            open: false,
            eventData: null
        })
    }
    

    if(!state.fetched){
        cargarDatos();
    }

    return (
        <>
            <Sidebar eventos='active' />
            <div className='container overflow-auto' style={{background:"#e8edbb"}}>
                {state.fetched ?<DataEventos data={state.data} cliente={props} openEdit={openEditModal} openDelete={openDeleteModal}/>: <h3>Loading...</h3>}
            </div>
            <EventoEditar open={editform.open} data={editform.eventData} close={closeEditModal} change={changeEditModal} submit={submitEditModal}/>
            <EventoEliminar open={deleteform.open} data={deleteform.eventData} close={closeDeleteModal} change={changeDeleteModal} submit={submitDeleteModal}/>        
        </>
    )
}


export default SubEventos;