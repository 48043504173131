import React from "react";
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../../assets/images/imagen_guia.png";
import ElPDF from "../../../assets/docs/Porqué ocupar glup Tickets en tus eventos.pdf";
import Conexion from "../../Conexion/Conexion";

const { apiUrl } = Conexion();

async function guardarCorreo(datos) {
  return fetch(`${apiUrl}/api/usuarios/correo`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(datos)
  })
    .then(r => {
      if (r.status > 399) {
        if (r.status === 401) {
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
        }
        console.log("Error", "Could not reach server");
      }
      return r.json()
    })
}

function isEmail(emailAdress){
  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

if (emailAdress.match(regex)) 
  return true; 

 else 
  return false; 
}

export default function Contact() {

  const [email, setEmail] = React.useState('');
  const hiddenFileInput1 = React.useRef(null);

  const handleChangeEmail = (e)=>{
    setEmail(e.target.value);
  }
  const handleSubmit = async (e) =>{
    e.preventDefault();
    if(isEmail(email)){
      let sent = await guardarCorreo({correo: email})
      if(sent.ok){
        //alert("Subscripcion exitosa");
        hiddenFileInput1.current.click();
        setEmail('')
      }
    }else{
      alert("Ingrese un correo electrónico válido")
    }
  }

  return (
    <Wrapper id="contact" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <Fade left>
          <div>
            <h1 className="extraBold font40">¿Quieres saber más?</h1>
            <HeaderP className="font13 semiBold">
              Descarga nuestra guía para saber porqué ocupar Tickets digitales para tus eventos!
            </HeaderP>
            <form onSubmit={handleSubmit}>
              <label htmlFor="email">Email: </label><br/>
              <Input name="email" className="mb-2 radius6 font17" value={email} onChange={handleChangeEmail} type={"email"} required/>
              <BtnWrapper>
                <FullButton title="Descargar" />
              </BtnWrapper>
            </form>
            <StyleA ref={hiddenFileInput1} href={ElPDF} download></StyleA>
          </div>
        </Fade>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9, width:'426px'}} />
        </ImageWrapper>
      </RightSide>
    </Wrapper>
  );
}


const Input = styled.input`
  border: none;
  background-color: #eee;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
`;

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 750px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 15px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;

const StyleA = styled.a`
  color: white;
  display: none;
`;


