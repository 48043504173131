import React from 'react';
import {Routes, Route} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Login from '../Login/Login';
import Logout from '../Logout/Logout';
import Dashboard from './Dashboard/Dashboard';
import TablaEventos from './TablaEventos/TablaEventos';
import Eventos from './Eventos/Eventos';
import Clientes from './Clientes/Clientes';
import useToken from '../useToken/useToken';
import Invitados from './Eventos/Invitados/Invitados';
import Ingresos from './Eventos/Invitados/Ingresos';
import SubEventos from './Eventos/SubEventos';
import ClienteRoles from './Clientes/ClienteRoles/ClienteRoles';
import ClienteEventos from './Clientes/ClienteEventos/ClienteEventos';
import CambiarContrasena from './Perfil/CambiarContrasena';
import './Admin.css'
import Sector from './Eventos/Sector';

export default function Admin() {
  const { token, setToken,setUser, user } = useToken();
  if (!token || user.acceso !==2) {
    return <Login setToken={setToken} setUser={setUser} validar={"admin"} />
  }
  return (<>
    <Helmet>
      <title>Admin | Glup Ticket</title>
    </Helmet>
    <main className='main'>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/tabla-eventos" element={<TablaEventos />} />
        <Route path="/eventos" element={<Eventos />} />
        <Route path="/eventos/subeventos" element={<SubEventos />} />
        <Route path="/eventos/subeventos/sector" element={<Sector />} />
        <Route path="/eventos/:eventoId" element={<Invitados />} />
        <Route path="/eventos/:eventoId/ingresos" element={<Ingresos />} />
        <Route path="/clientes" element={<Clientes />} />
        <Route path="/clientes/:clienteId/roles" element={<ClienteRoles />} />
        <Route path="/clientes/:clienteId/eventos" element={<ClienteEventos />} />
        <Route path="/cambiar-contrasena" element={<CambiarContrasena />} />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="*"
          element={
            <div style={{ padding: "1rem" }}>
              <p>404 Not Found</p>
            </div>
          }
        />
      </Routes>
    </main>
  </>);

}