import React from 'react';
import { Helmet } from "react-helmet-async";
// Sections
import TopNavbar from "./Nav/TopNavbar";
import Header from "./Sections/Header";
import VideoPromo from './Sections/VideoPromo';
import Contact from './Sections/Contact';
import Features from './Sections/Features'
import Pricing from "./Sections/Pricing";
import Footer from "./Sections/Footer";
import useToken from '../useToken/useToken';



export function LandingPage() {
  const { token, user } = useToken();
  return(
    <>
      <Helmet>
        <title>Glup Ticket - Control QR para tus eventos</title>
      </Helmet>
      <TopNavbar sessionToken={token} sessionUser={user}/>
      <Header />
      <VideoPromo />
      <Features />
      <Pricing sessionToken={token} sessionUser={user}/>
      <Contact />
      <Footer />
    </>
  );
}