import React, { useState } from 'react';
import Sidebar from '../../Sidebar/Sidebar';
import DataTable from 'react-data-table-component';
import ExportIngresos from './ExportIngresos';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { useParams, useNavigate } from 'react-router-dom';
import ModalConfirmacion from './modalConfirmacion';
import Spinner from 'react-bootstrap/Spinner';
import Conexion from '../../../Conexion/Conexion';
import format from "date-fns-tz/format";
const { apiUrl } = Conexion();

async function cargarInvitados(eventoId) {
    return fetch(`${apiUrl}/api/invitados/evento/${eventoId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            if (r.status === 403) {
                window.location.replace(window.location.origin + "/admin");
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
}

async function cargarInvitadosMultiUso(eventoId) {
    return fetch(`${apiUrl}/api/invitados/multi-uso/${eventoId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            if (r.status === 403) {
                window.location.replace(window.location.origin + "/admin");
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
}

async function VerificarTipoEvento(eventoId) {
    return fetch(`${apiUrl}/api/eventos/tipo-evento/${eventoId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(r => {
        if (r.status > 399) {
            if (r.status === 401) {
                alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                sessionStorage.clear();
                window.location.replace(window.location.origin);
                console.log(r.status)
            }
            if (r.status === 403) {
                window.location.replace(window.location.origin + "/admin");
            }
            console.log("Error", "Could not reach server");
        }
        return r.json()
    })
}


export default function Ingresos() {

    const { eventoId } = useParams();
    const [deleteform, setDeleteform] = useState({
        open: false,
        type: null
    })
    const [state, setState] = useState({
        datos: [],
        reportes: [],
        total: 0,
        checked: 0,
        nombre: '',
        isfetched: false,
        tipo: 0
    })

    const cargarDatos = async () =>{
        const tipoEvento = await VerificarTipoEvento(eventoId);
        var invitados = [];
        if (tipoEvento.subevento == 3) {
            invitados = await cargarInvitadosMultiUso(eventoId); 
        }else {
            invitados = await cargarInvitados(eventoId);
        }
        var checked = 0;
        var nombre = '';
        if(invitados){
            let data = invitados.datos;
            data.forEach(element => {
                if (element.estado != 1) {

                    if(element.hora_ingreso!= null){
                        var date = new Date(element.hora_ingreso);
                        element.time_ingreso = format( date,  "HH:mm:ss");
                        element.fecha_ingreso = format( date,  "dd/MM/yyyy");
                        element.hora_ingreso = date.toLocaleString();
                    }
                    checked++;
                }
                nombre = element.nombree
            });
            setState({
                datos: data,
                reportes: invitados.reportes,
                total: data.lenght,
                checked: checked,
                nombre: nombre,
                isfetched: true,
                tipo: tipoEvento.subevento
            })
        }

    }
    
    const handleValidar=async()=>{
        setDeleteform({open:true,type:"1"})
        
    }
    const handleInvalidar=async()=>{
        setDeleteform({open:true,type:"2"}) 
    }

    const submitDeleteModal = () => {
        window.location.reload()
    }

    const closeDeleteModal = () => {
        setDeleteform({
            open: false,
            type:null
        })
    }


    if(!state.isfetched){
        cargarDatos();
    }
    let navigate = useNavigate();


    const columns = [
        {
            name: '#',
            selector: row => row.nro_invitado,
            sortable: true
        }, {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true
        }, {
            name: 'Categoria',
            selector: row => row.nombree,
            sortable: true
        }, {
            name: 'Generado por',
            selector: row => row.usuario,
            sortable: true,
        },{
            name: 'Hora de Ingreso',
            selector: row => row.hora_ingreso ? row.hora_ingreso : "--:--:--",
            sortable: true,
            omit: state.tipo == 3
        },{
            name: 'Repetido',
            selector: row => row.repetido,
            format: row => row.repetido > 0 ? row.repetido : "-" ,
            sortable: true,
            omit: state.tipo == 3
        },{
            name: 'Estado',
            selector: row => {
                if (state.tipo == 3) {
                    return (row.usados != 0 ? row.usados : "-") + "/" + (row.usos != null ? row.usos : "-");
                }else{
                    return row.estado > 1 ? "CHECK" : "NO CHECK"
                }
            },
            sortable: true,
            conditionalCellStyles: [
                {
                    when: row => {
                        if (state.tipo == 3) {
                            return row.usados > 0
                        }else{
                            return row.estado > 1
                        }
                    },
                    style: {
                        color:'#1c1c1c',
                        backgroundColor: '#b1f873',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                        justifyContent: 'center',
                    },
                },
                {
                    when: row => {
                        if (state.tipo == 3) {
                            return row.usados == 0;
                        }else{
                            return row.estado <= 1
                        }
                    },
                    style: {
                        color:'#1c1c1c',
                        backgroundColor: '#d56e6e',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                        justifyContent: 'center',
                    },
                },
            ],
        }
    ];

    return (<>
        <Sidebar eventos='active' />
        <div className='container overflow-auto' style={{background:"#c9bbed"}}>
        <DataTable
                columns={columns}
                data={state.datos}
                title={<div>
                    <button className='btn btn-sm btn-secondary' onClick={() => navigate(-1)}>Atras</button>
                    <h3>{"Ingresos " + state.nombre}</h3>
                    <h4>{"INGRESOS "+state.checked+"/"+state.datos.length}</h4>
                    </div>}
                actions={<>
                        <ButtonToolbar className="my-2" hidden={state.tipo==3}>
                            <ButtonGroup className="mx-2">
                                <ExportIngresos data={state.datos} nombre={state.nombre} reportes={state.reportes}/>
                            </ButtonGroup>
                        </ButtonToolbar>
                        <ButtonToolbar className="my-2" hidden={state.tipo==3} >
                            <ButtonGroup className="mx-2">
                                <button onClick={handleValidar} className='btn btn-sm btn-warning' style={{ display: state.showBtnPdf }}>Reiniciar tickets</button>
                                <button onClick={handleInvalidar} className='btn btn-sm btn-danger' style={{ display: state.showBtnPdf }}>Invalidar tickets</button>
                            </ButtonGroup>
                        </ButtonToolbar>
                        </>}
                pagination />
        <ModalConfirmacion open={deleteform.open} data={eventoId} type={deleteform.type} close={closeDeleteModal} submit={submitDeleteModal} />
        </div></>
    )
}