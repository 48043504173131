import React, {useState, useEffect} from 'react';
import TopNavbar from '../TopNavbar/TopNavbar';
import Footer from '../Footer/Footer';
import { Helmet } from "react-helmet-async";
import styled from 'styled-components';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CreateEvent0 } from './CreateEventoForms';
import { toast } from 'react-toastify';
import { LinkButton } from '../../App/components/AppButton';
import { QuestionCircle } from 'react-bootstrap-icons'
import Conexion from '../../Conexion/Conexion';

import QrIcon from '../../../assets/images/qr-icon.png'

import "react-datepicker/dist/react-datepicker.css";

const { apiUrl } = Conexion();


async function obtenerPlanesActivos() {
    return fetch(`${apiUrl}/api/planes/planes-usuarios/activos`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        },
    })
        .then(r => {
            if (r.status > 399) {
                if (r.status === 401) {
                    alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
                    sessionStorage.clear();
                    window.location.replace(window.location.origin);
                    console.log(r.status)
                }
                console.log("Error", "Could not reach server");
            }
            return r.json()
        })
}



export default function NuevoEventoForm({cliente}){

    const [planesUsuario, setPlanesUsuario] = useState([]);
    const [planSeleccionado, setPlanSeleccionado] = useState(null);

    const cargarDatos = async ()=> {
        let datos = await obtenerPlanesActivos();
        if(datos.ok){
            setPlanesUsuario(datos.planes_usuario)
        }else{
            toast.error(datos.mensaje)
        }
    }

    useEffect(() => {
        cargarDatos();
    }, []);

    const seleccionarPlan = (plan) =>{
        setPlanSeleccionado(plan);
    }


    return (
        <>
        <Helmet>
            <title>Crear Nuevo Evento | Glup Ticket</title>
        </Helmet>
        <TopNavbar cliente={cliente} />
        <div className="container px-4 py-5" style={{minHeight:'100vh'}}>
            { !Boolean(planSeleccionado)
                ? <ChoosePlanComponent planesUsuario={planesUsuario} seleccionarPlan={seleccionarPlan}/>
                : <CreateEventForm plan={planSeleccionado} cliente={cliente} />
            }
        </div>
        <Footer/>
        </>
    );
}



function ChoosePlanComponent({planesUsuario, seleccionarPlan}){
    return (
        <div className="container p-2">
            <h2 className="pb-2 border-bottom font40 extraBold">Seleccionar Paquete</h2>
            <div className='my-4'>
                <LinkButton to="/app/panel/precios" title={"Comprar"} /> 
            </div> 
            {Boolean(planesUsuario.length)
                ? <div className='px-md-5'>
                        <h6 className='semiBold'>Paquetes disponibles:</h6>
                        <FlexBox>
                            {planesUsuario.map((plan) =>{
                                return(
                                <FlexItem key={plan.id} className="btn d-flex justify-content-between align-items-center" onClick={()=>{seleccionarPlan(plan)}}>
                                    <div>
                                        <img src={QrIcon} alt="Icono QR" width="48" />
                                    </div>
                                    <div className="ms-2" style={{textAlign:'left', width:'200px'}}>
                                        <p className="font20 extraBold m-0">{plan.nombre}</p>
                                        <p className="m-0">Hasta {plan.cant_tickets} Tickets</p>
                                    </div>
                                    <div>
                                        <TooltipTop text={plan.descripcion}>
                                            <QuestionCircle size={24} />
                                        </TooltipTop>
                                    </div>
                                </FlexItem>)
                            })}
                        </FlexBox>
                    </div>
                : 
                <h6 className='text-center redColor semiBold mt-4'>No tiene paquetes disponibles</h6>
            }
            
        </div>
    );
}


function CreateEventForm({plan, cliente,}){
    
    return (
        <div className="container p-2">
            <h2 className="pb-2 border-bottom font40 extraBold">Crear Evento - {plan.nombre}</h2>
            <CreateEvent0 plan={plan} cliente={cliente}/>
        </div>
    );
}

function TooltipTop({text, children}) {
    return (
      <>
          <OverlayTrigger
            placement={'top'}
            overlay={
              <Tooltip id={`tooltip-top`}>
                {text}
              </Tooltip>
            }
          >
            {children}
          </OverlayTrigger>
      </>
    );
  }

const FlexBox = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    flex-wrap: wrap;
    justify-content: start;
    gap: 20px;
`;

const FlexItem = styled.div`
    min-width: 320px;
    position: relative;
    display: block;
    padding: 8px 16px;
    text-decoration: none;
    border: 0.8px solid rgb(33, 37, 41, 0.2);

`