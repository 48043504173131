import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, Button, Card, CardContent, CardHeader, Divider } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import Conexion from '../../../Conexion/Conexion';

const { apiUrl } = Conexion();

async function obtenerGanancias() {
  return fetch(`${apiUrl}/api/dashboard/ganancias-por-mes`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('token')
      }
  })
      .then(result => result.json())
}

const  months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Augosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const GananciasPorMes = (props) => {

  const [state, setState] = useState([]);


  const cargarDatos = async () => {
    const ganancias = await obtenerGanancias();
    if (ganancias.ok) {
        let _ganancias = _.map(ganancias.data, (d, i) =>{
            return {
                mes: months[d.mes-1],
                monto: Number(d.monto)
            }
        })
      setState(_ganancias);
    }
  }

  useEffect(() => {
    cargarDatos();
  }, []);

  return (
    <Card {...props}>
      <CardHeader
        title="Ingresos por Mes"
        subheader="Último año"
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: 'relative'
          }}
        >
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={state}
              margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="5 5" />
              <XAxis dataKey="mes" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="monto" name="Monto en $" stroke="#FF6666" activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default GananciasPorMes;