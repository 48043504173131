import { useEffect, useState } from 'react';
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import Conexion from '../../../Conexion/Conexion';

const { apiUrl } = Conexion();

async function obtenerDato() {
  return fetch(`${apiUrl}/api/dashboard/ganancias-totales`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('token')
      }
  })
      .then(result => result.json())
}

const GananciasTotales = (props) => {

  const [state, setState] = useState([{monto:0}]);

  const cargarDatos = async () => {
    const ganancias = await obtenerDato();
    if (ganancias.ok) {
      setState(ganancias.data);
    }
  }

  useEffect(() => {
    cargarDatos();
  }, []);


  return(
    <Card {...props}>
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="overline"
              className='semiBold'
            >
              INGRESOS TOTALES
            </Typography>
            <Typography
              color="textPrimary"
              variant="h4"
            >
              {state[0].monto || 0} $
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: 'success.main',
                height: 56,
                width: 56
              }}
            >
              <AttachMoneyOutlinedIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            pt: 2
          }}
          >
          {/* <ArrowUpwardIcon color="success" />
          <Typography
            variant="body2"
            sx={{
              mr: 1
            }}
          >
            16%
          </Typography> */}
          <Typography
            color="textSecondary"
            variant="caption"
          >
            Total histórico
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default GananciasTotales;