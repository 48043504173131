import React, { useState, useEffect } from 'react';
import Login from '../Login/Login';
import Logout from '../Logout/Logout';
import useToken from '../useToken/useToken';
import { Helmet } from "react-helmet-async";
import ListaEventos from './ListaEventos/ListaEventos';
import {Routes, Route, Navigate} from "react-router-dom";
import Menu from './Menu/Menu';
import Roles from './Roles/Roles';
import RolForm from './Roles/RolForm';
import RolEventos from './Roles/RolEventos/RolEventos';
import EscanearQr from './EscanearQR/EscanearQR';
import Ingresos from './Ingresos/Ingresos';
import Generar from "./Generar/Generar";
import Conexion from '../Conexion/Conexion';
import Panel from '../Panel/Panel'
import "./App.css";
import IngresosMulti from './Ingresos/IngresosMulti';
import ListaEventosMulti from './ListaEventos/ListaEventosMulti';
const { apiUrl } = Conexion();

async function obtenerDatosCliente(cliente_id) {
  return fetch(`${apiUrl}/api/usuarios/cliente/${cliente_id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('token')
    }
  }).then(r => {
    if (r.status > 399) {
      if (r.status === 401) {
        alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
        sessionStorage.clear();
        window.location.replace(window.location.origin);
        console.log(r.status)
      }
      console.log("Error", "Could not reach server");
    }
    return r.json()
  })
}

let ValidarRol = (props) => {
  const { user } = useToken();
  if (!props.roles.includes(user.rol)) {
    return <Navigate push to="/app"/>
  }
  return props.element
}

function App() {
  const { token, setToken, setUser, user } = useToken();
  const [state, setState] = useState({ cliente: null, isFetched: false });

  const cargarDatosCliente = async () => {
    const dCliente = await obtenerDatosCliente(user.id);
    if (dCliente) {
      setState({ cliente: dCliente, isFetched: true })
    }
  }
  useEffect(() => {
    if (user !== null) {
      cargarDatosCliente();
    }
  }, []);
  
  if(!token  || user.acceso !==3) {
    return <Login setToken={setToken} setUser={setUser} validar={"cliente"}/>
  }

  return (


    <div>
      <Helmet>
        <title>App - Glup Ticket</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<ListaEventos />} />
        <Route path="/panel/*" element={<Panel />} />
        {/* <Route path="/evento/nuevo" element={<ValidarRol roles={[1]}  element={state.cliente != null?<NuevoEvento  cliente={state.cliente} reset={resetState} />:''} />} /> */}
        <Route path="/roles" element={<ValidarRol roles={[1]}  element={<Roles />} />} />
        <Route path="/roles/nuevo" element={<ValidarRol roles={[1]}  element={<RolForm />} />} />
        <Route path="/roles/:usuarioId/eventos" element={<ValidarRol roles={[1]}  element={<RolEventos />} />} />
        {/* <Route path="/evento/nuevo/:eventoId" element={ <ValidarRol roles={[1]} element={<EventoCreado cliente={state.cliente}/>} />}/> */}
        <Route path="/evento/:eventoId"element={ <ValidarRol roles={[1,2,3]} element={<Menu />} />}/>
        <Route path="/subevento/:eventoId"element={ <ValidarRol roles={[1,2,3]} element={<ListaEventosMulti />} />}/>
        <Route path="/evento/:eventoId/scan-qr" element={<ValidarRol roles={[1,2,3]}  element={<EscanearQr />} />} />
        <Route path="/evento/:eventoId/ingresos" element={<ValidarRol roles={[1,3]}  element={<Ingresos />} />} />
        <Route path="/subevento/:eventoPadre/ingresos/:eventoId" element={<ValidarRol roles={[1,3]}  element={<IngresosMulti />} />} />
        <Route path="/evento/:eventoId/generar" element={<ValidarRol roles={[1,2]}  element={<Generar cliente={state.cliente} />} />} />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="*"
          element={
            <div style={{ padding: "1rem" }}>
              <p>404 Not Found</p>
            </div>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
