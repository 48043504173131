import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = (tokenString);
    return userToken;
  };

  const getUser = () => {
    const tokenString = sessionStorage.getItem('usr_data');
    const user_d = JSON.parse(tokenString);
    return user_d;
  };

  /*const getTipo = () => {
    const tokenString = sessionStorage.getItem('tipo');
    const tipo = JSON.parse(tokenString);
    return tipo;
  };*/

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());
  //const [tipo, setTipo] = useState(getTipo());

  const saveToken = userToken => {
    sessionStorage.setItem('token', userToken);
    setToken(userToken.token);
  };

  const saveUser = userObj => {
    let dato={
      id: userObj.id_usuario,
      acceso: userObj.id_acceso,
      rol: userObj.id_rol
    }
    sessionStorage.setItem('usr_data', JSON.stringify(dato));
    setUser(dato);
  };
  /*const saveTipo = userObj => {
    sessionStorage.setItem('tipo', JSON.stringify(userObj.id_acceso));
    setTipo(userObj.id_acceso);
  };*/

  return {
    setToken: saveToken,
    token,
    setUser: saveUser,
    user,
    //setTipo: saveTipo,
    //tipo
  }
}
