import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { es } from 'date-fns/locale';
import formatDistance from 'date-fns/formatDistance';
import { Avatar, Box, Button, Card, CardHeader, Divider, IconButton, List,ListItem,ListItemAvatar,ListItemText } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ChevronRightRounded from '@mui/icons-material/ChevronRightRounded';
import Conexion from '../../../Conexion/Conexion';

const { apiUrl } = Conexion();

async function obtenerDatos() {
  return fetch(`${apiUrl}/api/dashboard/eventos-nuevos`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('token')
      }
  })
      .then(result => result.json())
}

const EventosNuevos = (props) => {

  let navigate = useNavigate();
  const [state, setState] = useState([]);

  const cargarDatos = async () => {
    const eventos = await obtenerDatos();
    if (eventos.ok) {
      setState(eventos.data);
    }
  }

  useEffect(() => {
    cargarDatos();
  }, []);

  return(
    <Card {...props}>
      <CardHeader
        subheader={`Últimos 7 días: ${state.length}`}
        title="Eventos Nuevos"
      />
      <Divider />
      <List
          sx={{
              overflow: 'auto',
              minHeight: 400,
              maxHeight: 400,
          }}
      >
        {state.map((evento, i) => (
          <ListItem
            divider={i < state.length - 1}
            key={evento.id}
            sx={{ py: 0, minHeight: 32 }}
          >
            <ListItemAvatar>
              <Avatar variant="rounded" sx={{ bgcolor: deepOrange[500] }} alt={evento.nombre} src={`${apiUrl}/api/eventos/imagen/${evento.imagen}`} />
            </ListItemAvatar>
            <ListItemText
              primary={evento.nombre}
              secondary={'Creado hace ' + formatDistance(new Date(evento.fecha_creacion),new Date(),{locale: es}) }
            />
            {/* <IconButton
              edge="end"
              size="small"
            >
              <ChevronRightRounded />
            </IconButton> */}
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
          onClick={() => navigate('/admin/eventos')}
        >
          Ver Todos los Eventos
        </Button>
      </Box>
    </Card>
  );
}

export default EventosNuevos;