import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Helmet } from "react-helmet-async";
import { useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Container, Col, Row } from 'react-bootstrap';
import { ChevronLeft, PersonCheckFill, } from 'react-bootstrap-icons';
import { LinkButton, AppButton, EventImg } from '../../components/AppButton';
import AppLogo from '../../components/AppLogo';
import { toast, ToastContainer } from 'react-toastify';
import Conexion from "../../../Conexion/Conexion";
import 'react-toastify/dist/ReactToastify.css';
const {apiUrl} = Conexion();

async function traerEventos(id) {
  return fetch(`${apiUrl}/api/eventos/cliente/rol/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('token')
    }
  }).then(r =>{
    if (r.status > 399) {
      if(r.status === 401){
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
      }
      console.log("Error","Could not reach server");
    }
    return r.json()
  })
}

async function editarPermisos(idusuario, datos) {
  return fetch(`${apiUrl}/api/usuarios/uroles/eventos/${idusuario}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('token')
    },
    body: JSON.stringify(datos)
  })
    .then(r => {
      if (r.status > 399) {
        if (r.status === 401) {
          alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
          sessionStorage.clear();
          window.location.replace(window.location.origin);
          console.log(r.status)
        }
        console.log("Error", "Could not reach server");
      }
      return r.json()
      
    })
}

function RolEventos() {

  const {usuarioId} = useParams();
  const [state, setState] = useState({
    eventos: [],
    usuario: [],
    isfetched: false
  });

  const cargarDatos = async () => {
    let rol_eventos = await traerEventos(usuarioId);
    if(rol_eventos){
      setState({
        eventos: rol_eventos.eventos,
        usuario: rol_eventos.usuario,
        isfetched: true
      })
    }
  }

  if(!state.isfetched){
    cargarDatos();
  }

  const handleInputNumber = (e,id_evento) =>{
    let value = e.target.value
    if( value.length >= 4) return
    let _state = _.cloneDeep(state);
    let _evento = _.find(_state.eventos, ['id', id_evento]);
    let index = _.findIndex(_state.eventos, ['id', id_evento]);
    _evento.max_tickets = value

    _state.eventos[index] = _evento
    setState(_state)
  }

  const handleInputCheck = (e,id_evento) =>{
    let checked = e.target.checked
    let _state = _.cloneDeep(state);
    let _evento = _.find(_state.eventos, ['id', id_evento]);
    let index = _.findIndex(_state.eventos, ['id', id_evento]);
    if(checked){
      _evento.id_evento = id_evento
      _evento.idcliente_rol = usuarioId
    }else{
      _evento.id_evento = null
      _evento.idcliente_rol = null
    }
    _state.eventos[index] = _evento
    setState(_state)
  }

  const handleSubmit = async (e) =>{
    e.preventDefault();
    let _state = _.cloneDeep(state);
    let data = _.map(_state.eventos,function(ev) {
      return _.omit(ev, ['cant_invitados','imagen', 'nombre'])
    });
    let _data = {
      eventos : _.filter(data, 'idcliente_rol')
    }

    let editar = await editarPermisos(usuarioId, _data)
    if(editar) {
      toast.success('Permisos Guardados', {
      theme: "colored",
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      });
    } 


  }

  var lista = state.eventos.map((ev, i) =>{
     
    return (
       <Row key={i}>
           <Col xs={12} className="mb-3" >
               <ItemBlock active={ev.idcliente_rol != null}>
                   <ImgWrapper>
                      <EventImg evento={ev} fecha={''} apiUrl={apiUrl} />
                      {/* <img className='demo-imagen' src={`${apiUrl}/api/eventos/imagen/${ev.imagen}`} alt="" style={{width: '100%',  height: '100%'}} /> */}
                   </ImgWrapper>
                   <ActionWrapper>
                      {state.usuario.id_rol == 2 ? 
                        <div>
                            <StyledInput type="number" min={"0"} max={ev.cant_invitados} disabled={ev.idcliente_rol == null} className=" form-control text-center"  name="cantidad" value={ev.max_tickets != null && ev.idcliente_rol != null ? ev.max_tickets  : ''}  onChange={(e)=> handleInputNumber(e,ev.id)} placeholder='0' required/>
                        </div>
                      : ''}
                     <Form.Check 
                         type="switch"
                         className='app-toggle-switch'
                         id={"custom-switch"+ev.id}
                         checked={ev.idcliente_rol != null}
                         onChange={(e)=> handleInputCheck(e,ev.id)}
                     />
                   </ActionWrapper>
               </ItemBlock>
           </Col>
       </Row>
    )
  } );

  return (
    <div className='container-sm'>
      <Helmet>
          <title>Editar Permisos de Rol - Glup Ticket</title>
      </Helmet>
      <div className='text-center'>
        <AppLogo />
      <Container  style={{maxWidth: '310px'}}>
        <h4 className='extraBold' >Eventos permitidos</h4>
        <p className='semiBold' >{state.usuario.nombre}</p>
        {state.usuario.id_rol == 2 ?  <p className='text-start'>Establezca la cantidad de tickets máximos que puede generar el vendedor:</p> : ''}
        <form onSubmit={handleSubmit}>
        {lista.length ? lista : <p className='text-danger'>No hay eventos activos</p>}
        <div className="d-flex flex-column">
          <AppButton className='mb-1' type={'submit'} title={<><PersonCheckFill size={24} />&nbsp; Confirmar Permisos</>} />
          <LinkButton className='my-4' border to={`/app/roles/`} title={<><ChevronLeft size={24} />&nbsp; Atras</>} />
        </div>
        </form>
      </Container>
      </div>
      <ToastContainer />
    </div>
  );
}


const ItemBlock = styled.div`
  background-color: ${(props) => (props.active ? "#dcfdd4" : "#eee")};
  border-radius: 18px;
  display: flex;
  flex-direction: row;
  height: 90px;
  width: auto;
  font-size: 16px;
  line-height: 24px;
  color: #979797;
  text-align: left;
  padding: 5px;
`
const ImgWrapper = styled.div`
  width: 70%;
  padding-right: 5px;
`

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
`

const StyledInput = styled.input`
    background: #2F2F2F;
    background-color: #eee;
    width: 100%;
    padding: 0px;
    border-radius: 4px;
    font-family: Arial, sans-serif;
    padding-left: 5px;
    font-style: normal;
    font-size: 1rem; 
    line-height: 36px; 
`;

export default RolEventos;