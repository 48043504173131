import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Conexion from "../../Conexion/Conexion";
import { Helmet } from "react-helmet-async";
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import {QrCodeScan, ListCheck, EyeFill, PlusCircleFill, XSquare, Download } from 'react-bootstrap-icons';
import {DataGrid} from '@mui/x-data-grid';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CustomButton } from '../../App/components/AppButton';
import { ToastContainer } from 'react-toastify';
import TopNavbar from '../TopNavbar/TopNavbar';
import Footer from '../Footer/Footer';
import styled from 'styled-components';
const { apiUrl } = Conexion();

async function traerEventos() {
  return fetch(`${apiUrl}/api/eventos/cliente/panel/id`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('token')
    }
  }).then(r => {
    if (r.status > 399) {
      if (r.status === 401) {
        alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
        sessionStorage.clear();
        window.location.replace(window.location.origin);
        console.log(r.status)
      }
      console.log("Error", "Could not reach server");
    }
    return r.json()
  })
}

function TooltipTop({text, children}) {
  return (
    <>
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={`tooltip-top`}>
              {text}
            </Tooltip>
          }
        >
          {children}
        </OverlayTrigger>
    </>
  );
}

const Eventos = (props) => {

  const navigate = useNavigate();
  const [state, setState] = useState({
    eventos: [],
    isCargado: false
  });

  const handleCreateEvent = () => {
    navigate('/app/panel/nuevo-evento')
  }

  const cargarDatosEventos = async () => {
    const dEventos = await traerEventos();
    if (dEventos) {
      setState({
        eventos: dEventos,
        isCargado: true
      })
    }
  }

  if (!state.isCargado) {
    cargarDatosEventos();
  }

  const handleClickAction =  React.useCallback(
    (id, action) => () => {

      switch (action) {
        case 'ver':
          navigate(`/app/evento/${id}`);
          break;
        case 'descargar':
          navigate(`/app/evento/${id}/generar`);
          break;
        case 'escanear':
          navigate(`/app/evento/${id}/scan-qr`);
          break;
        case 'ingresos':
          navigate(`/app/evento/${id}/ingresos`);
          break;
        default:
          navigate(`/app/evento/${id}`);
          break;
      }
      
    },
    [],
  );


  const columns = React.useMemo(
    () => [
    {
      headerName: 'Ver',
      field: 'id',
      headerClassName: 'extraBold',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
        <TooltipTop text={"Ver"}>
          <button onClick={handleClickAction(params.row.id,'ver')}  className="btn btn-outline-secondary mx-auto" ><EyeFill /></button>
        </TooltipTop>
        );
      }
    },
    {
      headerName: 'Nombre',
      field: 'nombre',
      headerClassName: 'extraBold',
      headerAlign: 'center',
      width: 250,
    },
    {
      headerName: 'Fecha',
      field: 'fecha_evento',
      headerClassName: 'extraBold',
      headerAlign: 'center',
      width: 100,
      valueGetter: (params) => {
        return formatInTimeZone(params.value, "UTC", "dd/MM/yyyy", {
          awareOfUnicodeTokens: true
        })
      },
    },
    {
      headerName: 'Estado',
      field: 'estado',
      headerClassName: 'extraBold',
      headerAlign: 'center',
      valueGetter: (params) => {
          switch (params.value) {
            case 1:
              return "Sin Invitados"
            case 2:
              return "Pendiente"
            case 3:
              return "Listo"
            case 4:
              return "Terminado"
            case 0:
              return "Eliminado"
            default:
              return "Error"
          }
      },
      width: 100,
    },
    {
      field: 'id_cliente',
      headerName: 'Acciones',
      headerClassName: 'extraBold',
      headerAlign: 'center',
      type: 'actions',
      width:'360',
      renderCell: (params) => {
        return <ActionsWrapper>
          <TooltipTop text={"Descargar tickets digitales"}>
            <button onClick={handleClickAction(params.row.id,'descargar')}  className="btn btn-outline-dark" >
              <Download />&nbsp;Descargar Tickets
            </button>
          </TooltipTop>
          <TooltipTop text={"Escanear tickets"}>
            <button onClick={handleClickAction(params.row.id,'escanear')}  className="btn btn-outline-dark" >
              <QrCodeScan size={18}/>
            </button>
          </TooltipTop>
          <TooltipTop text={"Lista de ingresos"}>
            <button onClick={handleClickAction(params.row.id,'ingresos')}  className="btn btn-outline-dark" >
              <ListCheck size={18}/>
            </button>
          </TooltipTop>
        </ActionsWrapper>
      }
    },
  ],[handleClickAction]);

  const DataEventos = (props) => {

    return (
    <div className='overflow-auto' style={{height:"500px", width:"100%", padding:"10px"}}>
        <DataGrid
          columns={columns}
          rows={props.data}
          pageSize={10}
          rowsPerPageOptions={[10,25,50]} 
          disableColumnMenu
        />
    </div>);
  };

  return (

    <>
      <Helmet>
          <title>Mis Eventos | Glup Ticket</title>
      </Helmet>
      <TopNavbar cliente={props.cliente} active={"eventos"} />
      <div className="container px-4 py-5" style={{minHeight:'100vh'}}>
        <h2 className="pb-2 border-bottom font40 extraBold">Mis Eventos</h2>
        <CustomButton className="primaryBg whiteColor" action={handleCreateEvent} title={<>Nuevo Evento&nbsp;<PlusCircleFill size={22}/></>} />
        <DataEventos data={state.eventos} />
      </div>
      <ToastContainer position="top-center"
        theme='colored'
        autoClose={2500}
        hideProgressBar={false}
        closeOnClick={false}
        pauseOnHover={true}
        draggable={false}
      />
      <Footer/>
    </>
  );
}
export default Eventos;


const ActionsWrapper = styled.div`
  display: flex;
  gap: 7px;
`