import CryptoJS from 'crypto-js';


export function signData (amount,transaction_uuid,reference_number,service_id, dni, last_purchase_date){
    try{
        
        // const profile_id_old = "B4028A55-7807-45F7-B671-441FFB25B0D0";
        const profile_id = "AF5B5498-A8B4-49E2-B0FC-BE652A0DC84E";
        
        // const access_key_old = "6fe4d99aae8734bf981200d45b4cbc16";
        // const secret_old = "830ef1e7b2ad49bc9faa1abbe075c6984afff65ce644431a95a9babe376c42b13579ec4c1c774a7bad7d06077e67480149102b92e07d40f3a3379008033e3c363560799cb2ba40e6808a7cce1577a3ff6f953a0b50244db98b376b6165b8a60ab34f01fe9b774aac95505789d21f3fd3f0a00f354acf4065bd2c46864b268509";
        const access_key = "f8951986e1c83685aafcf7b8f045b45f";
        const secret = "26ed680e3a73406a9713b16cb0137f301048724b30284cfe9ca4affa67be17891c1e73dc9107404a9deeb299702bd5cd3b434ec77f094661b25939e86bfc80b6ff33e89615684ae999bd084e8df312e978e260847e2148b086bbfe6b489fa92929ed94614faf48d7967dd194158152a453189e0802b84a488a48ec0eb35d2e0c";
        
        let fecha = new Date();
        const signed_date_time=fecha.toISOString().split('.')[0]+"Z";

        const signed_field_names = 'access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,merchant_defined_data1,merchant_defined_data4,merchant_defined_data6,merchant_defined_data9,merchant_defined_data11,merchant_defined_data87,merchant_defined_data90,merchant_defined_data91';
        const locale='es-mx'
        const transaction_type='sale';
        const unsigned_field_names='bill_to_forename,bill_to_surname,bill_to_email,bill_to_address_line1,bill_to_address_country,bill_to_address_state,bill_to_address_city,bill_to_address_postal_code';
        const currency='USD';
        const merchant_defined_data1 = 'SI'; //UsuarioLogueado?

        let fecha_ultima_compra = new Date();
        if(last_purchase_date !== null){
            fecha_ultima_compra = new Date(last_purchase_date);
        }
        const merchant_defined_data4 = fecha_ultima_compra.toLocaleDateString("en-US"); //Fecha ultima compra
        const merchant_defined_data6 = 'NO'; // Comprador recurrente
        const merchant_defined_data9 = 'Pagina web'; //Canal de venta
        const merchant_defined_data11 = dni; //Numero de documento de comprador
        const merchant_defined_data87 = service_id; // ID de servicio
        const merchant_defined_data90 = 'Contrato'; // Tipo de servicio
        const merchant_defined_data91 = amount; // Deuda a pagar

        let signedFieldNames = signed_field_names.split(",")

        signedFieldNames[0]=signedFieldNames[0]+"="+access_key;
        signedFieldNames[1]=signedFieldNames[1]+"="+profile_id;
        signedFieldNames[2]=signedFieldNames[2]+"="+transaction_uuid;
        signedFieldNames[3]=signedFieldNames[3]+"="+signed_field_names;
        signedFieldNames[4]=signedFieldNames[4]+"="+unsigned_field_names;
        signedFieldNames[5]=signedFieldNames[5]+"="+signed_date_time;
        signedFieldNames[6]=signedFieldNames[6]+"="+locale;
        signedFieldNames[7]=signedFieldNames[7]+"="+transaction_type;
        signedFieldNames[8]=signedFieldNames[8]+"="+reference_number;
        signedFieldNames[9]=signedFieldNames[9]+"="+amount;
        signedFieldNames[10]=signedFieldNames[10]+"="+currency;
        signedFieldNames[11]=signedFieldNames[11]+"="+merchant_defined_data1;
        signedFieldNames[12]=signedFieldNames[12]+"="+merchant_defined_data4;
        signedFieldNames[13]=signedFieldNames[13]+"="+merchant_defined_data6;
        signedFieldNames[14]=signedFieldNames[14]+"="+merchant_defined_data9;
        signedFieldNames[15]=signedFieldNames[15]+"="+merchant_defined_data11;
        signedFieldNames[16]=signedFieldNames[16]+"="+merchant_defined_data87;
        signedFieldNames[17]=signedFieldNames[17]+"="+merchant_defined_data90;
        signedFieldNames[18]=signedFieldNames[18]+"="+merchant_defined_data91;


        const raw_signature = CryptoJS.HmacSHA256(signedFieldNames.join(","),secret);
        const signature = raw_signature.toString(CryptoJS.enc.Base64);
        let datos = {access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,merchant_defined_data1,merchant_defined_data4,merchant_defined_data6,merchant_defined_data9,merchant_defined_data11,merchant_defined_data87,merchant_defined_data90,merchant_defined_data91,signature}
        return datos;
    }catch(error){
        console.log(error.message)
        return false
    }
}