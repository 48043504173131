import React from 'react';
import ReactModal from 'react-modal';
import GenerarInvitado from './GenerarInvitado';
import InputCSV from './InputCSV'


class ModalInvitados extends React.Component {
    constructor () {
      super();
      this.state = {
        showModal: false,
        showModalGenerar:false
      };
      this.handleOpenModal = this.handleOpenModal.bind(this);
      this.handleCloseModal = this.handleCloseModal.bind(this);
      this.handleOpenModalGenerar = this.handleOpenModalGenerar.bind(this);
      this.handleCloseModalGenerar = this.handleCloseModalGenerar.bind(this);
    }
    
    handleOpenModal () {
      this.setState({ showModal: true });
    }
    handleOpenModalGenerar () {
      this.setState({ showModalGenerar: true });
    }
    
    handleCloseModal () {
      this.setState({ showModal: false });
    }
    handleCloseModalGenerar () {
      this.setState({ showModalGenerar: false });
    }
    
    
    render () {
      return (
        <div>
          <button className="btn btn-sm btn-primary" onClick={this.handleOpenModal}>Importar</button>
          <button className="btn btn-sm btn-success" onClick={this.handleOpenModalGenerar}>Generar</button>
          <ReactModal
             isOpen={this.state.showModal}
             contentLabel="Importar Lista"
             htmlOpenClassName="col-md-6"
          >
            <button className="btn btn-danger" onClick={this.handleCloseModal}>Cerrar</button>
            <InputCSV IdEvento={this.props.IdEvento} TipoEvento={this.props.TipoEvento} />
          </ReactModal>

          <ReactModal
             isOpen={this.state.showModalGenerar}
             contentLabel="Generar Invitados"
             htmlOpenClassName="col-md-6"
          >
            <button className="btn btn-danger" onClick={this.handleCloseModalGenerar}>Cerrar</button>
            <GenerarInvitado IdEvento={this.props.IdEvento} TipoEvento={this.props.TipoEvento} />
          </ReactModal>
        </div>
      );
    }
  }

  export default ModalInvitados;