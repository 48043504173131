import React from 'react';
import { Spinner } from 'react-bootstrap';
import Conexion from '../../../Conexion/Conexion';
const {apiUrl} = Conexion();

async function crearRol(datos) {
    return fetch(`${apiUrl}/api/usuarios/roles`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
      },
      body: JSON.stringify(datos)
    })
      .then(r =>{
        if (r.status > 399) {
          if(r.status === 401){
              alert("Acceso no Autorizado, Por Favor Inicia Sesión Nuevamente");
              sessionStorage.clear();
              window.location.replace(window.location.origin);
              console.log(r.status)
          }
          console.log("Error","Could not reach server");
        }
        return r.json()
    })
  }

class RolForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: '',
            apellido_p: '',
            apellido_m: '',
            telefono: '',
            correo: '',
            edad: 0,
            direccion: '',
            cant_eventos:0,
            cant_invitados:0,
            nombre_usuario:'',
            password:'',
            confpass:'',
            id_acceso:3,
            id_rol:'',
            id_padre: props.idPadre,
            guardando:false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange(e) {    
        if (e.target.id === 'nombre') {
            let password = this.generate_password(e.target.value.trim(),'');
            this.setState({ nombre: e.target.value.trim(),
            password: password });
            var fecha=new Date()
            var fechaIni = fecha.getFullYear()+'-'+(fecha.getMonth()+1)+'-'+fecha.getDate();
            this.setState({ fecha_inicio: fechaIni});
        } else if (e.target.id === 'apellido_p') {
            this.setState({ apellido_p: e.target.value.trim() });
        } else if (e.target.id === 'apellido_m') {
            this.setState({ apellido_m: e.target.value.trim() });
        } else if (e.target.id === 'telefono') {
            let password = this.generate_password('',e.target.value.trim());
            this.setState({ telefono: e.target.value.trim(),
             password: password});           
        } else if (e.target.id === 'correo') {
            this.setState({ correo: e.target.value,
                nombre_usuario: e.target.value });
        } else if (e.target.id === 'id_rol') {
            this.setState({ id_rol: e.target.value});
        }else if (e.target.id === 'nombre_usuario') {
            this.setState({ nombre_usuario: e.target.value});
        }else if (e.target.id === 'password') {
            this.setState({ password: e.target.value });
        } else if (e.target.id === 'confpass') {
            this.setState({ confpass: e.target.value});
        }
     }

    generate_password(name, phone){
        if(name === ''){
            name = this.state.nombre;
        }
        if(phone === ''){
            phone = this.state.telefono;
        }
            
        return name+phone;
    }
    async handleSubmit(e) {
        e.preventDefault();
        this.state.guardando=true;
        if(this.state.password === this.state.confpass){
            const usuario = await crearRol(this.state);
            if(usuario.ok){
              alert(usuario.mensaje);
              window.location.reload(false);
            } 
            else {alert(usuario.mensaje);}
        }else{
            alert('Confirme su contraseña correctamente');
        } 
        this.state.guardando=false;
    }

    render() {
        return (
            <div className="container p-2">
                <h2 className="text-center">Registrar Nuevo Rol</h2>
                <form className="col-lg-8 mx-auto" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="mb-3 col-sm-4">
                            <label htmlFor="nombre" className="form-label">Nombre</label>
                            <input type="text" id="nombre" className="form-control" value={this.state.nombre} onChange={this.handleChange}  required />
                        </div>
                        <div className="mb-3 col-sm-4">
                            <label htmlFor="apellido_p" className="form-label">Apellido Paterno</label>
                            <input type="text" className="form-control" id="apellido_p" value={this.state.apellido_p} onChange={this.handleChange} required />
                        </div>
                        <div className="mb-3 col-sm-4">
                            <label htmlFor="apellido_m" className="form-label">Apellido Materno</label>
                            <input type="text" className="form-control" id="apellido_m" value={this.state.apellido_m} onChange={this.handleChange} required />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb-3 col-sm-4">
                            <label htmlFor="telefono" className="form-label">Telefono</label>
                            <input type="text" className="form-control" id="telefono" value={this.state.telefono} onChange={this.handleChange} required />
                        </div>
                        <div className="mb-3 col-sm-6">
                            <label htmlFor="correo" className="form-label">Correo</label>
                            <input type="email" className="form-control" id="correo" value={this.state.correo} onChange={this.handleChange} required />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="mb-3 col-sm-8">
                        <label htmlFor="id_rol" className="form-label">Rol de Usuario</label>
                            <select className="form-select" id="id_rol" value={this.state.id_rol} onChange={this.handleChange} required >
                                <option value={''}>Seleccione un Rol</option>
                                <option value={2}>Generador de Tickets</option>
                                <option value={3}>Escaneador</option>
                            </select>
                        </div>
                    </div>
                   <div className='row'>
                        <div className="mb-3 col-sm-8">
                            <label htmlFor="nombre_usuario" className="form-label">Nombre de Usuario</label>
                            <input type="text" disabled className="form-control" id="nombre_usuario" value={this.state.nombre_usuario} onChange={this.handleChange} required />
                        </div>
                    </div>
                    <div className="mb-3 col-sm-8">
                        <label htmlFor="password" className="form-label">Contraseña</label>
                        <input type="text" className="form-control" id="password" value={this.state.password} onChange={this.handleChange} required />
                    </div>
                    <div className="mb-3 col-sm-8">
                        <label htmlFor="conf_pass" className="form-label">Confirmar Contraseña</label>
                        <input type="password" className="form-control" id="confpass" value={this.state.confpass} onChange={this.handleChange} required />
                    </div>
                    <button type="submit" className="btn btn-primary" disabled={this.state.guardando}>{this.state.guardando?<Spinner animation="border" />: 'Guardar'}</button>
                </form>
            </div>
        );
    }
}

export default RolForm;