import Sidebar from '../Sidebar/Sidebar';
import { Box, Container, Grid } from '@mui/material';
import UsuariosConMasEventos from './components/usuarios-con-mas-eventos';
import EventosProximaSemana from './components/eventos-proxima-semana';
import EventosProximoMes from './components/eventos-proximo-mes';
import EventosMesPasado from './components/eventos-mes-pasado';
import GananciasMesActual from './components/ganancias-mes-actual';
import GananciasPorMes from './components/ganancias-por-mes';
import EventosNuevos from './components/eventos-nuevos';
import IngresosCheckeados from './components/ingresos-checkeados';
import IngresosTotales from './components/ingresos-totales';
import UsuariosActivos from './components/usuarios-activos';
import VentasPorPlan from './components/ventas-por-plan';
import EventosPorCiudad from './components/eventos-por-ciudad';
import GananciasTotales from './components/ganancias-totales';

export default function Dashboard() {

    return(
        <>
            <Sidebar dashboard='active'/>
            <Box
            component="main"
            sx={{flexGrow: 1, py: 8}}
            >
            <Container maxWidth={false}>
                <Grid
                container
                spacing={3}
                >
                    <Grid
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                    >
                        <UsuariosActivos />
                    </Grid>
                    <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={6}
                        xs={12}
                    >
                        <GananciasTotales />
                    </Grid>
                    <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={6}
                        xs={12}
                    >
                        <IngresosCheckeados />
                    </Grid>
                    <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={6}
                        xs={12}
                    >
                        <IngresosTotales />
                    </Grid>
                    <Grid
                        item
                        lg={4}
                        md={6}
                        xl={3}
                        xs={12}
                    >
                        <EventosNuevos  sx={{ height: '100%' }}/>
                    </Grid>
                    <Grid
                        item
                        lg={8}
                        md={12}
                        xl={9}
                        xs={12}
                    >
                        <GananciasPorMes sx={{ height: '100%' }} />
                    </Grid>
                    <Grid
                        item
                        lg={4}
                        md={6}
                        xl={3}
                        xs={12}
                    >
                        <EventosProximaSemana  sx={{ height: '100%' }}/>
                    </Grid>
                    <Grid
                        item
                        lg={4}
                        md={6}
                        xl={3}
                        xs={12}
                    >
                        <EventosProximoMes  sx={{ height: '100%' }}/>
                    </Grid>
                    <Grid
                        item
                        lg={4}
                        md={6}
                        xl={3}
                        xs={12}
                    >
                        <EventosMesPasado  sx={{ height: '100%' }}/>
                    </Grid>
                    <Grid
                        item
                        lg={4}
                        md={6}
                        xl={3}
                        xs={12}
                    >
                        <UsuariosConMasEventos  sx={{ height: '100%' }}/>
                    </Grid>
                    <Grid
                        item
                        lg={8}
                        md={12}
                        xl={9}
                        xs={12}
                    >
                        <VentasPorPlan sx={{ height: '100%' }} />
                    </Grid>
                    <Grid
                        item
                        lg={4}
                        md={6}
                        xl={3}
                        xs={12}
                    >
                        <EventosPorCiudad sx={{ height: '100%' }} />
                    </Grid>
                </Grid>
            </Container>
            </Box>
        </>
    );
}