import React from "react";
import ReactExport from "react-export-excel";
import styled from "styled-components";
import { FileEarmarkSpreadsheet } from 'react-bootstrap-icons'
import _ from 'lodash';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



export default function ExportarIngresos(props) {
    return (
        <ExcelFile filename={"Ingresos - "+ props.nombre} element={<DownloadButton className='animate pointer radius8'>Exportar&nbsp;<FileEarmarkSpreadsheet size={20}/></DownloadButton>}>
            <ExcelSheet data={props.data} name="Ingresos Actuales">
                <ExcelColumn label="#" value="nro_invitado"/>
                <ExcelColumn label="Nombre" value="nombre"/>
                <ExcelColumn label="Categoria" value="nombree"/>
                <ExcelColumn label="Generado por" value="usuario"/>
                <ExcelColumn label="Hora de Ingreso" value="hora_ingreso"/>
                <ExcelColumn label="Estado" 
                                value={(col) => col.estado > 1 ? "CHECK" : "NO CHECK"}/>
            </ExcelSheet>
            
        </ExcelFile>
    );
}



const DownloadButton = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#dcfdd4")};
  background-color: ${(props) => (props.border ? "transparent" : "#dcfdd4")};
  width: 100%;
  padding: 5px 10px;
  outline: none;
  color: ${(props) => (props.border ? "#fff" : "#1c1c1c")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#dcfdd4")};
    border: 1px solid #dcfdd4;
    color: ${(props) => (props.border ? "#dcfdd4" : "#000")};
  }
`;