import ReactModal from 'react-modal';
import DatePicker from "react-datepicker";
import _ from 'lodash';
import { useState, useEffect } from 'react';
import * as MultiDatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import multiColors from "react-multi-date-picker/plugins/colors"
import { Container, Row, Col } from 'react-bootstrap';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import Conexion from '../../Conexion/Conexion';
const {apiUrl} = Conexion();
const DateObject = MultiDatePicker.DateObject;


async function editarEvento(data,id) {
    return fetch(`${apiUrl}/api/eventos/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        },
        body: JSON.stringify(data)
    }).then(result => result.json())
}

async function addMembresiaDays(data,id) {
    return fetch(`${apiUrl}/api/eventos/membresia/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        },
        body: JSON.stringify(data)
    }).then(result => result.json())
}

async function traerDiasReinicio(id) {
    return fetch(`${apiUrl}/api/eventos/diasmembresia/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(result => result.json())
}

export default function EventoEditar(props){
    const datos = props.data;
    let fecha;
    let limite;
    let estado = "";
    let tipo = "";
    const [diasR, setDiasR] = useState([]);
    const [diasUnion, setDiasUnion] = useState([]);
    const [diasNuevos, setDiasNuevos] = useState([]);
    if(datos !== null){
        fecha = formatInTimeZone(datos.fecha_evento,"GMT", "MM/dd/yyyy HH:mm:ss", {
            awareOfUnicodeTokens: true
        });
        limite = formatInTimeZone(datos.fecha_limite,"GMT", "MM/dd/yyyy HH:mm:ss", {
            awareOfUnicodeTokens: true
        });
        
        switch (datos.estado) {
            case 0:
                estado = "Eliminado"
                break
            case 1:
                estado = "Sin Invitados"
                break
            case 2:
                estado = "Pendiente"
                break
            case 3:
                estado = "Listo"
                break
            case 4:
                estado = "Terminado"
                break    
            default:
                estado = ""
                break
        }
        switch (datos.subevento) {
            case 0:
                tipo = "Cerrado"
                break
            case 1:
                tipo = "Evento Padre"
                break
            case 2:
                tipo = "Membresia"
                break   
            case 3:
                tipo = "Multiuso"
                break   
            default:
                tipo = ""
                break
        }
    }


    const cargarDias = async () => {
        let data = await traerDiasReinicio(datos.id);
        if(data){
            let dias = data.map(dataObj => {
                let formatdia = formatInTimeZone(dataObj.dia,"GMT", "MM/dd/yyyy HH:mm:ss", {
                    awareOfUnicodeTokens: true
                });
                let dia = new DateObject(new Date(formatdia));
                if(dataObj.reiniciado){
                    dia.color = "green";    
                }else{
                    dia.color = "red";
                }
                
                return dia;
            })
        setDiasR(dias)
        setDiasUnion(dias)
        }
    }


    useEffect(() => {
        if(datos !== null){
            cargarDias();
        }
    }, [datos]);


    const closeModal=()=>{
        props.close();
    }

    let handleChange=(e)=>{
        
        if (e.target.id === 'nombre') {
            datos.nombre = e.target.value
            props.change(datos);
        }
    }
    let handleChangeFecha = fecha => {
        datos.fecha_evento = fecha
        if(new Date(limite) < fecha){
            datos.fecha_limite = fecha
        }
        props.change(datos);
    }

    let handleChangeLimite = fecha => {
        datos.fecha_limite = fecha;
        setDiasNuevos([])
        setDiasUnion(diasR)
        props.change(datos);
    }

    let handleChangeDias = fechas => {
        let fecha_inicio = new Date(fecha);
        let fecha_fin = new Date(limite)
        let _diasUnion = _.union(diasR, fechas);
        let _diff = _.difference(_diasUnion, diasR)
        let formatfechas = _diff.map((f)=>{ return new Date(f.format("MM/DD/YYYY")) });
        let _diasNuevos = _.filter(formatfechas, function(o) {  if(fecha_inicio <= o && fecha_fin >= o) return o; });
        setDiasUnion(_diasUnion)
        setDiasNuevos(_diasNuevos)

       
    }

    let handleSubmit = async (e) =>{
        e.preventDefault();
        if(diasNuevos.length > 0){
            let _dias = _.map(diasNuevos, (o) =>{
                return formatInTimeZone(o, "UTC", "dd-MM-yyyy", {
                    awareOfUnicodeTokens: true
                });
            })
            let data = {
                dias: _dias
            }
            let result = await addMembresiaDays(data,datos.id);
        }
        const data = {
            nombre: datos.nombre,
            fecha_evento: formatInTimeZone(datos.fecha_evento,"UTC", "dd-MM-yyyy", {
                awareOfUnicodeTokens: true
            }),
            fecha_limite: formatInTimeZone(datos.fecha_limite,"UTC", "dd-MM-yyyy", {
                awareOfUnicodeTokens: true
            }),
            estado: datos.estado == 4 ? 3 : datos.estado
          }
        const editar = await editarEvento(data,datos.id);
        if(editar.ok){
            alert("Editado con éxito");
            props.submit();
            props.close();
        }else{
            alert("Error");
        }
    }
    
    return (
        <div>
          <ReactModal
             isOpen={props.open}
             contentLabel="Editar Evento"
             htmlOpenClassName="col-md-6"
          >
            <button className="btn btn-danger" onClick={closeModal}>Cerrar</button>
            <Container>
                <h2 className="text-center">Editar Evento</h2>
                <form className="col-lg-8 mx-auto mb-2" onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Col>
                            <label htmlFor="nombre_ev" className="form-label">Nombre</label>
                            <input type="text" className="form-control" name='nombre' id="nombre" value={datos !== null ? datos.nombre : ''} onChange={handleChange}  />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <label className="form-label">Fecha Evento:</label>
                            <DatePicker selected={datos !== null ? new Date(fecha) : ''} name="fecha" dateFormat="dd-MM-yyyy" onChange={handleChangeFecha} />
                        </Col>
                        <Col>
                            <label className="form-label">Fecha Límite:</label>
                            <DatePicker minDate={datos !== null ? new Date(fecha) : ''} selected={datos !== null ? new Date(limite) : ''} name="fecha" dateFormat="dd-MM-yyyy" onChange={handleChangeLimite} />
                        </Col>
                    </Row>
                    <Row className="mb-3" hidden={tipo != 'Membresia'}>
                        <Col>
                            <p className="form-label">Agregar Dias de Reinicio:</p>
                            <MultiDatePicker.default  value={diasUnion} onChange={handleChangeDias}
                                multiple format='DD/MM/YYYY' inputClass='react-datepicker__input-container'
                                sort
                                formattingIgnoreList={diasR}
                                minDate={new Date(fecha)}
                                maxDate={new Date(limite)}
                                plugins={[
                                    <DatePanel removeButton={false} />,
                                    multiColors({colors: ['green', 'red', 'blue']})
                                ]} 
                            />
                        </Col>
                        <Col>
                            <p className="form-label">Leyenda - Dias de Reinicio:</p>
                            <ul>
                                <li>
                                    <div className="rmdp-color rmdp-green d-inline-block"></div><p className='d-inline-block'>Reiniciados</p>
                                </li>
                                <li>
                                    <div className="rmdp-color rmdp-red d-inline-block"></div><p className='d-inline-block'>Guardados</p>
                                </li>
                                <li>
                                    <div className="rmdp-color rmdp-blue d-inline-block"></div><p className='d-inline-block'>Por Agregar</p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    {estado == "Terminado" ? <p>Alargue el plazo de la fecha limite para reactivar el evento</p> : ""}
                    <button type="submit" className="btn btn-primary text-right" disabled={(new Date(limite)) < (new Date())} >Guardar</button>
                </form>
                <hr></hr>
                <div className='row'>
                <div className='col-lg-4 mx-auto'>
                    <div>
                        <h4><b>Estado:</b> {datos !== null ? estado : ""}</h4>
                    </div>
                    <div>
                        <h4><b>Tipo:</b> {datos !== null ? tipo : ""}</h4>
                    </div>
                    <div>
                        <h4><b>Cliente:</b> {datos !== null ? datos.cliente: ""}</h4>
                    </div>
                    
                    <div>    
                        <h4><b>Banner:</b></h4>
                        {datos!=null?datos.origen==1?
                        <img src={`${apiUrl}/api/eventos/imagen/${datos !== null ? datos.imagen: ""}`} width={280} height={100} alt="" />:
                        <img src={`${datos.imagen }`} width={280} height={100} alt="" />
                        :""}
                        
                    </div>
                
                </div>
                <div className='col-lg-4 mx-auto'>
                    <div>
                        <h4><b>Fondo QR:</b></h4>
                        {datos!=null?datos.origen==1?
                        <img src={`${apiUrl}/api/eventos/imagen/${datos !== null ? datos.fondo_qr: ""}`} width={240} height={426} alt="" />:
                        <img src={`${datos.fondo_qr }`} width={280} height={100} alt="" />
                        :""}
                    </div>
                </div>
                </div>
                

            </Container>
          </ReactModal>
        </div>
      );  

}