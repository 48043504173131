import React, {useState} from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import RollerIcon from "../../../assets/svg/Services/RollerIcon";
import ShoppingCartIcon from "../../../assets/images/shopping-cart.png";
import MonitorIcon from "../../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../../assets/svg/Services/PrinterIcon";
import CheckMark from "../../../assets/svg/Checkmark";
import VisaMcLogo from "../../../assets/images/visa-and-mastercard-logos.png"

export default function PricingTable({ plan_id, icon, price, quantity, title, text,  offers, action }) {
  let getIcon;

  const [selected, setSelected] = useState(0)
  
  const changeSelect = (newIndex) => {
    setSelected(newIndex)
  }

  switch (icon) {
    case "shoppingCart":
      getIcon = <img src={ShoppingCartIcon} width="40" />;
      break;
    case "roller":
      getIcon = <RollerIcon />;
      break;
    case "monitor":
      getIcon = <MonitorIcon />;
      break;
    case "browser":
      getIcon = <BrowserIcon />;
      break;
    case "printer":
      getIcon = <PrinterIcon />;
      break;
    default:
      getIcon = <RollerIcon />;
      break;
  }

  return (
    <Wrapper className="whiteBg radius8 shadow">
      <div className="flexSpaceCenter">
        {getIcon}
        <p className="font30 extraBold">${price[selected]}</p>
      </div>
      <div style={{ margin: "30px 0" }}>
        <h5 className="font17 semiBold">
          <select 
            onChange={(event) => changeSelect(event.target.value)}
            value={selected}
          >
            {quantity.map((elem,index)=>{
              return <option key={index} value={index} >{elem}</option>
            })
            }
          </select>
          Tickets
        </h5>
        
        <h4 className="font30 extraBold">{title}</h4>
        <p className="font13" style={{height:"100px"}}>{text}</p>
      </div>
      <div style={{height:"200px"}}>
        {offers
          ? offers.map((item, index) => (
            <div className="flexNullCenter" style={{ margin: "2px 0" }} key={index}>
                <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                  {item.cheked ? (
                    <div style={{ minWidth: "20px" }}>
                      <CheckMark />
                    </div>
                  ) : (
                    <div style={{ minWidth: "20px" }}></div>
                    )}
                </div>
                <p className="font15 semiBold mb-0">{item.name}</p>
              </div>
            ))
            : null}
      </div>
      <div className="text-end" style={{ width: "100%", height:"30px" }}>
        {price[selected] == 0 ? '' :<img  src={VisaMcLogo} width="100"/>}
      </div>
      <div style={{ maxWidth: "180px", margin: "30px auto 0 auto" }}>
        <FullButton title={price[selected] == 0 ? 'Pruébalo gratis' : 'Comprar'} action={() => action(plan_id, selected)} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;
